import React from 'react';
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';

const TeacherFeedback = () => {
    // Dummy Data
    const teacherFeedbacks = [
        {
            teacherName: 'Mr. Zhang Wei',
            subject: 'Mandarin Writing',
            overallRating: 85, // Overall rating in %
            feedback: {
                participation: 'Very active in class discussions and always asks insightful questions.',
                homework: 'Completes homework on time with great attention to detail.',
                exams: 'Good performance in exams, but there is room for improvement in writing accuracy.',
                classBehavior: 'Well-behaved and respectful towards peers and teachers.',
            },
            suggestions: 'Focus more on writing practice and try to participate more in writing exercises.',
        },
        {
            teacherName: 'Ms. Li Hua',
            subject: 'Mandarin Reading',
            overallRating: 90, // Overall rating in %
            feedback: {
                participation: 'Actively participates in reading sessions and shows great enthusiasm.',
                homework: 'Homework is always well done, demonstrating a good understanding of the material.',
                exams: 'Excellent performance in reading comprehension exams.',
                classBehavior: 'Displays exemplary behavior and is a role model for other students.',
            },
            suggestions: 'Continue practicing reading aloud to improve fluency further.',
        },
    ];

    return (
        <Container style={{ marginTop: '20px' }}>
            <h2 style={{ color: '#7335b7', marginBottom: '20px' }}>Teacher Feedback</h2>
            {teacherFeedbacks.map((feedback, index) => (
                <Card key={index} style={{ marginBottom: '20px' }}>
                    <Card.Body>
                        <Row>
                            <Col md={8}>
                                <Card.Title style={{ color: '#7335b7' }}>
                                    {feedback.teacherName} - {feedback.subject}
                                </Card.Title>
                            </Col>
                            <Col md={4}>
                                <h6>Overall Rating</h6>
                                <ProgressBar
                                    now={feedback.overallRating}
                                    label={`${feedback.overallRating}%`}
                                    variant="info"
                                    style={{ height: '20px', fontSize: '14px', backgroundColor: '#d9c5f0' }}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={6}>
                                <h5>Participation</h5>
                                <p>{feedback.feedback.participation}</p>
                            </Col>
                            <Col md={6}>
                                <h5>Homework</h5>
                                <p>{feedback.feedback.homework}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <h5>Exams</h5>
                                <p>{feedback.feedback.exams}</p>
                            </Col>
                            <Col md={6}>
                                <h5>Class Behavior</h5>
                                <p>{feedback.feedback.classBehavior}</p>
                            </Col>
                        </Row>
                        <hr />
                        <h5>Suggestions</h5>
                        <p>{feedback.suggestions}</p>
                    </Card.Body>
                </Card>
            ))}
        </Container>
    );
};

export default TeacherFeedback;
