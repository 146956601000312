export const LOGIN = "LOGIN";

export const LOGOUT = "LOGOUT";

export const UPDATE_STUDENT_DETAIL = "UPDATE_STUDENT_DETAIL";

// Action creator for updating student details
export const updateStudentDetail = (studentDetail) => ({
    type: UPDATE_STUDENT_DETAIL,
    studentDetail: studentDetail,
});