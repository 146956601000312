import { Shuffle } from "lucide-react";
import React, { useEffect, useState } from "react";
import { fireConfetti, fireGameCompleteConfetti } from "../ai-games.data";

const words = [
  { id: 1, text: "Apple", pair: "Fruit", hint: "A common red or green fruit" },
  {
    id: 2,
    text: "Fruit",
    pair: "Apple",
    hint: "Category of sweet, edible plant products",
  },
  { id: 3, text: "Dog", pair: "Animal", hint: "A common household pet" },
  {
    id: 4,
    text: "Animal",
    pair: "Dog",
    hint: "Living beings that can move and eat",
  },
  { id: 5, text: "Sun", pair: "Star", hint: "The center of our solar system" },
  { id: 6, text: "Star", pair: "Sun", hint: "A bright celestial body" },
  { id: 7, text: "Ocean", pair: "Water", hint: "A vast body of saltwater" },
  { id: 8, text: "Water", pair: "Ocean", hint: "H2O, essential for life" },
  { id: 9, text: "Book", pair: "Read", hint: "A collection of written pages" },
  {
    id: 10,
    text: "Read",
    pair: "Book",
    hint: "To look at and comprehend text",
  },
  { id: 11, text: "Tree", pair: "Forest", hint: "A tall woody plant" },
  {
    id: 12,
    text: "Forest",
    pair: "Tree",
    hint: "A large area with many plants",
  },
];

const WordMatchingGame = () => {
  const [cards, setCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState([]);
  const [moves, setMoves] = useState(0);
  const [gameComplete, setGameComplete] = useState(false);
  const [incorrectPair, setIncorrectPair] = useState(false);
  const [score, setScore] = useState(0);

  useEffect(() => {
    shuffleCards();
  }, []);

  const shuffleCards = () => {
    const shuffled = [...words].sort(() => Math.random() - 0.5);
    setCards(shuffled);
    setSelectedCards([]);
    setMatchedPairs([]);
    setMoves(0);
    setScore(0);
    setGameComplete(false);
  };

  const handleCardClick = (card) => {
    if (
      selectedCards.length === 2 ||
      selectedCards.includes(card) ||
      matchedPairs.includes(card.text)
    ) {
      return;
    }

    const newSelected = [...selectedCards, card];
    setSelectedCards(newSelected);

    if (newSelected.length === 2) {
      setMoves(moves + 1);
      if (newSelected[0].pair === newSelected[1].text) {
        // Increase score for correct match
        setScore(score + 10);
        fireConfetti();

        setMatchedPairs([
          ...matchedPairs,
          newSelected[0].text,
          newSelected[1].text,
        ]);
        setSelectedCards([]);

        if (matchedPairs.length + 2 === words.length) {
          setGameComplete(true);
          // Fire special confetti for game completion
          fireGameCompleteConfetti();
        }
      } else {
        // Decrease score for incorrect match
        setScore(Math.max(0, score - 5));
        setIncorrectPair(true);
        setTimeout(() => {
          setSelectedCards([]);
          setIncorrectPair(false);
        }, 1000);
      }
    }
  };

  const getCardStyle = (card) => {
    if (matchedPairs.includes(card.text)) {
      return "tw-bg-green-500 tw-text-white tw-border-green-600";
    }
    if (selectedCards.includes(card)) {
      if (selectedCards.length === 2 && incorrectPair) {
        return "tw-bg-red-500 tw-text-white tw-border-red-600";
      }
      return "tw-bg-blue-500 tw-text-white tw-border-blue-600";
    }
    return "tw-bg-gray-100 tw-text-gray-400 tw-border-gray-300 tw-opacity-75";
  };

  return (
    <div className="tw-max-w-4xl tw-mt-2 tw-mx-auto tw-p-4">
      <div className="tw-my-6">
        <h1 className="tw-text-2xl tw-text-center tw-font-bold">
          Word Matching Game
        </h1>
      </div>
      <div className="tw-my-8 tw-flex tw-justify-between tw-items-center">
        <div className="tw-text-xl tw-font-bold tw-flex tw-justify-center tw-gap-8">
          <div>Moves: {moves}</div>
          <div className="tw-text-xl tw-font-bold tw-text-green-600">
            Score: {score}
          </div>
        </div>
        <button
          onClick={shuffleCards}
          className="tw-flex tw-text-sm tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded tw-hover:bg-blue-600 tw-transition-colors"
        >
          <Shuffle size={20} />
          New Game
        </button>
      </div>

      <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-4">
        {cards.map((card) => (
          <button
            key={card.id}
            onClick={() => handleCardClick(card)}
            className={`
              tw-rounded-lg 
              tw-transition-all tw-duration-300
              tw-border-2 tw-h-28
              tw-flex tw-flex-col tw-items-center tw-justify-center
              tw-p-4
              tw-font-medium
              ${getCardStyle(card)}
              tw-hover:opacity-100
              disabled:tw-cursor-not-allowed
              focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-400
            `}
            disabled={
              selectedCards.length === 2 && !selectedCards.includes(card)
            }
          >
            <span className="tw-text-lg tw-mb-2">{card.text}</span>
            {(selectedCards.includes(card) ||
              matchedPairs.includes(card.text)) && (
              <span className="tw-text-xs tw-text-center tw-opacity-75">
                {card.hint}
              </span>
            )}
          </button>
        ))}
      </div>

      {gameComplete && (
        <div className="tw-mt-6 tw-text-center">
          <h2 className="tw-text-2xl tw-font-bold tw-text-green-600">
            Congratulations! Your score is {score}! You completed the game in{" "}
            {moves} moves!
          </h2>
        </div>
      )}
    </div>
  );
};

export default WordMatchingGame;
