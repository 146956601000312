import React from "react";
import { Container, Row } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";
import DemoVdos from "./DemoVdos.js";

const DashboardDemo = () => {
  return (
    <section id="watchdemo" className="home-section text-center">
      <Container style={{ padding: "5% 0" }}>
        <Row
          className="align-items-center d-flex justify-content-center py-5"
          style={{
            backgroundColor: "#7335b7",
            borderRadius: "20px",
            color: "#ffffff",
          }}
        >
          <Slide direction="right">
            <DemoVdos />
          </Slide>
        </Row>
      </Container>
    </section>
  );
};

export default DashboardDemo;
