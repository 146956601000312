// import React from 'react'
// import { Bar } from 'react-chartjs-2';

// function BarChart({ barChartData }) {

//     const labels = [];
//     const data = [];

//     for (const [levelKey, levelValue] of Object.entries(barChartData)) {
//         for (const [sublevelKey, sublevelValue] of Object.entries(levelValue)) {
//             if (sublevelKey.startsWith('level') && typeof sublevelValue.score === 'number') {
//                 labels.push(sublevelKey);
//                 data.push(sublevelValue.score);
//             }
//         }
//     }

//     const modifiedBarChartData = {
//         labels: labels,
//         datasets: [
//             {
//                 label: 'Scores',
//                 backgroundColor: '#7335b7',
//                 borderColor: '#5C2E91',
//                 borderWidth: 1,
//                 hoverBackgroundColor: '#A569BD',
//                 hoverBorderColor: '#5C2E91',
//                 data: data,
//             },
//         ],
//     };

//     return (
//         <Bar data={modifiedBarChartData} />
//     )
// }

// export default BarChart

import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Dropdown, DropdownButton } from 'react-bootstrap';

function BarChart({ barChartData }) {
    // State to store the selected level
    console.log(barChartData);
    const [selectedLevel, setSelectedLevel] = useState('level1');

    // Function to handle the level change
    const handleSelect = (eventKey) => {
        setSelectedLevel(eventKey);
    };

    // Extract data for the selected level
    const labels = [];
    const data = [];

    const levelData = barChartData[selectedLevel] || {};
    for (const [sublevelKey, sublevelValue] of Object.entries(levelData)) {
        if (sublevelKey.startsWith(selectedLevel) && typeof sublevelValue.score === 'number') {
            labels.push(sublevelKey.replace('_', '.'));
            data.push(sublevelValue.score);
        }
    }

    const modifiedBarChartData = {
        labels: labels,
        datasets: [
            {
                label: 'Scores',
                backgroundColor: '#7335b7',
                borderColor: '#5C2E91',
                borderWidth: 1,
                hoverBackgroundColor: '#A569BD',
                hoverBorderColor: '#5C2E91',
                data: data,
            },
        ],
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                max: 10, // Set the maximum value on the Y-axis
                ticks: {
                    stepSize: 1, // Ensure there are always 10 scales
                },
            },
        },
    };

    return (
        <div>
            {/* Dropdown for level selection */}
            <DropdownButton
                id="level-dropdown"
                title={`Select Level: ${selectedLevel}`}
                onSelect={handleSelect}
                variant="primary"
                style={{ backgroundColor: '#7335b7', color: 'white', width: '0px' }}
            >
                {[...Array(10).keys()].map((i) => (
                    <Dropdown.Item
                        key={i + 1}
                        eventKey={`level${i + 1}`}
                        active={selectedLevel === `level${i + 1}`}
                        disabled={!barChartData[`level${i + 1}`].unlocked}
                    >
                        Level {i + 1}
                    </Dropdown.Item>
                ))}
            </DropdownButton>

            {/* Bar Chart */}
            <Bar data={modifiedBarChartData} options={chartOptions} />
        </div>
    );
}

export default BarChart;
