import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';

const ParentFeedback = () => {
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle the feedback submission logic here
        console.log('Feedback submitted:', {
            feedbackTitle: feedbackTitle,
            feedbackContent: feedbackContent,
            email: "abc",
            date: new Date().toLocaleString(),
        });
        // Clear the form after submission
        setFeedbackTitle('');
        setFeedbackContent('');
    };

    return (
        <Container style={{ padding: '20px' }}>
            <h2 style={{ color: '#7335b7', textAlign: "center" }}>Parent Feedback</h2>
            <p style={{ textAlign: "center" }}>Please provide your feedback below</p>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="feedbackTitle" className="mb-3">
                    <Form.Label style={{ color: '#7335b7' }}>Feedback Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter the feedback title"
                        value={feedbackTitle}
                        onChange={(e) => setFeedbackTitle(e.target.value)}
                        style={{
                            borderColor: '#7335b7'
                        }}
                    />
                </Form.Group>

                <Form.Group controlId="feedbackContent" className="mb-3">
                    <Form.Label style={{ color: '#7335b7' }}>Feedback</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Write your feedback here"
                        value={feedbackContent}
                        onChange={(e) => setFeedbackContent(e.target.value)}
                        style={{
                            borderColor: '#7335b7'
                        }}
                    />
                </Form.Group>

                <div className="text-center">
                    <Button
                        type="submit"
                        style={{
                            backgroundColor: '#7335b7',
                            borderColor: '#7335b7',
                            color: 'white',
                        }}
                    >
                        Submit Feedback
                    </Button>
                </div>
            </Form>
        </Container>
    );
};

export default ParentFeedback;
