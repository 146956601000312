import { UPDATE_STUDENT_DETAIL, LOGOUT } from "../action";

export const studentDetailInitialState = {
    studentDetail: undefined,
    // studentDetail: {
    //     studentReport: {
    //         levelScores: {},
    //     },
    // },
};

const StudentDetailReducer = (state = studentDetailInitialState, action) => {
    switch (action.type) {
        case UPDATE_STUDENT_DETAIL:
            return {
                ...state,
                studentDetail: action.studentDetail,
            };

        case LOGOUT:
            return {
                ...state,
                studentDetail: undefined,
            };

        default:
            return { ...state };
    }
};

export default StudentDetailReducer;