import React from "react";
import Button from "react-bootstrap/Button";
import { Link, useParams } from "react-router-dom";
import { aiGamesData } from "./ai-games.data";
import WordMatchGame from "./all-games/WordMatchingGame";

// Create a map of game components for easier maintenance
const GAME_COMPONENTS = {
  "Word Match": WordMatchGame,
};

export default function AIGameDetails() {
  const { gameName } = useParams();

  const game = aiGamesData.find((game) => game.title === gameName);

  if (!game) {
    return (
      <div className="error-container tw-m-10">
        <h2>Game not found</h2>
        <p>Please select a valid game from the dashboard.</p>
        <Button variant="outline-primary">
          <Link to="/dashboard/games/ai-games">Go to dashboard</Link>
        </Button>
      </div>
    );
  }

  const GameComponent = GAME_COMPONENTS[gameName];

  return (
    <section className="game-container">
      {GameComponent ? (
        <GameComponent />
      ) : (
        <h3 className="tw-text-center tw-mt-20">
          <span className="tw-font-bold">{gameName}</span> game is
          under development
        </h3>
      )}
    </section>
  );
}
