import React, { useState } from "react";
import { Form, Button, Card, Col, Container, Row } from "react-bootstrap";
import Avatar from "./Avatar";
import images from "../../images";
import { testData } from "../../constants/data";
import "../../css/Dashboard.css";

function Dashboard() {
  const [step, setStep] = useState(0);
  const [userType, setUserType] = useState("");
  const [language, setLanguage] = useState("");
  const [proficiency, setProficiency] = useState("");
  const [testAnswers, setTestAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [testPassed, setTestPassed] = useState(false);
  const [background, setBackground] = useState("");

  const handleUserTypeSelect = (type) => {
    setUserType(type);
    setStep(1);
  };

  const handleLanguageSelect = (selectedLanguage) => {
    if (userType === "Preschool") {
      setLanguage(selectedLanguage);
      setStep(2);
    } else {
      alert(`${userType.toUpperCase()} section is under development.`);
    }
  };
  const handleProficiencySelect = (selectedProficiency) => {
    setProficiency(selectedProficiency);
    setStep(3);
  };

  const handleAnswerSelect = (questionIndex, answer) => {
    setTestAnswers({ ...testAnswers, [questionIndex]: answer });
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const evaluateTest = () => {
    const questions = testData[language][proficiency];
    let correctCount = 0;

    questions.forEach((question, index) => {
      if (testAnswers[index] === question.correctAnswer) {
        correctCount++;
      }
    });

    const score = (correctCount / questions.length) * 100;
    if (score >= 80) {
      setTestPassed(true);
      setStep(4);
    } else {
      alert("Test not passed. Please try again.");
      setTestAnswers({});
      setCurrentQuestionIndex(0);
    }
  };

  const handleBackgroundSelect = (selectedBackground) => {
    setBackground(selectedBackground);
    setStep(5);
  };

  const goBack = () => {
    setStep(step - 1);
  };

  // Function to recite text using speechSynthesis API
  const reciteText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  };

  // Function to stop the speech
  const stopRecitingText = () => {
    window.speechSynthesis.cancel();
  };

  return (
    <Container className="mt-0">
      <Row className="justify-content-center">
        <Col md={9}>
          {step > 0 && (
            <Button variant="secondary" className="mb-3" onClick={goBack}>
              Back
            </Button>
          )}
        </Col>

        {/* Step 0: User Type Selection */}
        {step === 0 && (
          <Col md={8}>
            <Card className="p-0 shadow-sm">
              <Card.Body>
                <h2 className="text-center mb-4">Select your level</h2>
                <Row className="d-flex justify-content-around">
                  <Col md={4}>
                    <Card
                      onClick={() => handleUserTypeSelect("Preschool")}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.preSchoolImg} alt="Preschool" />
                      <Card.Body>
                        <Card.Title>Preschool</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card
                      onClick={() => handleUserTypeSelect("K-3")}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.k3Img} alt="K-3" />
                      <Card.Body>
                        <Card.Title>K-3</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card
                      onClick={() => handleUserTypeSelect("Middle School")}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.middleSchoolImg} alt="Middle School" />
                      <Card.Body>
                        <Card.Title>Middle School</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-4">
                  <Col md={4}>
                    <Card
                      onClick={() => handleUserTypeSelect("High School")}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.highSchoolImg} alt="High School" />
                      <Card.Body>
                        <Card.Title>High School</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card
                      onClick={() => handleUserTypeSelect("Higher Ed")}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.higherEdImg} alt="Higher Ed" />
                      <Card.Body>
                        <Card.Title>Higher Ed</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card
                      onClick={() => handleUserTypeSelect("Adult")}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.adultImg} alt="Adult" />
                      <Card.Body>
                        <Card.Title>Adult</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )}

        {/* Step 1: Language Selection */}
        {step === 1 && (
          <Col md={8}>
            <Card className="p-4 shadow-sm">
              <Card.Body>
                <h2 className="text-center mb-4">What is the language you want to learn?</h2>
                <Row className="d-flex justify-content-around">
                  <Col md={4}>
                    <Card
                      onClick={() => handleLanguageSelect("Mandarin")}
                      onMouseEnter={() => reciteText("Mandarin")}
                      onMouseLeave={() => stopRecitingText()}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.mandarinPic} alt="Mandarin" />
                      <Card.Body>
                        <Card.Title>Mandarin</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card
                      onClick={() => handleLanguageSelect("French")}
                      onMouseEnter={() => reciteText("French")}
                      onMouseLeave={() => stopRecitingText()}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.frenchPic} alt="French" />
                      <Card.Body>
                        <Card.Title>French</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card
                      onClick={() => handleLanguageSelect("Spanish")}
                      onMouseEnter={() => reciteText("Spanish")}
                      onMouseLeave={() => stopRecitingText()}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.spanishPic} alt="Spanish" />
                      <Card.Body>
                        <Card.Title>Spanish</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-4">
                  <Col md={4}>
                    <Card
                      onClick={() => handleLanguageSelect("Latin")}
                      onMouseEnter={() => reciteText("Latin")}
                      onMouseLeave={() => stopRecitingText()}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.latinPic} alt="Latin" />
                      <Card.Body>
                        <Card.Title>Latin</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card
                      onClick={() => handleLanguageSelect("Arabic")}
                      onMouseEnter={() => reciteText("Arabic")}
                      onMouseLeave={() => stopRecitingText()}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.arabicPic} alt="Arabic" />
                      <Card.Body>
                        <Card.Title>Arabic</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card
                      onClick={() => handleLanguageSelect("Hindi")}
                      onMouseEnter={() => reciteText("Hindi")}
                      onMouseLeave={() => stopRecitingText()}
                      className="dashboard-clickable-card dashboard-hover-card"
                    >
                      <Card.Img variant="top" src={images.hindiPic} alt="Hindi" />
                      <Card.Body>
                        <Card.Title>Hindi</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )}

        {/* Other Steps for Preschool level */}
        {step === 2 && (
          <Col md={8}>
            <Card className="p-4 shadow-sm">
              <Card.Body>
                <h2 className="text-center mb-4">Select your proficiency level</h2>
                <div className="d-flex justify-content-around">
                  <Button variant="outline-primary" onClick={() => handleProficiencySelect("New")}>
                    I'm new to the language
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => handleProficiencySelect("Conversational")}
                  >
                    I have a few conversational words
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => handleProficiencySelect("Intermediate")}
                  >
                    I can hold a conversation
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        )}

        {step === 3 && (
          <Col md={8}>
            <Card className="p-4 shadow-sm">
              <Card.Body>
                <h2 className="text-center mb-4">Test your knowledge</h2>
                <Form>
                  <Form.Group controlId={`question${currentQuestionIndex}`}>
                    <Form.Label>
                      {testData[language][proficiency][currentQuestionIndex].question}
                    </Form.Label>
                    {testData[language][proficiency][currentQuestionIndex].options.map(
                      (option, index) => (
                        <Form.Check
                          key={index}
                          type="radio"
                          label={option}
                          name={`question${currentQuestionIndex}`}
                          id={`option${index}`}
                          onChange={() => handleAnswerSelect(currentQuestionIndex, option)}
                          checked={testAnswers[currentQuestionIndex] === option}
                        />
                      )
                    )}
                  </Form.Group>
                  <div className="d-flex justify-content-between mt-4">
                    <Button
                      variant="secondary"
                      onClick={handlePreviousQuestion}
                      disabled={currentQuestionIndex === 0}
                    >
                      Back
                    </Button>
                    {currentQuestionIndex === testData[language][proficiency].length - 1 ? (
                      <Button variant="primary" onClick={evaluateTest}>
                        Submit Test
                      </Button>
                    ) : (
                      <Button variant="primary" onClick={handleNextQuestion}>
                        Next
                      </Button>
                    )}
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        )}

        {step === 4 && (
          <Col md={8}>
            <Card className="p-4 shadow-sm">
              <Card.Body>
                <h2 className="text-center mb-4">Choose your background</h2>
                <div className="d-flex justify-content-around">
                  <Button
                    variant="outline-primary"
                    onClick={() => handleBackgroundSelect("Bilingual Immersion School")}
                  >
                    Bilingual Immersion School
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => handleBackgroundSelect("Private Lessons/Tutoring")}
                  >
                    Private Lessons/Tutoring
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => handleBackgroundSelect("Self-Study")}
                  >
                    Self-Study
                  </Button>
                  <Button variant="outline-primary" onClick={() => handleBackgroundSelect("Work")}>
                    Work
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => handleBackgroundSelect("Leisure")}
                  >
                    Leisure
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        )}

        {step === 5 && <Avatar />}
      </Row>
    </Container>
  );
}

export default Dashboard;
