import React from "react";
import { Zoom } from "react-awesome-reveal";
import { Container, Row, Col, Card } from "react-bootstrap";
import { measures } from "../../constants/data.js";

const ProductMeasures = () => {
  return (
    <section id="product-measures" className="product-measures-section text-center py-5 bg-white">
      <Container>
        <Row className="text-center">
          <Col lg={4}></Col>
          <Col lg={4}>
            <h3
              className="text-center mb-4"
              style={{
                borderBottom: "4px solid #156AA7",
                paddingBottom: "10px",
                animation: "fade-in 1.5s ease-in-out",
              }}
            >
              What Our Product Measures
            </h3>
          </Col>
          <Col lg={4}></Col>
        </Row>
        <Row xs={1} sm={2} md={2} lg={3} className="justify-content-center">
          {measures.map((measure, index) => (
            <Col key={index} className="mb-4">
              <Zoom>
                <Card className="h-100 shadow-sm landing-card-hover animate-fade-in-up">
                  <div className="p-4 landing-icon-bounce">{measure.icon}</div>
                  <Card.Body>
                    <h4 className="title mb-3">
                      <a href={measure.link} className="text-dark text-decoration-none">
                        {measure.person}
                      </a>
                    </h4>
                  </Card.Body>
                </Card>
              </Zoom>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ProductMeasures;
