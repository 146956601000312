import React from "react";
import { Container, Row, Col, Card, ProgressBar } from "react-bootstrap";
import "../../../css/Lessons.css";
import { LessonsData } from "../../../constants/data.js";

const Lessons = () => {
  const lessons = LessonsData();

  return (
    <Container className="lesson-fade-in">
      <Row>
        <Col lg={12}>
          <div className="section-title course-home margin-bottom-80 center-align">
            <h2 className="title">Our Lessons</h2>
          </div>
        </Col>
      </Row>

      <Row>
        {lessons.frenchLessons.map((lesson, index) => (
          <Col lg={4} key={index} className="lesson-card-col">
            <Card
              style={{
                cursor: "pointer",
                border: `5px solid ${lesson.color}`,
                transition: "transform 0.3s ease-in-out, box-shadow 0.3s",
              }}
              onClick={() => {
                lesson.onClick();
              }}
              className="text-center lesson-card-hover"
            >
              <Card.Body
                className="d-flex flex-column justify-content-between"
                style={{
                  backgroundColor: "#fff",
                  transition: "background-color 0.3s",
                }}
              >
                {lesson.image}
                <Card.Title style={{ color: lesson.color }}>{lesson.title}</Card.Title>
                <Card.Text>{lesson.description}</Card.Text>

                <ProgressBar now={lesson.progress} label={`${lesson.progress}%`} style={{ marginTop: "10px" }} />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Lessons;
