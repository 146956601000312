import { legacy_createStore as createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { RootReducer } from './RootReducer';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    // storage,
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export { store, persistor };
