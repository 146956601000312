import { Slide } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";

const AboutUsSection = () => {
  return (
    <section id="about" className="about-section py-5" style={{ backgroundColor: "#7335b7", color: "#ffffff" }}>
      <br />
      <Container>
        {/* Headings */}
        <div initial="hidden" whileInView="visible" viewport={{ once: true }}>
          <Slide direction="down">
            <h1 className="subtitle mb-4 text-center" style={{ fontWeight: "600", fontSize: "2.5rem", color: "#CECECE" }}>
              Who are we?
            </h1>
          </Slide>
          <Slide direction="up">
            <h3 className="title mb-5 text-center" style={{ fontSize: "2.25rem", fontWeight: "700" }}>
              An Innovative K-12 Language Learning LMS
            </h3>
          </Slide>
        </div>

        {/* Cards */}
        <div className="cards-container" initial="hidden" whileInView="visible" viewport={{ once: true }}>
          <Row className="justify-content-center">
            {/* Card 1 */}
            <Col lg={4} md={6} className="mb-4 d-flex">
              <Slide direction="left">
                <div
                  className="card"
                  style={{
                    borderRadius: "15px",
                    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    padding: "2rem",
                  }}
                >
                  <div className="card-body">
                    <h4 className="card-title" style={{ fontWeight: "600", color: "#7335b7" }}>
                      Personalized Learning Paths
                    </h4>
                    <p className="card-text" style={{ fontSize: "1.125rem", lineHeight: "1.75rem" }}>
                      Our LMS provides personalized learning paths that cater to each student's unique needs, ensuring they receive the support they need to excel.
                    </p>
                  </div>
                </div>
              </Slide>
            </Col>

            {/* Card 2 */}
            <Col lg={4} md={6} className="mb-4 d-flex">
              <Slide direction="up">
                <div
                  className="card"
                  style={{
                    borderRadius: "15px",
                    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    padding: "2rem",
                  }}
                >
                  <div className="card-body">
                    <h4 className="card-title" style={{ fontWeight: "600", color: "#7335b7" }}>
                      Comprehensive Support Tools
                    </h4>
                    <p className="card-text" style={{ fontSize: "1.125rem", lineHeight: "1.75rem" }}>
                      Real-time feedback and comprehensive tools for educators and parents to enrich students' learning.
                    </p>
                  </div>
                </div>
              </Slide>
            </Col>

            {/* Card 3 */}
            <Col lg={4} md={6} className="mb-4 d-flex">
              <Slide direction="right">
                <div
                  className="card"
                  style={{
                    borderRadius: "15px",
                    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    padding: "2rem",
                  }}
                >
                  <div className="card-body">
                    <h4 className="card-title" style={{ fontWeight: "600", color: "#7335b7" }}>
                      Gamification and AI
                    </h4>
                    <p className="card-text" style={{ fontSize: "1.125rem", lineHeight: "1.75rem" }}>
                      Our interactive gamification elements make learning fun and motivating, helping students stay engaged and track their progress effectively.
                    </p>
                  </div>
                </div>
              </Slide>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default AboutUsSection;
