import React, { useState } from "react";
import "../../css/StudentCurriculum.css";

const content = {
  noviceLow: {
    interpretative: "Identify isolated words and basic phrases in simple contexts.",
    interpersonal: "Exchange basic greetings and personal information with prompts.",
    presentational: "Introduce oneself using rehearsed words and phrases.",
    intercultural: "Recognize a few typical products and practices of the target culture.",
  },
  noviceMid: {
    interpretative: "Understand short, simple texts on familiar topics.",
    interpersonal: "Engage in simple conversations with memorized expressions.",
    presentational: "Present basic information about oneself and immediate surroundings.",
    intercultural: "Identify common cultural practices and products in everyday situations.",
  },
  noviceHigh: {
    interpretative: "Grasp the main idea of simple, predictable texts.",
    interpersonal: "Respond to simple questions and statements with practiced language.",
    presentational: "Give short presentations on familiar topics using phrases and simple sentences.",
    intercultural: "Recognize some cultural differences and similarities in everyday interactions.",
  },
  intermediateLow: {
    interpretative: "Understand main ideas and some details in short conversations and texts.",
    interpersonal: "Participate in simple, routine conversations on familiar topics.",
    presentational: "Express simple opinions and preferences using short sentences.",
    intercultural: "Show understanding of cultural practices in common social situations.",
  },
  intermediateMid: {
    interpretative: "Comprehend the main ideas and some details in more complex texts.",
    interpersonal: "Engage in conversations on familiar topics with some spontaneity.",
    presentational: "Deliver brief presentations on familiar subjects using connected sentences.",
    intercultural: "Respect cultural norms and appropriately respond in typical cultural contexts.",
  },
  intermediateHigh: {
    interpretative: "Understand detailed information in longer, more complex texts.",
    interpersonal: "Initiate and sustain conversations on a variety of topics.",
    presentational: "Present detailed information and personal viewpoints clearly and coherently.",
    intercultural: "Adapt language and behavior to a variety of cultural contexts with sensitivity.",
  },
  advancedLow: {
    interpretative: "Interpret detailed and nuanced information in texts and discussions.",
    interpersonal: "Maintain conversations on a wide range of topics with fluency.",
    presentational: "Create organized presentations on familiar and some unfamiliar topics.",
    intercultural: "Demonstrate awareness of cultural perspectives and adjust accordingly.",
  },
  advancedMid: {
    interpretative: "Analyze complex texts and grasp implicit meanings.",
    interpersonal: "Engage in complex discussions, offering detailed explanations and justifications.",
    presentational: "Deliver structured presentations on abstract topics with clear argumentation.",
    intercultural: "Navigate cultural differences with a deep understanding of underlying values.",
  },
  advancedHigh: {
    interpretative: "Understand and critically analyze sophisticated texts across genres.",
    interpersonal: "Discuss abstract ideas and hypothetical situations with near-native fluency.",
    presentational: "Present well-structured arguments on complex topics with persuasive language.",
    intercultural: "Integrate cultural knowledge seamlessly in communication and actions.",
  },
  superior: {
    interpretative: "Comprehend and interpret a wide range of complex and nuanced texts.",
    interpersonal: "Engage effortlessly in highly sophisticated and abstract discussions.",
    presentational: "Deliver compelling presentations on any topic with native-like proficiency.",
    intercultural: "Exhibit near-native cultural competence in diverse and complex situations.",
  },
};

const StudentCurriculum = () => {
  const [level, setLevel] = useState("noviceLow"); // Default level
  const [activeTab, setActiveTab] = useState("interpretative"); // Default tab

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="student-curriculum">
      <h1 className="title">Student Curriculum</h1>

      <h1>Current Level: {level} </h1>

      {/* Dropdown to select the level */}
      <div className="level-selector">
        <label htmlFor="level-select">Select Level:</label>
        <select id="level-select" onChange={handleLevelChange} value={level}>
          <option value="noviceLow">Novice Low</option>
          <option value="noviceMid">Novice Mid</option>
          <option value="noviceHigh">Novice High</option>
          <option value="intermediateLow">Intermediate Low</option>
          <option value="intermediateMid">Intermediate Mid</option>
          <option value="intermediateHigh">Intermediate High</option>
          <option value="advancedLow">Advanced Low</option>
          <option value="advancedMid">Advanced Mid</option>
          <option value="advancedHigh">Advanced High</option>
          <option value="superior">Superior</option>
        </select>
      </div>

      {/* Tabs to switch between interpretative, interpersonal, presentational, and intercultural */}
      <div className="tabs">
        {["interpretative", "interpersonal", "presentational", "intercultural"].map((tab) => (
          <button key={tab} onClick={() => handleTabChange(tab)} className={`tab-button ${activeTab === tab ? "active" : ""}`}>
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      {/* Display content based on the selected level and tab */}
      <div className="content">
        <h2 className="content-title">{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h2>
        <p>{content[level][activeTab]}</p>
      </div>
    </div>
  );
};

export default StudentCurriculum;
