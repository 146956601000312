import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const MotionBox = motion.div;
const MotionHeading = motion.h1;
const MotionText = motion.p;
const MotionButton = motion.button;

const PaymentSuccess = () => {
  const searchQuery = useSearchParams()[0];
  const referenceNum = searchQuery.get("reference");
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/dashboard");
  };

  return (
    <MotionBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      style={{
        background: "linear-gradient(to right, #68D391, #38A169)",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <MotionHeading
          style={{
            textTransform: "uppercase",
            fontSize: "40px",
            color: "white",
            fontWeight: "600",
          }}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Purchased Successfully
        </MotionHeading>

        <MotionText
          style={{
            fontSize: "18px",
            color: "#E2E8F0",
          }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          Payment ID : {referenceNum}
        </MotionText>

        <MotionButton
          style={{
            backgroundColor: "#3182CE",
            color: "white",
            border: "none",
            padding: "14px 28px",
            fontSize: "16px",
            cursor: "pointer",
            borderRadius: "4px",
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleRedirect}
        >
          Go to Dashboard
        </MotionButton>
      </div>
    </MotionBox>
  );
};

export default PaymentSuccess;
