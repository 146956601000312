import React, { useContext } from "react";
import { Container, Row, Col, Card, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../css/Lessons.css";
import images from "../../images";
import { ProgressContext } from "../../contexts/ProgressContext";

const Lessons = () => {
  const navigate = useNavigate();
  const { progress, updateProgress } = useContext(ProgressContext);

  const lessonsArray = [
    {
      title: "ENGLISH",
      description: "English Lessons",
      color: "#00ffddd7",
      image: <img src={images.middleSchoolImg} alt="English" className="lesson-image" />,
      progress: progress.English,
      onClick: () => navigate("/dashboard/english_lessons"),
      updateProgress: () => updateProgress("Enlish", 25),
    },
    {
      title: "MANDARIN",
      description: "Mandarin Lessons",
      color: "#ff0000",
      image: <img src={images.chinese_boy} alt="Mandarin" className="lesson-image" />,
      progress: progress.Mandarin,
      onClick: () => navigate("/dashboard/chinese_lessons"),
      updateProgress: () => updateProgress("Mandarin", 25),
    },
    {
      title: "SPANISH",
      description: "Spanish Lessons",
      color: "green",
      image: <img src={images.spanish_boy} alt="Spanish" className="lesson-image" />,
      progress: progress.Spanish,
      onClick: () => navigate("/dashboard/spanish_lessons"),
      updateProgress: () => updateProgress("Spanish", 25),
    },
    {
      title: "FRENCH",
      description: "French Lessons",
      color: "#0000ff",
      image: <img src={images.french_girl} alt="French" className="lesson-image" />,
      progress: progress.French,
      onClick: () => navigate("/dashboard/french_lessons"),
      updateProgress: () => updateProgress("French", 25),
    },
  ];

  // Function to recite text using speechSynthesis API
  const reciteText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  };

  // Function to stop the speech
  const stopRecitingText = () => {
    window.speechSynthesis.cancel();
  };

  return (
    <Container className="lesson-fade-in">
      <Row>
        <Col lg={12}>
          <div className="section-title course-home margin-bottom-80 center-align">
            <h2 className="title" style={{ paddingRight: "15%" }}>
              Our Lessons
            </h2>
          </div>
        </Col>
      </Row>

      <Row>
        {lessonsArray.map((lesson, index) => (
          <Col lg={5} md={6} sm={12} key={index} className="lesson-card-col">
            <Card
              style={{
                cursor: "pointer",
                border: `5px solid ${lesson.color}`,
                transition: "transform 0.3s ease-in-out, box-shadow 0.3s",
              }}
              onClick={lesson.onClick}
              className="text-center lesson-card-hover"
            >
              <Card.Body
                className="d-flex flex-column justify-content-between"
                style={{
                  backgroundColor: "#fff",
                  transition: "background-color 0.3s",
                }}
                // onMouseEnter={() => reciteText(lesson.description)}
                // onMouseLeave={() => stopRecitingText()}
              >
                {lesson.image}
                <Card.Title style={{ color: lesson.color }}>{lesson.title}</Card.Title>
                <Card.Text>{lesson.description}</Card.Text>

                <ProgressBar now={lesson.progress} label={`${lesson.progress}%`} style={{ marginTop: "10px" }} />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Lessons;
