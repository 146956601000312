import React from "react";
import { Link } from "react-router-dom";
// import { Zoom, Slide } from "react-awesome-reveal";
import { Container, Row, Col, Button } from "react-bootstrap";
import images from "../../images";

const WelcomeSection = () => {
  return (
    <section id="login" className="home-section text-center">
      <Container style={{ padding: "5% 0" }}>
        <Row
          className="align-items-center d-flex justify-content-center py-5"
          style={{
            backgroundColor: "#7335b7",
            borderRadius: "20px",
            color: "#ffffff",
          }}
        >
          <Col lg={4}>
            <img src={images.hero3} alt="" className="img-fluid " style={{ maxHeight: "400px" }} />
          </Col>
          <Col lg={8} className="d-flex align-items-center text-align-center">
            <div className="header-inner">
              <h1 className="title">Welcome to Happy Plum!</h1>
              <p className="description">Our LMS connects your child's language learning journey with parents and teachers, ensuring personalized education for future global leaders. Join us in this exciting adventure to cultivate your child's success in the 21st century.</p>
              <div className=" mt-4">
                <Link to="/login" className="text-decoration-none">
                  <Button
                    variant="light"
                    style={{
                      marginRight: "10px",
                      fontWeight: "bold",
                      borderRadius: "20px",
                      padding: "10px 20px",
                      color: "#7335b7",
                    }}
                  >
                    Login
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
    </section>
  );
};

export default WelcomeSection;
