import React from "react";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import { JackInTheBox } from "react-awesome-reveal";
import { BiMap, BiEnvelope, BiPhone } from "react-icons/bi";
import { InfoItem, FormField, SocialLinks } from "./ContactUsDetails.js";

const ContactUs = () => {
  return (
    <section id="contact" className="contact-section py-5" style={{ backgroundColor: "#7335b7", borderRadius: "0" }}>
      <br />
      <Container>
        <Row className="gy-5 gx-lg-5">
          <Col lg={12}>
            <div className="section-header" style={{ color: "#ffffff" }}>
              <h2>Contact Us</h2>
              <p>Happy Plum Language Learning</p>
            </div>
          </Col>
        </Row>

        <Row className="gy-5 gx-lg-5">
          <Col lg={6}>
            <JackInTheBox>
              <Card className="h-100 shadow">
                <Card.Body>
                  <Card.Title>Get in Touch</Card.Title>
                  <Card.Text>If you have any queries, please contact us, and we will provide support.</Card.Text>

                  <InfoItem icon={<BiMap size={24} style={{ color: "blue" }} />} title="Location:" text="Boston, MA USA" />

                  <InfoItem icon={<BiEnvelope size={24} style={{ color: "green" }} />} title="Email:" text="happyplumint@gmail.com" />

                  <InfoItem icon={<BiPhone size={24} style={{ color: "red" }} />} title="Call:" text="+16174595461" />
                </Card.Body>
              </Card>
            </JackInTheBox>
          </Col>

          {/* Contact Form */}
          <Col lg={6}>
            <JackInTheBox>
              <Card className="h-100 shadow">
                <Card.Body>
                  <Card.Title>Customer Support</Card.Title>
                  <Form className="php-email-form">
                    <Row className="mb-3">
                      <FormField name="name" placeholder="Your Name" type="text" />
                      <FormField name="email" placeholder="Your Email" type="email" />
                      <FormField name="subject" placeholder="Subject" type="text" />
                      <FormField name="message" placeholder="Message" as="textarea" />

                      <Col md={12} className="text-center">
                        <Button
                          type="submit"
                          variant="light"
                          style={{
                            marginRight: "10px",
                            fontWeight: "bold",
                            borderRadius: "20px",
                            padding: "10px 20px",
                            backgroundColor: "#7335b7",
                            color: "#ffffff",
                          }}
                        >
                          Send Message
                        </Button>
                      </Col>
                    </Row>
                  </Form>

                  {/* Social Media Links */}
                  <div>
                    <p>Social Media Links:</p>
                    <SocialLinks />
                  </div>
                </Card.Body>
              </Card>
            </JackInTheBox>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs;
