export const levels = [
    {
        level: "1",
        sublevels: [
            {
                sublevel: "1.1",
                conversations: [
                    {
                        english: "Hello, how are you?",
                        mandarin: {
                            pinyin: "Nǐ hǎo, nǐ zěnme yàng?",
                            characters: "你好，你怎么样？"
                        }
                    },
                    {
                        english: "I am good.",
                        mandarin: {
                            pinyin: "Wǒ hěn hǎo.",
                            characters: "我很好。"
                        }
                    },
                    {
                        english: "What is your name?",
                        mandarin: {
                            pinyin: "Nǐ jiào shénme míngzì?",
                            characters: "你叫什么名字？"
                        }
                    },
                    {
                        english: "My name is ",
                        mandarin: {
                            pinyin: "Wǒ jiào",
                            characters: "我叫"
                        }
                    },
                    {
                        english: "How old are you?",
                        mandarin: {
                            pinyin: "Nǐ jǐ suì?",
                            characters: "你几岁？"
                        }
                    },
                    {
                        english: "I am 5.",
                        mandarin: {
                            pinyin: "Wǒ wǔ suì.",
                            characters: "我五岁。"
                        }
                    }
                ]
            },
            {
                sublevel: "1.2",
                conversations: [
                    {
                        english: "Where do you live?",
                        mandarin: {
                            pinyin: "Nǐ zhù zài nǎlǐ?",
                            characters: "你住在哪里？"
                        }
                    },
                    {
                        english: "I live in New York.",
                        mandarin: {
                            pinyin: "Wǒ zhù zài Niǔyuē.",
                            characters: "我住在纽约。"
                        }
                    },
                    {
                        english: "Do you like ice cream?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān bīngqílín ma?",
                            characters: "你喜欢冰淇淋吗？"
                        }
                    },
                    {
                        english: "Yes, I love ice cream!",
                        mandarin: {
                            pinyin: "Shì de, wǒ ài bīngqílín!",
                            characters: "是的，我爱冰淇淋！"
                        }
                    },
                    {
                        english: "What is your favorite color?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de yánsè shì shénme?",
                            characters: "你最喜欢的颜色是什么？"
                        }
                    },
                    {
                        english: "My favorite color is blue.",
                        mandarin: {
                            pinyin: "Wǒ zuì xǐhuān de yánsè shì lán sè.",
                            characters: "我最喜欢的颜色是蓝色。"
                        }
                    }
                ]
            },
            {
                sublevel: "1.3",
                conversations: [
                    {
                        english: "What is your hobby?",
                        mandarin: {
                            pinyin: "Nǐ de àihào shì shénme?",
                            characters: "你的爱好是什么？"
                        }
                    },
                    {
                        english: "I like reading books.",
                        mandarin: {
                            pinyin: "Wǒ xǐhuān kàn shū.",
                            characters: "我喜欢看书。"
                        }
                    },
                    {
                        english: "Do you have a pet?",
                        mandarin: {
                            pinyin: "Nǐ yǒu chǒngwù ma?",
                            characters: "你有宠物吗？"
                        }
                    },
                    {
                        english: "Yes, I have a dog.",
                        mandarin: {
                            pinyin: "Yǒu, wǒ yǒu yī zhǐ gǒu.",
                            characters: "有，我有一只狗。"
                        }
                    },
                    {
                        english: "What is your favorite food?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de shíwù shì shénme?",
                            characters: "你最喜欢的食物是什么？"
                        }
                    },
                    {
                        english: "I love pizza.",
                        mandarin: {
                            pinyin: "Wǒ ài pīsà.",
                            characters: "我爱披萨。"
                        }
                    }
                ]
            },
            {
                sublevel: "1.4",
                conversations: [
                    {
                        english: "Can you ride a bicycle?",
                        mandarin: {
                            pinyin: "Nǐ huì qí zìxíngchē ma?",
                            characters: "你会骑自行车吗？"
                        }
                    },
                    {
                        english: "Yes, I can ride a bicycle.",
                        mandarin: {
                            pinyin: "Shì de, wǒ huì qí zìxíngchē.",
                            characters: "是的，我会骑自行车。"
                        }
                    },
                    {
                        english: "Do you play any sports?",
                        mandarin: {
                            pinyin: "Nǐ yùndòng ma?",
                            characters: "你运动吗？"
                        }
                    },
                    {
                        english: "I play soccer.",
                        mandarin: {
                            pinyin: "Wǒ tī zúqiú.",
                            characters: "我踢足球。"
                        }
                    },
                    {
                        english: "What is your favorite game?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de yóuxì shì shénme?",
                            characters: "你最喜欢的游戏是什么？"
                        }
                    },
                    {
                        english: "My favorite game is chess.",
                        mandarin: {
                            pinyin: "Wǒ zuì xǐhuān de yóuxì shì xiàngqí.",
                            characters: "我最喜欢的游戏是象棋。"
                        }
                    }
                ]
            },
            {
                sublevel: "1.5",
                conversations: [
                    {
                        english: "What is your favorite book?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de shū shì shénme?",
                            characters: "你最喜欢的书是什么？"
                        }
                    },
                    {
                        english: "My favorite book is Harry Potter.",
                        mandarin: {
                            pinyin: "Wǒ zuì xǐhuān de shū shì Hālì Bōtè.",
                            characters: "我最喜欢的书是哈利·波特。"
                        }
                    },
                    {
                        english: "Do you like to travel?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān lǚxíng ma?",
                            characters: "你喜欢旅行吗？"
                        }
                    },
                    {
                        english: "Yes, I enjoy traveling.",
                        mandarin: {
                            pinyin: "Shì de, wǒ xǐhuān lǚxíng.",
                            characters: "是的，我喜欢旅行。"
                        }
                    },
                    {
                        english: "What is your favorite place?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de dìfāng shì nǎlǐ?",
                            characters: "你最喜欢的地方是哪里？"
                        }
                    },
                    {
                        english: "My favorite place is the beach.",
                        mandarin: {
                            pinyin: "Wǒ zuì xǐhuān de dìfāng shì hǎitān.",
                            characters: "我最喜欢的地方是海滩。"
                        }
                    }
                ]
            }
        ]
    },
    {
        level: "2",
        sublevels: [
            {
                sublevel: "2.1",
                conversations: [
                    {
                        english: "What do you like to eat for breakfast?",
                        mandarin: {
                            pinyin: "Nǐ zǎocān xǐhuān chī shénme?",
                            characters: "你早餐喜欢吃什么？"
                        }
                    },
                    {
                        english: "I like to eat eggs and toast.",
                        mandarin: {
                            pinyin: "Wǒ xǐhuān chī jīdàn hé miànbāo.",
                            characters: "我喜欢吃鸡蛋和面包。"
                        }
                    },
                    {
                        english: "Do you like coffee or tea?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān kāfēi háishì chá?",
                            characters: "你喜欢咖啡还是茶？"
                        }
                    },
                    {
                        english: "I like coffee.",
                        mandarin: {
                            pinyin: "Wǒ xǐhuān kāfēi.",
                            characters: "我喜欢咖啡。"
                        }
                    },
                    {
                        english: "Can you cook?",
                        mandarin: {
                            pinyin: "Nǐ huì zuò fàn ma?",
                            characters: "你会做饭吗？"
                        }
                    },
                    {
                        english: "Yes, I can cook.",
                        mandarin: {
                            pinyin: "Shì de, wǒ huì zuò fàn.",
                            characters: "是的，我会做饭。"
                        }
                    }
                ]
            },
            {
                sublevel: "2.2",
                conversations: [
                    {
                        english: "Where are you going?",
                        mandarin: {
                            pinyin: "Nǐ yào qù nǎlǐ?",
                            characters: "你要去哪里？"
                        }
                    },
                    {
                        english: "I am going to the park.",
                        mandarin: {
                            pinyin: "Wǒ yào qù gōngyuán.",
                            characters: "我要去公园。"
                        }
                    },
                    {
                        english: "Do you have any siblings?",
                        mandarin: {
                            pinyin: "Nǐ yǒu xiōngdì jiěmèi ma?",
                            characters: "你有兄弟姐妹吗？"
                        }
                    },
                    {
                        english: "I have one sister.",
                        mandarin: {
                            pinyin: "Wǒ yǒu yī gè jiějiě.",
                            characters: "我有一个姐姐。"
                        }
                    },
                    {
                        english: "What do you like to do on weekends?",
                        mandarin: {
                            pinyin: "Nǐ zhōumò xǐhuān zuò shénme?",
                            characters: "你周末喜欢做什么？"
                        }
                    },
                    {
                        english: "I like to go hiking.",
                        mandarin: {
                            pinyin: "Wǒ xǐhuān páshān.",
                            characters: "我喜欢爬山。"
                        }
                    }
                ]
            },
            {
                sublevel: "2.3",
                conversations: [
                    {
                        english: "Do you have a favorite movie?",
                        mandarin: {
                            pinyin: "Nǐ yǒu zuì xǐhuān de diànyǐng ma?",
                            characters: "你有最喜欢的电影吗？"
                        }
                    },
                    {
                        english: "Yes, I love 'The Lion King.'",
                        mandarin: {
                            pinyin: "Shì de, wǒ ài 'Shīzǐ Wáng'.",
                            characters: "是的，我爱《狮子王》。"
                        }
                    },
                    {
                        english: "Do you like music?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān yīnyuè ma?",
                            characters: "你喜欢音乐吗？"
                        }
                    },
                    {
                        english: "Yes, I like music a lot.",
                        mandarin: {
                            pinyin: "Shì de, wǒ hěn xǐhuān yīnyuè.",
                            characters: "是的，我很喜欢音乐。"
                        }
                    },
                    {
                        english: "What is your favorite song?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de gē shì shénme?",
                            characters: "你最喜欢的歌是什么？"
                        }
                    },
                    {
                        english: "My favorite song is 'Imagine.'",
                        mandarin: {
                            pinyin: "Wǒ zuì xǐhuān de gē shì 'Imagine'.",
                            characters: "我最喜欢的歌是《Imagine》。"
                        }
                    }
                ]
            },
            {
                sublevel: "2.4",
                conversations: [
                    {
                        english: "Can you swim?",
                        mandarin: {
                            pinyin: "Nǐ huì yóuyǒng ma?",
                            characters: "你会游泳吗？"
                        }
                    },
                    {
                        english: "Yes, I can swim.",
                        mandarin: {
                            pinyin: "Shì de, wǒ huì yóuyǒng.",
                            characters: "是的，我会游泳。"
                        }
                    },
                    {
                        english: "Do you like the beach?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān hǎitān ma?",
                            characters: "你喜欢海滩吗？"
                        }
                    },
                    {
                        english: "I love the beach.",
                        mandarin: {
                            pinyin: "Wǒ ài hǎitān.",
                            characters: "我爱海滩。"
                        }
                    },
                    {
                        english: "What is your favorite season?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de jìjié shì shénme?",
                            characters: "你最喜欢的季节是什么？"
                        }
                    },
                    {
                        english: "My favorite season is summer.",
                        mandarin: {
                            pinyin: "Wǒ zuì xǐhuān de jìjié shì xiàtiān.",
                            characters: "我最喜欢的季节是夏天。"
                        }
                    }
                ]
            },
            {
                sublevel: "2.5",
                conversations: [
                    {
                        english: "Do you like to read books?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān kàn shū ma?",
                            characters: "你喜欢看书吗？"
                        }
                    },
                    {
                        english: "Yes, I enjoy reading.",
                        mandarin: {
                            pinyin: "Shì de, wǒ xǐhuān kàn shū.",
                            characters: "是的，我喜欢看书。"
                        }
                    },
                    {
                        english: "What kind of books do you like?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān shénme yàng de shū?",
                            characters: "你喜欢什么样的书？"
                        }
                    },
                    {
                        english: "I like adventure books.",
                        mandarin: {
                            pinyin: "Wǒ xǐhuān màoxiǎn xiǎoshuō.",
                            characters: "我喜欢冒险小说。"
                        }
                    },
                    {
                        english: "Do you have a favorite author?",
                        mandarin: {
                            pinyin: "Nǐ yǒu zuì xǐhuān de zuòjiā ma?",
                            characters: "你有最喜欢的作家吗？"
                        }
                    },
                    {
                        english: "Yes, I like J.K. Rowling.",
                        mandarin: {
                            pinyin: "Shì de, wǒ xǐhuān J.K. Luólín.",
                            characters: "是的，我喜欢J.K. 罗琳。"
                        }
                    }
                ]
            }
        ]
    },
    {
        level: "3",
        sublevels: [
            {
                sublevel: "3.1",
                conversations: [
                    {
                        english: "How do you usually commute to work?",
                        mandarin: {
                            pinyin: "Nǐ tōngcháng zěnme shàngbān?",
                            characters: "你通常怎么上班？"
                        }
                    },
                    {
                        english: "I usually take the bus.",
                        mandarin: {
                            pinyin: "Wǒ tōngcháng zuò gōngjiāo chē.",
                            characters: "我通常坐公交车。"
                        }
                    },
                    {
                        english: "What kind of movies do you like?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān shénme yàng de diànyǐng?",
                            characters: "你喜欢什么样的电影？"
                        }
                    },
                    {
                        english: "I like comedy movies.",
                        mandarin: {
                            pinyin: "Wǒ xǐhuān xǐjù diànyǐng.",
                            characters: "我喜欢喜剧电影。"
                        }
                    },
                    {
                        english: "Have you traveled abroad?",
                        mandarin: {
                            pinyin: "Nǐ qùguò guówài ma?",
                            characters: "你去过国外吗？"
                        }
                    },
                    {
                        english: "Yes, I have been to Japan.",
                        mandarin: {
                            pinyin: "Shì de, wǒ qùguò Rìběn.",
                            characters: "是的，我去过日本。"
                        }
                    }
                ]
            },
            {
                sublevel: "3.2",
                conversations: [
                    {
                        english: "What is your favorite food?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de shíwù shì shénme?",
                            characters: "你最喜欢的食物是什么？"
                        }
                    },
                    {
                        english: "My favorite food is dumplings.",
                        mandarin: {
                            pinyin: "Wǒ zuì xǐhuān de shíwù shì jiǎozi.",
                            characters: "我最喜欢的食物是饺子。"
                        }
                    },
                    {
                        english: "Do you like shopping?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān gòuwù ma?",
                            characters: "你喜欢购物吗？"
                        }
                    },
                    {
                        english: "Yes, I love shopping for clothes.",
                        mandarin: {
                            pinyin: "Shì de, wǒ xǐhuān mǎi yīfú.",
                            characters: "是的，我喜欢买衣服。"
                        }
                    },
                    {
                        english: "What is your dream job?",
                        mandarin: {
                            pinyin: "Nǐ de mèngxiǎng zhíyè shì shénme?",
                            characters: "你的梦想职业是什么？"
                        }
                    },
                    {
                        english: "I want to be a doctor.",
                        mandarin: {
                            pinyin: "Wǒ xiǎng dāng yīshēng.",
                            characters: "我想当医生。"
                        }
                    }
                ]
            },
            {
                sublevel: "3.3",
                conversations: [
                    {
                        english: "Do you have any pets?",
                        mandarin: {
                            pinyin: "Nǐ yǒu chǒngwù ma?",
                            characters: "你有宠物吗？"
                        }
                    },
                    {
                        english: "Yes, I have a cat.",
                        mandarin: {
                            pinyin: "Shì de, wǒ yǒu yī zhī māo.",
                            characters: "是的，我有一只猫。"
                        }
                    },
                    {
                        english: "Do you know how to drive?",
                        mandarin: {
                            pinyin: "Nǐ huì kāichē ma?",
                            characters: "你会开车吗？"
                        }
                    },
                    {
                        english: "No, I don't have a driver's license.",
                        mandarin: {
                            pinyin: "Bù, wǒ méiyǒu jiàzhào.",
                            characters: "不，我没有驾照。"
                        }
                    },
                    {
                        english: "What time do you usually wake up?",
                        mandarin: {
                            pinyin: "Nǐ tōngcháng jǐ diǎn qǐchuáng?",
                            characters: "你通常几点起床？"
                        }
                    },
                    {
                        english: "I usually wake up at 7 a.m.",
                        mandarin: {
                            pinyin: "Wǒ tōngcháng zǎoshang qī diǎn qǐchuáng.",
                            characters: "我通常早上七点起床。"
                        }
                    }
                ]
            },
            {
                sublevel: "3.4",
                conversations: [
                    {
                        english: "Do you enjoy cooking?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān zuò fàn ma?",
                            characters: "你喜欢做饭吗？"
                        }
                    },
                    {
                        english: "Yes, I like to cook Chinese food.",
                        mandarin: {
                            pinyin: "Shì de, wǒ xǐhuān zuò Zhōngguó cài.",
                            characters: "是的，我喜欢做中国菜。"
                        }
                    },
                    {
                        english: "Do you have any plans for the weekend?",
                        mandarin: {
                            pinyin: "Nǐ zhōumò yǒu shénme jìhuà ma?",
                            characters: "你周末有什么计划吗？"
                        }
                    },
                    {
                        english: "I plan to visit my friend.",
                        mandarin: {
                            pinyin: "Wǒ dǎsuàn qù kàn péngyǒu.",
                            characters: "我打算去看朋友。"
                        }
                    },
                    {
                        english: "What kind of music do you enjoy?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān shénme yàng de yīnyuè?",
                            characters: "你喜欢什么样的音乐？"
                        }
                    },
                    {
                        english: "I enjoy classical music.",
                        mandarin: {
                            pinyin: "Wǒ xǐhuān gǔdiǎn yīnyuè.",
                            characters: "我喜欢古典音乐。"
                        }
                    }
                ]
            },
            {
                sublevel: "3.5",
                conversations: [
                    {
                        english: "Have you ever been to a concert?",
                        mandarin: {
                            pinyin: "Nǐ qùguò yīnyuè huì ma?",
                            characters: "你去过音乐会吗？"
                        }
                    },
                    {
                        english: "Yes, I went last year.",
                        mandarin: {
                            pinyin: "Shì de, wǒ qùnián qùle.",
                            characters: "是的，我去年去了。"
                        }
                    },
                    {
                        english: "Do you like to play games?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān wán yóuxì ma?",
                            characters: "你喜欢玩游戏吗？"
                        }
                    },
                    {
                        english: "Yes, I enjoy video games.",
                        mandarin: {
                            pinyin: "Shì de, wǒ xǐhuān diànzǐ yóuxì.",
                            characters: "是的，我喜欢电子游戏。"
                        }
                    },
                    {
                        english: "What is your favorite holiday?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de jiérì shì shénme?",
                            characters: "你最喜欢的节日是什么？"
                        }
                    },
                    {
                        english: "My favorite holiday is New Year's Day.",
                        mandarin: {
                            pinyin: "Wǒ zuì xǐhuān de jiérì shì Yuándàn.",
                            characters: "我最喜欢的节日是元旦。"
                        }
                    }
                ]
            }
        ]
    },
    {
        level: "4",
        sublevels: [
            {
                sublevel: "4.1",
                conversations: [
                    {
                        english: "What time do you usually go to bed?",
                        mandarin: {
                            pinyin: "Nǐ tōngcháng jǐ diǎn shuìjiào?",
                            characters: "你通常几点睡觉？"
                        }
                    },
                    {
                        english: "I usually go to bed at 10 p.m.",
                        mandarin: {
                            pinyin: "Wǒ tōngcháng wǎnshàng shí diǎn shuìjiào.",
                            characters: "我通常晚上十点睡觉。"
                        }
                    },
                    {
                        english: "Do you have any siblings?",
                        mandarin: {
                            pinyin: "Nǐ yǒu xiōngdì jiěmèi ma?",
                            characters: "你有兄弟姐妹吗？"
                        }
                    },
                    {
                        english: "Yes, I have an older sister.",
                        mandarin: {
                            pinyin: "Shì de, wǒ yǒu yī gè jiějie.",
                            characters: "是的，我有一个姐姐。"
                        }
                    },
                    {
                        english: "What is your hobby?",
                        mandarin: {
                            pinyin: "Nǐ de àihào shì shénme?",
                            characters: "你的爱好是什么？"
                        }
                    },
                    {
                        english: "My hobby is painting.",
                        mandarin: {
                            pinyin: "Wǒ de àihào shì huà huà.",
                            characters: "我的爱好是画画。"
                        }
                    }
                ]
            },
            {
                sublevel: "4.2",
                conversations: [
                    {
                        english: "What is your favorite season?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de jìjié shì shénme?",
                            characters: "你最喜欢的季节是什么？"
                        }
                    },
                    {
                        english: "I love spring the most.",
                        mandarin: {
                            pinyin: "Wǒ zuì xǐhuān chūntiān.",
                            characters: "我最喜欢春天。"
                        }
                    },
                    {
                        english: "Do you play any musical instruments?",
                        mandarin: {
                            pinyin: "Nǐ huì tán yīnyuè yuèqì ma?",
                            characters: "你会弹音乐乐器吗？"
                        }
                    },
                    {
                        english: "Yes, I can play the piano.",
                        mandarin: {
                            pinyin: "Shì de, wǒ huì tán gāngqín.",
                            characters: "是的，我会弹钢琴。"
                        }
                    },
                    {
                        english: "How often do you exercise?",
                        mandarin: {
                            pinyin: "Nǐ duōjiǔ yùndòng yīcì?",
                            characters: "你多久运动一次？"
                        }
                    },
                    {
                        english: "I exercise three times a week.",
                        mandarin: {
                            pinyin: "Wǒ měi zhōu yùndòng sān cì.",
                            characters: "我每周运动三次。"
                        }
                    }
                ]
            },
            {
                sublevel: "4.3",
                conversations: [
                    {
                        english: "What is your favorite book?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de shū shì shénme?",
                            characters: "你最喜欢的书是什么？"
                        }
                    },
                    {
                        english: "My favorite book is 'Pride and Prejudice.'",
                        mandarin: {
                            pinyin: "Wǒ zuì xǐhuān de shū shì 《Àomàn yǔ Piānjiàn》.",
                            characters: "我最喜欢的书是《傲慢与偏见》。"
                        }
                    },
                    {
                        english: "Do you like spicy food?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān chī là de shíwù ma?",
                            characters: "你喜欢吃辣的食物吗？"
                        }
                    },
                    {
                        english: "Yes, I love spicy food.",
                        mandarin: {
                            pinyin: "Shì de, wǒ xǐhuān chī là de shíwù.",
                            characters: "是的，我喜欢吃辣的食物。"
                        }
                    },
                    {
                        english: "Where did you grow up?",
                        mandarin: {
                            pinyin: "Nǐ zài nǎlǐ zhǎngdà de?",
                            characters: "你在哪里长大的？"
                        }
                    },
                    {
                        english: "I grew up in Beijing.",
                        mandarin: {
                            pinyin: "Wǒ zài Běijīng zhǎngdà.",
                            characters: "我在北京长大。"
                        }
                    }
                ]
            },
            {
                sublevel: "4.4",
                conversations: [
                    {
                        english: "Do you have any special skills?",
                        mandarin: {
                            pinyin: "Nǐ yǒu shénme tèbié de jìnéng ma?",
                            characters: "你有什么特别的技能吗？"
                        }
                    },
                    {
                        english: "I can speak three languages.",
                        mandarin: {
                            pinyin: "Wǒ huì shuō sān zhǒng yǔyán.",
                            characters: "我会说三种语言。"
                        }
                    },
                    {
                        english: "What is your favorite sport?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de yùndòng shì shénme?",
                            characters: "你最喜欢的运动是什么？"
                        }
                    },
                    {
                        english: "I enjoy playing basketball.",
                        mandarin: {
                            pinyin: "Wǒ xǐhuān dǎ lánqiú.",
                            characters: "我喜欢打篮球。"
                        }
                    },
                    {
                        english: "Do you like to read the news?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān kàn xīnwén ma?",
                            characters: "你喜欢看新闻吗？"
                        }
                    },
                    {
                        english: "Yes, I read the news every morning.",
                        mandarin: {
                            pinyin: "Shì de, wǒ měitiān zǎoshang dū kàn xīnwén.",
                            characters: "是的，我每天早上都看新闻。"
                        }
                    }
                ]
            },
            {
                sublevel: "4.5",
                conversations: [
                    {
                        english: "What is your favorite drink?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de yǐnliào shì shénme?",
                            characters: "你最喜欢的饮料是什么？"
                        }
                    },
                    {
                        english: "I love drinking green tea.",
                        mandarin: {
                            pinyin: "Wǒ xǐhuān hē lǜchá.",
                            characters: "我喜欢喝绿茶。"
                        }
                    },
                    {
                        english: "Do you have any allergies?",
                        mandarin: {
                            pinyin: "Nǐ yǒu rènhé guòmǐn ma?",
                            characters: "你有任何过敏吗？"
                        }
                    },
                    {
                        english: "Yes, I'm allergic to nuts.",
                        mandarin: {
                            pinyin: "Shì de, wǒ duì jiānguǒ guòmǐn.",
                            characters: "是的，我对坚果过敏。"
                        }
                    },
                    {
                        english: "How do you relax after work?",
                        mandarin: {
                            pinyin: "Nǐ gōngzuò hòu zěnme fàngsōng?",
                            characters: "你工作后怎么放松？"
                        }
                    },
                    {
                        english: "I like to watch TV shows.",
                        mandarin: {
                            pinyin: "Wǒ xǐhuān kàn diànshì jù.",
                            characters: "我喜欢看电视剧。"
                        }
                    }
                ]
            }
        ]
    },
    {
        level: "5",
        sublevels: [
            {
                sublevel: "5.1",
                conversations: [
                    {
                        english: "What do you usually eat for breakfast?",
                        mandarin: {
                            pinyin: "Nǐ tōngcháng zǎocān chī shénme?",
                            characters: "你通常早餐吃什么？"
                        }
                    },
                    {
                        english: "I usually eat eggs and toast.",
                        mandarin: {
                            pinyin: "Wǒ tōngcháng chī jīdàn hé tǔsī.",
                            characters: "我通常吃鸡蛋和吐司。"
                        }
                    },
                    {
                        english: "Do you like to travel?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān lǚyóu ma?",
                            characters: "你喜欢旅游吗？"
                        }
                    },
                    {
                        english: "Yes, I love visiting new places.",
                        mandarin: {
                            pinyin: "Shì de, wǒ xǐhuān cānguān xīn dìfāng.",
                            characters: "是的，我喜欢参观新地方。"
                        }
                    },
                    {
                        english: "Where did you go on your last vacation?",
                        mandarin: {
                            pinyin: "Nǐ zuìjìn yīcì jiàqī qù nǎlǐ le?",
                            characters: "你最近一次假期去哪里了？"
                        }
                    },
                    {
                        english: "I went to Japan last vacation.",
                        mandarin: {
                            pinyin: "Wǒ shàng yīcì jiàqī qù le Rìběn.",
                            characters: "我上一次假期去了日本。"
                        }
                    }
                ]
            },
            {
                sublevel: "5.2",
                conversations: [
                    {
                        english: "What do you do on weekends?",
                        mandarin: {
                            pinyin: "Nǐ zhōumò zuò shénme?",
                            characters: "你周末做什么？"
                        }
                    },
                    {
                        english: "I usually go hiking.",
                        mandarin: {
                            pinyin: "Wǒ tōngcháng qù páshān.",
                            characters: "我通常去爬山。"
                        }
                    },
                    {
                        english: "Do you like cooking?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān zuò fàn ma?",
                            characters: "你喜欢做饭吗？"
                        }
                    },
                    {
                        english: "Yes, I enjoy making pasta.",
                        mandarin: {
                            pinyin: "Shì de, wǒ xǐhuān zuò yìdàlì miàn.",
                            characters: "是的，我喜欢做意大利面。"
                        }
                    },
                    {
                        english: "How do you usually commute to work?",
                        mandarin: {
                            pinyin: "Nǐ tōngcháng zěnme shàngbān?",
                            characters: "你通常怎么上班？"
                        }
                    },
                    {
                        english: "I usually take the subway.",
                        mandarin: {
                            pinyin: "Wǒ tōngcháng zuò dìtiě.",
                            characters: "我通常坐地铁。"
                        }
                    }
                ]
            },
            {
                sublevel: "5.3",
                conversations: [
                    {
                        english: "Do you have any pets?",
                        mandarin: {
                            pinyin: "Nǐ yǒu chǒngwù ma?",
                            characters: "你有宠物吗？"
                        }
                    },
                    {
                        english: "Yes, I have a cat.",
                        mandarin: {
                            pinyin: "Shì de, wǒ yǒu yī zhī māo.",
                            characters: "是的，我有一只猫。"
                        }
                    },
                    {
                        english: "What is your cat's name?",
                        mandarin: {
                            pinyin: "Nǐ de māo jiào shénme míngzi?",
                            characters: "你的猫叫什么名字？"
                        }
                    },
                    {
                        english: "My cat's name is Luna.",
                        mandarin: {
                            pinyin: "Wǒ de māo jiào Lùnà.",
                            characters: "我的猫叫露娜。"
                        }
                    },
                    {
                        english: "Do you prefer tea or coffee?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān chá háishì kāfēi?",
                            characters: "你喜欢茶还是咖啡？"
                        }
                    },
                    {
                        english: "I prefer tea.",
                        mandarin: {
                            pinyin: "Wǒ gèng xǐhuān chá.",
                            characters: "我更喜欢茶。"
                        }
                    }
                ]
            },
            {
                sublevel: "5.4",
                conversations: [
                    {
                        english: "What kind of music do you like?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān shénme lèixíng de yīnyuè?",
                            characters: "你喜欢什么类型的音乐？"
                        }
                    },
                    {
                        english: "I like classical music.",
                        mandarin: {
                            pinyin: "Wǒ xǐhuān gǔdiǎn yīnyuè.",
                            characters: "我喜欢古典音乐。"
                        }
                    },
                    {
                        english: "Have you ever been to a concert?",
                        mandarin: {
                            pinyin: "Nǐ cānjiāguò yīnyuè huì ma?",
                            characters: "你参加过音乐会吗？"
                        }
                    },
                    {
                        english: "Yes, I have been to many concerts.",
                        mandarin: {
                            pinyin: "Shì de, wǒ cānjiāguò hěn duō yīnyuè huì.",
                            characters: "是的，我参加过很多音乐会。"
                        }
                    },
                    {
                        english: "What is your dream job?",
                        mandarin: {
                            pinyin: "Nǐ de mèngxiǎng gōngzuò shì shénme?",
                            characters: "你的梦想工作是什么？"
                        }
                    },
                    {
                        english: "My dream job is to be a writer.",
                        mandarin: {
                            pinyin: "Wǒ de mèngxiǎng gōngzuò shì zuò zuòjiā.",
                            characters: "我的梦想工作是做作家。"
                        }
                    }
                ]
            },
            {
                sublevel: "5.5",
                conversations: [
                    {
                        english: "Do you believe in luck?",
                        mandarin: {
                            pinyin: "Nǐ xiāngxìn yùnqì ma?",
                            characters: "你相信运气吗？"
                        }
                    },
                    {
                        english: "Yes, I believe in luck.",
                        mandarin: {
                            pinyin: "Shì de, wǒ xiāngxìn yùnqì.",
                            characters: "是的，我相信运气。"
                        }
                    },
                    {
                        english: "What is your favorite movie?",
                        mandarin: {
                            pinyin: "Nǐ zuì xǐhuān de diànyǐng shì shénme?",
                            characters: "你最喜欢的电影是什么？"
                        }
                    },
                    {
                        english: "My favorite movie is 'Inception.'",
                        mandarin: {
                            pinyin: "Wǒ zuì xǐhuān de diànyǐng shì 《Dàomèng kòngjiān》.",
                            characters: "我最喜欢的电影是《盗梦空间》。"
                        }
                    },
                    {
                        english: "Do you like to dance?",
                        mandarin: {
                            pinyin: "Nǐ xǐhuān tiàowǔ ma?",
                            characters: "你喜欢跳舞吗？"
                        }
                    },
                    {
                        english: "Yes, I enjoy dancing.",
                        mandarin: {
                            pinyin: "Shì de, wǒ xǐhuān tiàowǔ.",
                            characters: "是的，我喜欢跳舞。"
                        }
                    }
                ]
            }
        ]
    }
];
