import confetti from "canvas-confetti";

export const aiGamesData = [
  {
    id: "wordmatch",
    title: "Word Match",
    icon: "🎮",
    description: "Match words with their meanings and images",
  },
  {
    id: "speechmaster",
    title: "Speech Master",
    icon: "🎤",
    description: "Practice pronunciation with AI feedback",
  },
  {
    id: "chatadventure",
    title: "Chat Adventure",
    icon: "💭",
    description: "Role-play conversations with AI characters",
  },
  {
    id: "grammarquest",
    title: "Grammar Quest",
    icon: "📝",
    description: "Fix sentences and learn grammar rules",
  },
  {
    id: "vocabbuilder",
    title: "Vocabulary Builder",
    icon: "🎯",
    description: "Learn new words through interactive exercises",
  },
  {
    id: "storycreator",
    title: "Story Creator",
    icon: "📚",
    description: "Create stories with AI guidance for your kids",
  },
];

export const categories = [
  { value: "General Knowledge", label: "General Knowledge", icon: "🎯" },
  { value: "Food Recipe", label: "Food Recipe", icon: "🍳" },
  { value: "Animals", label: "Animals", icon: "🐾" },
  { value: "Sports & Fitness", label: "Sports & Fitness", icon: "⚽" },
  { value: "Movies", label: "Movies", icon: "🎬" },
  { value: "Travel", label: "Travel", icon: "✈️" },
  { value: "Science", label: "Science", icon: "🔬" },
  { value: "Art", label: "Art", icon: "🎨" },
  { value: "History", label: "History", icon: "📜" },
  { value: "Geography", label: "Geography", icon: "🌍" },
  { value: "Literature", label: "Literature", icon: "📚" },
];

export const fireConfetti = () => {
  confetti({
    particleCount: 100,
    spread: 100,
    origin: { x: 0.4, y: 0.6 },
    angle: 45,
  });

  confetti({
    particleCount: 100,
    spread: 100,
    origin: { x: 0.6, y: 0.6 },
    angle: 135,
  });
};

export const fireGameCompleteConfetti = () => {
  const duration = 2000;
  const end = Date.now() + duration;

  const frame = () => {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0, y: 0.8 },
    });

    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1, y: 0.8 },
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  };

  frame();
};
