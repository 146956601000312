import React, { useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Slide, Zoom } from "react-awesome-reveal";
import { teamMembers } from "../../constants/data.js";

const Team = () => {
  const [expanded, setExpanded] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  // Determine how many members to display
  const membersToShow = showAll ? teamMembers : teamMembers.slice(0, 2);
  return (
    <section id="team" className="team-section py-5 my-5" style={{ backgroundColor: "#7335b7", color: "#ffffff" }}>
      <br />
      <Container>
        <Row className="text-center mb-4">
          <Zoom>
            <Col>
              <h2
                className="mb-4"
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "700",
                  borderBottom: "2px solid #ffffff",
                  display: "inline-block",
                  paddingBottom: "10px",
                }}
              >
                Meet Our Global Team
              </h2>
            </Col>
          </Zoom>
        </Row>

        {membersToShow.map((member, index) => (
          <Slide direction={member.slideDirection}>
            <Row key={index} className="align-items-center mb-5">
              <Col lg={4} className="mb-4 mb-lg-0 d-flex justify-content-center">
                <div className="team-image-wrapper">
                  <Image src={member.image} fluid rounded className="team-image" />
                </div>
              </Col>
              <Col lg={8}>
                <div className="team-member-info">
                  <h3 className="title mb-3" style={{ fontSize: "1.75rem", fontWeight: "700" }}>
                    {member.role}
                  </h3>

                  <div className={`team-member-content ${expanded === index ? "expanded" : ""}`} style={{ position: "relative" }}>
                    <p
                      style={{
                        fontSize: "1.125rem",
                        lineHeight: "1.75rem",
                      }}
                    >
                      {expanded === index ? member.content : `${member.content.slice(0, 450)}${member.content.length > 450 ? "..." : ""}`}
                    </p>

                    {member.content.length > 450 && (
                      <Button
                        variant="link"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          color: "#ffffff",
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        onClick={() => handleToggle(index)}
                      >
                        {expanded === index ? "Read Less" : "Read More"}
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Slide>
        ))}

        {/* Show More or Show Less button */}
        <Row className="text-center mt-4">
          <Col>
            <Button
              variant="outline-light"
              onClick={handleShowAll}
              style={{
                fontWeight: "bold",
                borderRadius: "20px",
                padding: "10px 20px",
              }}
            >
              {showAll ? "Show Less" : "Show More"}
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Team;
