import React from "react";
import { Link } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <div className="tw-hidden sm:tw-block tw-py-3" style={{ backgroundColor: "#7335b7", width: "100%", position: "absolute", bottom: "0" }}>
      <Container className="d-flex justify-content-center fs-6">
        <strong className="text-light text-center">
          Copyright &copy; {year} |{" "}
          <Link to="https://www.happy-plum.com" target="_blank" style={{ color: "inherit", textDecoration: "none" }}>
            <b>Happy Plum</b>
          </Link>
          {"  "}, All rights are reserved.
        </strong>
      </Container>
    </div>
  );
}

export default Footer;
