import { Col, Stack, Form } from "react-bootstrap";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";

const InfoItem = ({ icon, title, text }) => (
  <div className="info-item d-flex align-items-center" style={{ marginBottom: "20px" }}>
    <Stack direction="horizontal" gap={3}>
      {icon}
      <div>
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </Stack>
  </div>
);

const FormField = ({ name, placeholder, type, as = "input" }) => (
  <Col md={12} className="form-group mb-3">
    <Form.Control type={type} as={as} name={name} placeholder={placeholder} required />
  </Col>
);

// Social Media Links component
const SocialLinks = () => (
  <Stack direction="horizontal" gap={3}>
    <a href="https://www.facebook.com/happyplummandarin" target="blank" style={{ textDecoration: "none", fontSize: "24px", marginRight: "10px" }}>
      <FaFacebook />
    </a>
    <a href="https://www.linkedin.com/company/73955807/admin/feed/posts/" target="blank" style={{ textDecoration: "none", fontSize: "24px", marginRight: "10px" }}>
      <FaLinkedin />
    </a>
    <a href="https://www.youtube.com/@happyplummandarin5661" target="blank" style={{ textDecoration: "none", fontSize: "24px", marginRight: "10px", color: "red" }}>
      <FaYoutube />
    </a>
  </Stack>
);

export { InfoItem, FormField, SocialLinks };
