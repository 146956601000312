import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN, UPDATE_STUDENT_DETAIL } from "../redux/action";

import Login from "./Login";
import Register from "./Register";
import HomePage from "./LandingHome";
import Layout from "./Layout";
import MainLayout from "./MainLayout";
import Profile from "./Profile";
import PaymentSuccess from "./PaymentSuccess";

import {
  Dashboard,
  AnimatedStories,
  SelectLevel,
  Homework,
  Rubric,
  RealLifeScenario,
  Lessons,
  Goal,
  SetGoal,
  GoalProgress,
  StudentCurriculum,
  StudentChats,
  PerformanceStudent,
  ReviewSheet,
  CulturalHighlights,
  FlashCards,
  TestAssessments,
  Project,
  Result,
  EnglishLessons,
  EnglishLevel11,
  EnglishLevel12,
  EnglishLevel13,
  EnglishLevel14,
  EShapes,
  EColors,
  EFruits,
  EVegetables,
  EAnimals,
  ETransportation,
  EWeather,
  EDaysWeek,
  EVocabulary,
  EQuizzes,
  EFruitsQuiz,
  EVegetablesQuiz,
  EAnimalsQuiz,
  EColorsQuiz,
  ETransportationsQuiz,
  EWeathersQuiz,
  EShapesQuiz,
  EDaysOfWeekQuiz,
  ChineseLessons,
  ChineseLevel11,
  ChineseLevel12,
  ChineseLevel13,
  ChineseLevel14,
  CShapes,
  CColors,
  CFruits,
  CVegetables,
  CAnimals,
  CTransportation,
  CWeather,
  CDaysWeek,
  CVocabulary,
  CQuizzes,
  CFruitsQuiz,
  CVegetablesQuiz,
  CAnimalsQuiz,
  CColorsQuiz,
  CTransportationsQuiz,
  CWeathersQuiz,
  CShapesQuiz,
  CDaysOfWeekQuiz,
  SpanishLessons,
  SpanishLevel11,
  SpanishLevel12,
  SpanishLevel13,
  SpanishLevel14,
  SShapes,
  SColors,
  SFruits,
  SVegetables,
  SAnimals,
  STransportation,
  SWeather,
  SDaysWeek,
  SVocabulary,
  SQuizzes,
  SFruitsQuiz,
  SVegetablesQuiz,
  SAnimalsQuiz,
  SColorsQuiz,
  STransportationsQuiz,
  SWeathersQuiz,
  SShapesQuiz,
  SDaysOfWeekQuiz,
  FrenchLessons,
  FrenchLevel11,
  FrenchLevel12,
  FrenchLevel13,
  FrenchLevel14,
  FShapes,
  FColors,
  FFruits,
  FVegetables,
  FAnimals,
  FTransportation,
  FWeather,
  FDaysWeek,
  FVocabulary,
  FQuizzes,
  FFruitsQuiz,
  FVegetablesQuiz,
  FAnimalsQuiz,
  FColorsQuiz,
  FTransportationsQuiz,
  FWeathersQuiz,
  FShapesQuiz,
  FDaysOfWeekQuiz,
  DragAndMatch,
  FruitsGameEnglish,
  VegetablesGameEnglish,
  AnimalsGameEnglish,
  TransportationGameEnglish,
  ColorsGameEnglish,
  FruitsGameMandarin,
  VegetablesGameMandarin,
  AnimalsGameMandarin,
  TransportationGameMandarin,
  ColorsGameMandarin,
  FruitsGameSpanish,
  VegetablesGameSpanish,
  AnimalsGameSpanish,
  TransportationGameSpanish,
  ColorsGameSpanish,
  FruitsGameFrench,
  VegetablesGameFrench,
  AnimalsGameFrench,
  TransportationGameFrench,
  ColorsGameFrench,
} from "./student_dashboard";

import {
  ParentHome,
  ChildProfile,
  DetailedReports,
  ProgressSummary,
  GoalProgressPage,
  SetGoalPage,
  Messages,
  Notifications,
  TeacherFeedback,
  ParentFeedback,
  SupportYourChild,
  AccountManagement,
  Subscriptions,
} from "./parent_dashboard";

import {
  ClassOverview,
  AttendanceTracking,
  StudentList,
  StudentProfile,
  PerformanceGraphs,
  AssignmentManager,
  CreateAssignment,
  SubmissionReview,
  ResourceLibrary,
  ContentCreation,
  UploadShare,
  Inbox,
  ComposeMessage,
  GroupMessages,
  PerformanceOverview,
  EngagementReports,
  ProgressTracking,
  CustomReports,
  TeacherHome,
  ClassList,
} from "./teacher_dashboard";

import AIGamesPages from "./student_dashboard/games/AIGamesPages";
import AIGameDetails from "./student_dashboard/games/AIGameDetails";

const Routing = () => {
  const [types, setTypes] = useState({});
  const dispatch = useDispatch();
  const { user, role } = useSelector((state) => state.LoginReducer);

  useEffect(() => {
    const renderAuth = async () => {
      const auth = await sessionStorage.getItem("user");
      const token = await sessionStorage.getItem("token");
      const role = await sessionStorage.getItem("type");

      const auth1 = JSON.parse(auth);
      const token1 = JSON.parse(token);
      dispatch({
        type: LOGIN,
        isAuthenticated: true,
        token: token1,
        user: auth1,
        role: role,
      });

      if (role === "Student" || role === "Parent") {
        const studentDetail = await sessionStorage.getItem("studentDetail");
        const studentDetail1 = JSON.parse(studentDetail);
        if (studentDetail1) {
          console.log("Student Detail:", studentDetail1);
        }
        dispatch({
          type: UPDATE_STUDENT_DETAIL,
          studentDetail: studentDetail1,
        });
      }

      if (token1) {
        console.log("Token:", token1);
      }
    };
    renderAuth();

    const auth = sessionStorage.getItem("user");
    if (auth) {
      const myObject = JSON.parse(auth);
      setTypes(myObject);
    }
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Routes under the main layout */}
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="/paymentsuccess" element={<PaymentSuccess />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>

        {/* Routes under the dashboard layout */}
        <Route path="/dashboard" element={<MainLayout />}>
          {role === "Student" && (
            <>
              <Route index element={<Dashboard />} />
              <Route path="select-level" element={<SelectLevel />} />
              <Route path="stories" element={<AnimatedStories />} />
              <Route path="homework" element={<Homework types={types} />} />
              <Route path="homework/result" element={<Result />} />
              <Route path="rubric" element={<Rubric />} />
              <Route path="test-assessment/result" element={<Result />} />
              <Route path="real-life-scenario" element={<RealLifeScenario />} />
              <Route path="lessons" element={<Lessons />} />
              <Route path="english_lessons" element={<EnglishLessons />} />
              <Route path="english_lessons/level1_1" element={<EnglishLevel11 />} />
              <Route path="english_lessons/level1_2" element={<EnglishLevel12 />} />
              <Route path="english_lessons/level1_3" element={<EnglishLevel13 />} />
              <Route path="english_lessons/level1_4" element={<EnglishLevel14 />} />
              <Route path="english_lessons/level1_4" element={<EnglishLevel14 />} />
              <Route path="english_lessons/level1_1/shapes" element={<EShapes />} />
              <Route path="english_lessons/level1_1/colors" element={<EColors />} />
              <Route
                path="english_lessons/level1_1/colors/drag-n-drop"
                element={<ColorsGameEnglish />}
              />
              <Route path="english_lessons/level1_1/fruits" element={<EFruits />} />
              <Route
                path="english_lessons/level1_1/fruits/drag-n-drop"
                element={<FruitsGameEnglish />}
              />
              <Route path="english_lessons/level1_1/vegetables" element={<EVegetables />} />
              <Route
                path="english_lessons/level1_1/vegetables/drag-n-drop"
                element={<VegetablesGameEnglish />}
              />
              <Route path="english_lessons/level1_1/animals" element={<EAnimals />} />
              <Route
                path="english_lessons/level1_1/animals/drag-n-drop"
                element={<AnimalsGameEnglish />}
              />
              <Route path="english_lessons/level1_1/transportation" element={<ETransportation />} />
              <Route
                path="english_lessons/level1_1/transportation/drag-n-drop"
                element={<TransportationGameEnglish />}
              />
              <Route path="english_lessons/level1_1/weather" element={<EWeather />} />
              <Route path="english_lessons/level1_1/days-of-week" element={<EDaysWeek />} />
              <Route path="english_lessons/level1_1/vocabulary" element={<EVocabulary />} />
              <Route path="english_lessons/level1_1/quizzes" element={<EQuizzes />} />
              <Route
                path="english_lessons/level1_1/quizzes/fruits-quiz"
                element={<EFruitsQuiz />}
              />
              <Route
                path="english_lessons/level1_1/quizzes/vegetables-quiz"
                element={<EVegetablesQuiz />}
              />
              <Route
                path="english_lessons/level1_1/quizzes/animals-quiz"
                element={<EAnimalsQuiz />}
              />
              <Route
                path="english_lessons/level1_1/quizzes/colors-quiz"
                element={<EColorsQuiz />}
              />
              <Route
                path="english_lessons/level1_1/quizzes/transportations-quiz"
                element={<ETransportationsQuiz />}
              />
              <Route
                path="english_lessons/level1_1/quizzes/weathers-quiz"
                element={<EWeathersQuiz />}
              />
              <Route
                path="english_lessons/level1_1/quizzes/shapes-quiz"
                element={<EShapesQuiz />}
              />
              <Route
                path="english_lessons/level1_1/quizzes/daysofweek-quiz"
                element={<EDaysOfWeekQuiz />}
              />
              <Route path="chinese_lessons" element={<ChineseLessons />} />
              <Route path="chinese_lessons/level1_1" element={<ChineseLevel11 />} />
              <Route path="chinese_lessons/level1_2" element={<ChineseLevel12 />} />
              <Route path="chinese_lessons/level1_3" element={<ChineseLevel13 />} />
              <Route path="chinese_lessons/level1_4" element={<ChineseLevel14 />} />
              <Route path="chinese_lessons/level1_4" element={<ChineseLevel14 />} />
              <Route path="chinese_lessons/level1_1/shapes" element={<CShapes />} />
              <Route path="chinese_lessons/level1_1/colors" element={<CColors />} />
              <Route
                path="chinese_lessons/level1_1/colors/drag-n-drop"
                element={<ColorsGameMandarin />}
              />
              <Route path="chinese_lessons/level1_1/fruits" element={<CFruits />} />
              <Route
                path="chinese_lessons/level1_1/fruits/drag-n-drop"
                element={<FruitsGameMandarin />}
              />
              <Route path="chinese_lessons/level1_1/vegetables" element={<CVegetables />} />
              <Route
                path="chinese_lessons/level1_1/vegetables/drag-n-drop"
                element={<VegetablesGameMandarin />}
              />
              <Route path="chinese_lessons/level1_1/animals" element={<CAnimals />} />
              <Route
                path="chinese_lessons/level1_1/animals/drag-n-drop"
                element={<AnimalsGameMandarin />}
              />
              <Route path="chinese_lessons/level1_1/transportation" element={<CTransportation />} />
              <Route
                path="chinese_lessons/level1_1/transportation/drag-n-drop"
                element={<TransportationGameMandarin />}
              />
              <Route path="chinese_lessons/level1_1/weather" element={<CWeather />} />
              <Route path="chinese_lessons/level1_1/days-of-week" element={<CDaysWeek />} />
              <Route path="chinese_lessons/level1_1/vocabulary" element={<CVocabulary />} />
              <Route path="chinese_lessons/level1_1/quizzes" element={<CQuizzes />} />
              <Route
                path="chinese_lessons/level1_1/quizzes/fruits-quiz"
                element={<CFruitsQuiz />}
              />
              <Route
                path="chinese_lessons/level1_1/quizzes/vegetables-quiz"
                element={<CVegetablesQuiz />}
              />
              <Route
                path="chinese_lessons/level1_1/quizzes/animals-quiz"
                element={<CAnimalsQuiz />}
              />
              <Route
                path="chinese_lessons/level1_1/quizzes/colors-quiz"
                element={<CColorsQuiz />}
              />
              <Route
                path="chinese_lessons/level1_1/quizzes/transportations-quiz"
                element={<CTransportationsQuiz />}
              />
              <Route
                path="chinese_lessons/level1_1/quizzes/weathers-quiz"
                element={<CWeathersQuiz />}
              />
              <Route
                path="chinese_lessons/level1_1/quizzes/shapes-quiz"
                element={<CShapesQuiz />}
              />
              <Route
                path="chinese_lessons/level1_1/quizzes/daysofweek-quiz"
                element={<CDaysOfWeekQuiz />}
              />
              <Route path="spanish_lessons" element={<SpanishLessons />} />
              <Route path="spanish_lessons/level1_1" element={<SpanishLevel11 />} />
              <Route path="spanish_lessons/level1_2" element={<SpanishLevel12 />} />
              <Route path="spanish_lessons/level1_3" element={<SpanishLevel13 />} />
              <Route path="spanish_lessons/level1_4" element={<SpanishLevel14 />} />
              <Route path="spanish_lessons/level1_1/shapes" element={<SShapes />} />
              <Route path="spanish_lessons/level1_1/colors" element={<SColors />} />
              <Route
                path="spanish_lessons/level1_1/colors/drag-n-drop"
                element={<ColorsGameSpanish />}
              />
              <Route path="spanish_lessons/level1_1/fruits" element={<SFruits />} />
              <Route
                path="spanish_lessons/level1_1/fruits/drag-n-drop"
                element={<FruitsGameSpanish />}
              />
              <Route path="spanish_lessons/level1_1/vegetables" element={<SVegetables />} />
              <Route
                path="spanish_lessons/level1_1/vegetables/drag-n-drop"
                element={<VegetablesGameSpanish />}
              />
              <Route path="spanish_lessons/level1_1/animals" element={<SAnimals />} />
              <Route
                path="spanish_lessons/level1_1/animals/drag-n-drop"
                element={<AnimalsGameSpanish />}
              />
              <Route path="spanish_lessons/level1_1/transportation" element={<STransportation />} />
              <Route
                path="spanish_lessons/level1_1/transportation/drag-n-drop"
                element={<TransportationGameSpanish />}
              />
              <Route path="spanish_lessons/level1_1/weather" element={<SWeather />} />
              <Route path="spanish_lessons/level1_1/days-of-week" element={<SDaysWeek />} />
              <Route path="spanish_lessons/level1_1/vocabulary" element={<SVocabulary />} />
              <Route path="spanish_lessons/level1_1/quizzes" element={<SQuizzes />} />
              <Route
                path="spanish_lessons/level1_1/quizzes/fruits-quiz"
                element={<SFruitsQuiz />}
              />
              <Route
                path="spanish_lessons/level1_1/quizzes/vegetables-quiz"
                element={<SVegetablesQuiz />}
              />
              <Route
                path="spanish_lessons/level1_1/quizzes/animals-quiz"
                element={<SAnimalsQuiz />}
              />
              <Route
                path="spanish_lessons/level1_1/quizzes/colors-quiz"
                element={<SColorsQuiz />}
              />
              <Route
                path="spanish_lessons/level1_1/quizzes/transportations-quiz"
                element={<STransportationsQuiz />}
              />
              <Route
                path="spanish_lessons/level1_1/quizzes/weathers-quiz"
                element={<SWeathersQuiz />}
              />
              <Route
                path="spanish_lessons/level1_1/quizzes/shapes-quiz"
                element={<SShapesQuiz />}
              />
              <Route
                path="spanish_lessons/level1_1/quizzes/daysofweek-quiz"
                element={<SDaysOfWeekQuiz />}
              />
              <Route path="french_lessons" element={<FrenchLessons />} />
              <Route path="french_lessons/level1_1" element={<FrenchLevel11 />} />
              <Route path="french_lessons/level1_2" element={<FrenchLevel12 />} />
              <Route path="french_lessons/level1_3" element={<FrenchLevel13 />} />
              <Route path="french_lessons/level1_4" element={<FrenchLevel14 />} />
              <Route path="french_lessons/level1_1/shapes" element={<FShapes />} />
              <Route path="french_lessons/level1_1/colors" element={<FColors />} />
              <Route
                path="french_lessons/level1_1/colors/drag-n-drop"
                element={<ColorsGameFrench />}
              />
              <Route path="french_lessons/level1_1/fruits" element={<FFruits />} />
              <Route
                path="french_lessons/level1_1/fruits/drag-n-drop"
                element={<FruitsGameFrench />}
              />
              <Route path="french_lessons/level1_1/vegetables" element={<FVegetables />} />
              <Route
                path="french_lessons/level1_1/vegetables/drag-n-drop"
                element={<VegetablesGameFrench />}
              />
              <Route path="french_lessons/level1_1/animals" element={<FAnimals />} />
              <Route
                path="french_lessons/level1_1/animals/drag-n-drop"
                element={<AnimalsGameFrench />}
              />
              <Route path="french_lessons/level1_1/transportation" element={<FTransportation />} />
              <Route
                path="french_lessons/level1_1/transportation/drag-n-drop"
                element={<TransportationGameFrench />}
              />
              <Route path="french_lessons/level1_1/weather" element={<FWeather />} />
              <Route path="french_lessons/level1_1/days-of-week" element={<FDaysWeek />} />
              <Route path="french_lessons/level1_1/vocabulary" element={<FVocabulary />} />
              <Route path="french_lessons/level1_1/quizzes" element={<FQuizzes />} />
              <Route path="french_lessons/level1_1/quizzes/fruits-quiz" element={<FFruitsQuiz />} />
              <Route
                path="french_lessons/level1_1/quizzes/vegetables-quiz"
                element={<FVegetablesQuiz />}
              />
              <Route
                path="french_lessons/level1_1/quizzes/animals-quiz"
                element={<FAnimalsQuiz />}
              />
              <Route path="french_lessons/level1_1/quizzes/colors-quiz" element={<FColorsQuiz />} />
              <Route
                path="french_lessons/level1_1/quizzes/transportations-quiz"
                element={<FTransportationsQuiz />}
              />
              <Route
                path="french_lessons/level1_1/quizzes/weathers-quiz"
                element={<FWeathersQuiz />}
              />
              <Route path="french_lessons/level1_1/quizzes/shapes-quiz" element={<FShapesQuiz />} />
              <Route
                path="french_lessons/level1_1/quizzes/daysofweek-quiz"
                element={<FDaysOfWeekQuiz />}
              />
              <Route path="goal" element={<Goal />} />
              <Route path="set-goal" element={<SetGoal />} />
              <Route path="goal-progress" element={<GoalProgress />} />
              <Route path="student-curriculum" element={<StudentCurriculum />} />
              <Route path="student-chat" element={<StudentChats />} />
              <Route path="student-performance-stats" element={<PerformanceStudent />} />
              <Route path="review-sheet" element={<ReviewSheet />} />
              <Route path="profile" element={<Profile />} />
              <Route path="cultural-highlights" element={<CulturalHighlights />} />
              <Route path="flash-cards" element={<FlashCards />} />
              <Route path="test-assessments" element={<TestAssessments />} />
              <Route path="project" element={<Project />} />

              {/* GAMES */}
              <Route path="games">
                <Route path="drag-and-match" element={<DragAndMatch />} />
                <Route path="ai-games" element={<AIGamesPages />} />
                <Route path="ai-games/:gameName" element={<AIGameDetails />} />
              </Route>
            </>
          )}
          {role === "Teacher" && (
            <>
              <Route index element={<TeacherHome />} />
              <Route path="class-list" element={<ClassList />} />
              <Route path="class-overview" element={<ClassOverview />} />
              <Route path="attendance-tracking" element={<AttendanceTracking />} />
              <Route path="student-list" element={<StudentList />} />
              <Route path="student-profile" element={<StudentProfile />} />
              <Route path="performance-graphs" element={<PerformanceGraphs />} />
              <Route path="assignment-manager" element={<AssignmentManager types={types} />} />
              <Route path="create-assignment" element={<CreateAssignment types={types} />} />
              <Route path="submission-review" element={<SubmissionReview types={types} />} />
              <Route path="submission-review/result" element={<Result />} />
              <Route path="resource-library" element={<ResourceLibrary />} />
              <Route path="upload-share" element={<UploadShare types={types} />} />
              <Route path="content-creation" element={<ContentCreation types={types} />} />
              <Route path="inbox" element={<Inbox types={types} />} />
              <Route path="compose-message" element={<ComposeMessage types={types} />} />
              <Route path="group-messages" element={<GroupMessages />} />
              <Route path="performance-overview" element={<PerformanceOverview />} />
              <Route path="engagement-reports" element={<EngagementReports />} />
              <Route path="progress-tracking" element={<ProgressTracking />} />
              <Route path="custom-reports" element={<CustomReports />} />
            </>
          )}
          {role === "Parent" && (
            <>
              <Route index element={<ParentHome />} />
              <Route path="child-profile" element={<ChildProfile />} />
              <Route path="detailed-reports" element={<DetailedReports />} />
              <Route path="progress-summary" element={<ProgressSummary />} />
              <Route path="learning-goals" element={<SetGoalPage />} />
              <Route path="progress-towards-goals" element={<GoalProgressPage />} />
              <Route path="messages" element={<Messages />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="teacher-feedback" element={<TeacherFeedback />} />
              <Route path="parent-feedback" element={<ParentFeedback />} />
              <Route path="support-your-child" element={<SupportYourChild />} />
              <Route path="account-management" element={<AccountManagement />} />
              <Route path="subscription-payments" element={<Subscriptions />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
