import React, { useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// Letter Component (draggable)
const DraggableLetter = ({ letter, index, moveLetter }) => {
    const [{ isDragging }, drag] = useDrag({
        type: "letter",
        item: { letter, index },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    return (
        <div
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                fontSize: "24px",
                cursor: "move",
                padding: "8px",
                margin: "4px",
                backgroundColor: "white",
                borderRadius: "4px",
                textAlign: "center",
            }}
        >
            {letter}
        </div>
    );
};

// Drop zone for correct letter placement
const DropZone = ({ acceptLetter, index, correctLetter }) => {
    const [{ isOver }, drop] = useDrop({
        accept: "letter",
        drop: (item) => acceptLetter(item, index),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <div
            ref={drop}
            style={{
                height: "50px",
                width: "50px",
                border: "2px solid #333",
                margin: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: isOver ? "lightgreen" : "white",
            }}
        >
            {correctLetter}
        </div>
    );
};

const DragAndMatch = () => {
    const word = "AMRIT";
    const [correctLetters, setCorrectLetters] = useState(new Array(word.length).fill(""));

    // When letter is dropped into the correct zone
    const handleDrop = (item, dropIndex) => {
        const updatedLetters = [...correctLetters];
        if (word[dropIndex] === item.letter) {
            updatedLetters[dropIndex] = item.letter;
        }
        setCorrectLetters(updatedLetters);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{ display: "flex", justifyContent: "center", padding: "50px" }}>
                {/* Display letters to drag */}
                <div>
                    <h3>Drag the letters to match the word</h3>
                    <div style={{ display: "flex" }}>
                        {word.split("").map((letter, index) => (
                            <DraggableLetter
                                key={index}
                                letter={letter}
                                index={index}
                            />
                        ))}
                    </div>
                </div>

                {/* Display drop zones for word */}
                <div style={{ marginLeft: "50px" }}>
                    <h3>Drop here:</h3>
                    <div style={{ display: "flex" }}>
                        {word.split("").map((_, index) => (
                            <DropZone
                                key={index}
                                acceptLetter={handleDrop}
                                index={index}
                                correctLetter={correctLetters[index]}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </DndProvider>
    );
};

export default DragAndMatch
