import React, { useState, useCallback, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Home, Users } from "lucide-react";
import { postRequestMethod } from "../api/api";
import { MANAGE_USER_LOGIN, MANAGE_USER_VALIDATE_COOKIE } from "../api/server";
import { LOGIN, updateStudentDetail } from "../redux/action";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [variant, setVariant] = useState("");
  const [loginLoading, setLoginLoading] = useState(true);

  const [login, setLogin] = useState({
    email: "",
    password: "",
    type: "",
    studentId: "",
    classId: "",
    parentID: "",
  });

  const [error, setError] = useState({
    email: "",
    password: "",
    type: "",
    studentId: "",
    classId: "",
    parentID: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    postRequestMethod(MANAGE_USER_VALIDATE_COOKIE, "", { dummy: "dummy" })
      .then((res) => {
        const { user, token } = res.data;
        sessionStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("token", JSON.stringify(token));
        sessionStorage.setItem("type", user.type);
        if (user.type === "Student" || user.type === "Parent") {
          sessionStorage.setItem("studentDetail", JSON.stringify(res.data.studentDetail));
          dispatch(updateStudentDetail(res.data.studentDetail));
        }
        dispatch({ type: LOGIN, isAuthenticated: true, token: token, user: user, role: user.type });
        navigate("/dashboard");
      })
      .catch((error) => {
        console.error("Error validating cookie:", error);
      })
      .finally(() => {
        setLoginLoading(false);
      });
  }, [navigate, dispatch]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      const requestData = {
        email: login.email,
        password: login.password,
        type: login.type,
        ...(login.type === "Student" && { studentID: login.studentId }),
        ...(login.type === "Teacher" && { classID: login.classId }),
        ...(login.type === "Parent" && { parentID: login.parentID }),
      };

      try {
        const res = await postRequestMethod(MANAGE_USER_LOGIN, "", requestData);
        sessionStorage.setItem("user", JSON.stringify(res.data.user));
        sessionStorage.setItem("token", JSON.stringify(res.data.auth));
        sessionStorage.setItem("type", login.type);

        if (login.type === "Student" || login.type === "Parent") {
          sessionStorage.setItem("studentDetail", JSON.stringify(res.data.studentDetail));
          dispatch(updateStudentDetail(res.data.studentDetail));
        }

        dispatch({
          type: LOGIN,
          isAuthenticated: true,
          token: res.data.auth,
          user: res.data.user,
          role: login.type,
        });

        setSnackMessage(res.data.message);
        setVariant("success");
        setOpenSnackbar(true);
        navigate("/dashboard");
        // setTimeout(() => navigate('/dashboard'), 2000);
      } catch (error) {
        console.error("Error submitting Login:", error);
        setSnackMessage(error.response?.data?.error || "Something went wrong");
        setVariant("danger");
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    },
    [login, dispatch, navigate]
  );

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  if (loginLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-items-center tw-min-h-screen">
        <div className="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-t-2 tw-border-b-2 tw-border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="tw-py-4 sm:tw-py-0 tw-min-h-screen tw-bg-gray-50 tw-flex tw-justify-center tw-items-center tw-px-4 sm:tw-px-8">
      <div className="tw-bg-white tw-rounded-3xl tw-shadow-lg tw-overflow-hidden tw-w-full tw-max-w-6xl tw-flex">
        {/* Left Side */}
        <div className="tw-hidden md:tw-block tw-w-1/2 tw-bg-purple-600 tw-p-12 tw-text-white">
          <h1 className="tw-text-4xl tw-font-bold tw-mb-4 tw-text-center">Welcome Back!</h1>
          <p className="tw-text-lg tw-mb-20 tw-text-center">Start your educational journey with us</p>

          <div className="tw-flex tw-gap-8 tw-justify-center">
            <div className="tw-bg-purple-500/30 tw-p-4 tw-rounded-xl tw-text-center tw-w-28 tw-h-28 tw-text-xl">
              <Home className="tw-w-10 tw-h-10 tw-mx-auto tw-mb-2" />
              <span>Learn</span>
            </div>
            <div className="tw-bg-purple-500/30 tw-p-4 tw-rounded-xl tw-text-center tw-w-28 tw-h-28 tw-text-xl">
              <Users className="tw-w-10 tw-h-10 tw-mx-auto tw-mb-2" />
              <span>Explore</span>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="tw-w-full md:tw-w-1/2 tw-p-4 sm:tw-p-8">
          <h2 className="tw-text-2xl sm:tw-text-3xl tw-font-bold tw-text-purple-600 tw-mb-8 tw-mt-4">Login to Your Account</h2>

          <form onSubmit={handleSubmit} className="tw-space-y-6">
            <div>
              <label className="tw-block tw-text-base tw-text-gray-500 tw-font-medium tw-mb-2">Email Address</label>
              <input type="email" required className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-purple-600" placeholder="Enter your email" value={login.email} onChange={(e) => setLogin({ ...login, email: e.target.value })} />
            </div>

            <div>
              <label className="tw-block tw-text-base tw-text-gray-500  tw-font-medium tw-mb-2">Password</label>
              <div className="tw-relative">
                <input type={showPassword ? "text" : "password"} required className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-purple-600" placeholder="Enter your password" value={login.password} onChange={(e) => setLogin({ ...login, password: e.target.value })} />
                <button type="button" className="tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2 tw-text-gray-500" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>

            <div>
              <label className="tw-block tw-text-base tw-text-gray-500  tw-font-medium tw-mb-2">Select Role</label>
              <div className="tw-flex tw-gap-6 tw-flex-wrap tw-text-base">
                <label className="tw-flex tw-items-center">
                  <input type="radio" name="role" value="Teacher" checked={login.type === "Teacher"} onChange={(e) => setLogin({ ...login, type: e.target.value })} className="tw-mr-2" />
                  Teacher
                </label>
                <label className="tw-flex tw-items-center">
                  <input type="radio" name="role" value="Parent" checked={login.type === "Parent"} onChange={(e) => setLogin({ ...login, type: e.target.value })} className="tw-mr-2" />
                  Parent
                </label>
                <label className="tw-flex tw-items-center">
                  <input type="radio" name="role" value="Student" checked={login.type === "Student"} onChange={(e) => setLogin({ ...login, type: e.target.value })} className="tw-mr-2" />
                  Student
                </label>
              </div>
            </div>

            {login.type === "Student" && (
              <div>
                <label className="tw-block tw-text-base tw-text-gray-500  tw-font-medium tw-mb-2">Student ID</label>
                <input type="text" required className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-purple-600" placeholder="Enter Student ID" value={login.studentId} onChange={(e) => setLogin({ ...login, studentId: e.target.value })} />
              </div>
            )}

            {login.type === "Parent" && (
              <div>
                <label className="tw-block tw-text-base tw-text-gray-500  tw-font-medium tw-mb-2">Parent ID</label>
                <input type="text" required className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-purple-600" placeholder="Enter Parent ID" value={login.parentID} onChange={(e) => setLogin({ ...login, parentID: e.target.value })} />
              </div>
            )}

            {login.type === "Teacher" && (
              <div>
                <label className="tw-block tw-text-base tw-text-gray-500 tw-font-medium tw-mb-2">Class ID</label>
                <input type="text" required className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-purple-600" placeholder="Enter Class ID" value={login.classId} onChange={(e) => setLogin({ ...login, classId: e.target.value })} />
              </div>
            )}

            <button type="submit" disabled={loading} className="tw-w-full tw-text-lg tw-font-medium tw-bg-purple-600 tw-text-white tw-py-2 tw-rounded-lg hover:tw-bg-purple-700 tw-transition-colors disabled:tw-opacity-50">
              {loading ? "Logging in..." : "Login"}
            </button>

            <p className="tw-text-center tw-text-base tw-text-gray-600">
              Don't have an account?{" "}
              <Link to="/register" className="tw-text-purple-600 tw-font-medium hover:tw-underline">
                Create one
              </Link>
            </p>
          </form>
        </div>
      </div>

      {/* Snackbar */}
      {openSnackbar && (
        <Alert
          variant={variant}
          onClose={handleCloseSnackbar}
          dismissible
          className="snackbar"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 9999,
          }}
        >
          {snackMessage}
        </Alert>
      )}
    </div>
  );
};

export default Login;
