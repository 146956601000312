import React, { useContext } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { ProgressBar, Container, Row, Col } from "react-bootstrap";
import { ProgressContext } from "../../contexts/ProgressContext";
import images from "../../images";
import "../../css/PerformanceStudent.css";

const PerformanceStudent = () => {
  const { progress } = useContext(ProgressContext);

  const studentDetail = sessionStorage.getItem("user");
  const studentDetail1 = JSON.parse(studentDetail);
  // console.log("Student Detail:", studentDetail1);

  // Calculate course completion based on the average progress of all lessons
  const totalLessons = Object.keys(progress).length;
  const totalProgress = Object.values(progress).reduce((acc, curr) => acc + curr, 0);
  const progressPercentage = (totalProgress / (totalLessons * 100)) * 100;

  const studentData = {
    studentName: studentDetail1.fullName,
    testReports: [
      { testName: "English Lessons", score: progress.English },
      { testName: "Mandarin Lessons", score: progress.Mandarin },
      { testName: "Spanish Lessons", score: progress.Spanish },
      { testName: "French Lessons", score: progress.French },
    ],
  };

  return (
    <Container fluid className="performance-student-container">
      <Row>
        <Col>
          <h1 className="h1-student">
            <u>Student Performance</u>
          </h1>
          <h2 className="h1-student">
            Student's Name: <img src={images.logo} width={"45px"} alt="student-pic" style={{ borderRadius: "50%" }}></img> <b style={{ color: "blue" }}>{studentData.studentName} </b>
          </h2>
        </Col>
      </Row>

      {/* Progress Bars for Lessons */}
      <Row>
        <Col>
          <h3>
            {" "}
            <b>
              <u>LESSONS PROGRESS</u>
            </b>{" "}
          </h3>
          <b>English</b>
          <ProgressBar now={progress.English} label={`${progress.English}%`} variant="success" className="lesson-progress-bar" />

          <b>Mandarin</b>
          <ProgressBar now={progress.Mandarin} label={`${progress.Mandarin}%`} variant="danger" className="lesson-progress-bar" />

          <b>Spanish</b>
          <ProgressBar now={progress.Spanish} label={`${progress.Spanish}%`} variant="success" className="lesson-progress-bar" />

          <b>French</b>
          <ProgressBar now={progress.French} label={`${progress.French}%`} variant="info" className="lesson-progress-bar" />
        </Col>
      </Row>

      <br />
      <br />
      <br />

      {/* Lessons Report */}
      <Row>
        <Col>
          <div className="test-report-div">
            <h3>
              {" "}
              <b>
                <u>LESSONS REPORT</u>
              </b>{" "}
            </h3>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={studentData.testReports}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="testName" />
                <YAxis tickFormatter={(value) => `${value}%`} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="score" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Col>
      </Row>

      <br />
      <br />
      {/* Lesson Completion Progress */}
      <Row>
        <Col>
          <div className="course-completion-div">
            <h3>
              {" "}
              <b>
                <u>LESSONS COMPLETION</u>
              </b>{" "}
            </h3>
            <p>
              Lessons Completed:
              <b> {`${((progressPercentage * 16) / 100).toFixed(0)}`} Level Completed</b>
            </p>
            <ProgressBar now={progressPercentage} label={`${progressPercentage.toFixed(0)}%`} />
            <div className="progress-bar-container">
              <div className="progress-bar-inner" style={{ width: `${progressPercentage.toFixed(0)}%` }}></div>
              <p>{progressPercentage.toFixed(0)}% Completed</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PerformanceStudent;
