import React from "react";
import "./App.css";
import Routing from "./components/Routing";
import { ProgressProvider } from "./contexts/ProgressContext";
import { Analytics } from "@vercel/analytics/react";

function App() {
  return (
    <>
      <ProgressProvider>
        <Routing />
      </ProgressProvider>
      <Analytics />
    </>
  );
}

export default App;
