import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import images from "../../../../images";

const flashcards = [
  {
    word: "TRANPORTATION Drag and Drop Game",
    path: "/dashboard/chinese_lessons/level1_1/transportation/drag-n-drop",
    color: "black",
    image: images.transportations,
  },
  { word: "Car", translation: "汽车", color: "red", image: images.car },
  { word: "Bus", translation: "公交车", color: "blue", image: images.bus },
  { word: "Bicycle", translation: "自行车", color: "green", image: images.bicycle },
  { word: "Train", translation: "火车", color: "violet", image: images.train },
  { word: "Airplane", translation: "飞机", color: "yellow", image: images.airplane },
  { word: "Ship", translation: "船", color: "pink", image: images.ship },
  { word: "Subway", translation: "地铁", color: "orange", image: images.subway },
];

const Vocabulary = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const reciteText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "zh-CN";
    window.speechSynthesis.speak(utterance);
  };

  const stopRecitingText = () => {
    window.speechSynthesis.cancel();
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <motion.h2
            className="mb-4"
            style={{ color: "#4A90E2", fontWeight: "bold" }}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.1 }}
          >
            Lesson on Transportation
          </motion.h2>
          {/* <motion.p className="lead" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.2 }} style={{ fontSize: "1.2rem", color: "#555" }}>
            Welcome to your first Mandarin lesson! In this lesson, you'll learn some basic greetings and essential vocabulary.
          </motion.p> */}
        </Col>
      </Row>

      <Row className="mt-5">
        <Col lg={12} className="text-center">
          <motion.h3
            className="mb-4"
            style={{ color: "#FF9800", fontWeight: "bold" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          >
            Transportation :
          </motion.h3>
        </Col>
      </Row>

      <Row className="justify-content-center">
        {flashcards.map((card, index) => (
          <Col lg={3} md={4} sm={6} xs={12} key={index} className="mb-4">
            <motion.div
              whileHover={{ scale: 1.1, rotate: 3 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: /* index * */ 0.2 }}
              onClick={() => navigate(card.path)}
            >
              <Card
                className="h-100"
                style={{
                  backgroundColor: card.color,
                  borderRadius: "15px",
                  transition: "transform s",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  cursor: "pointer",
                  overflow: "hidden",
                }}
                onMouseEnter={() => reciteText(card.translation)}
                onMouseLeave={() => stopRecitingText()}
              >
                <Card.Img
                  variant="top"
                  src={card.image}
                  alt={card.word}
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                  <motion.h4
                    style={{
                      fontSize: "2rem",
                      color: "#fff",
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",
                      transition: "color 0.1s ease-in-out",
                    }}
                  >
                    {card.word}
                  </motion.h4>
                  <motion.p
                    style={{
                      fontSize: "1.2rem",
                      color: "#fff",
                      margin: "10px 0",
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.05 }}
                  >
                    {card.translation}
                  </motion.p>
                  <motion.p
                    style={{ fontSize: "1rem", color: "#fff" }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4 }}
                  >
                    {card.translation}
                  </motion.p>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>

      <Row className="justify-content-end">
        <Col md={4} className="d-flex justify-content-end">
          <Button variant="secondary" className="mb-3 mt-5" onClick={goBack}>
            Go back
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Vocabulary;
