import React from "react";
import { Fade } from "react-awesome-reveal";
import "react-vertical-timeline-component/style.min.css";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "../../css/Program.css";
import ProgramCard from "./ProgramCard.js";
import { programs } from "../../constants/data.js";

const Programs = () => (
  <div style={{ overflow: "hidden" }} id="programs">
    <Fade bottom>
      <h2>
        <b>
          <u>Steps</u>
        </b>
      </h2>
      <br />
    </Fade>
    <VerticalTimeline>
      {programs.map((event) => (
        <ProgramCard key={event.id} event={event} />
      ))}
    </VerticalTimeline>
  </div>
);

export default Programs;
