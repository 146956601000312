import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, Button, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider } from "react-dnd";
import { motion } from "framer-motion";
import Confetti from "react-confetti";
import confetti from "canvas-confetti";
import { useSelector, useDispatch } from "react-redux";
import { updateRequestMethod } from "../../../../../api/api.js";
import { MARK_QUESTION_COMPLETED } from "../../../../../api/server.js";
import { updateStudentDetail } from "../../../../../redux/action.js";
import { ProgressContext } from "../../../../../contexts/ProgressContext.js";

import hpImages from "../../../../../images/index.js";
import audios from "../../../../../audio/index.js";

const ItemTypes = {
  IMAGE: "image",
};

// Function to detect if the device is touch-based
const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};

const ImageCard = ({ image }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.IMAGE,
    item: { image },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;

  return (
    <motion.div
      ref={drag}
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: opacity, opacity: 1 }}
      whileHover={{ scale: 1.1, boxShadow: "0px 4px 15px rgba(0, 123, 255, 0.5)" }}
      transition={{ duration: 0.3 }}
      style={{
        cursor: "move",
        width: "120px",
        margin: "auto",
      }}
      className="mb-3"
    >
      <Card
        style={{
          opacity,
          border: "1px solid #ccc",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s ease",
        }}
      >
        <Card.Img variant="top" src={image.src} style={{ width: "100%" }} />
        <Card.Body style={{ padding: "0.5rem", textAlign: "center" }}>
          <Card.Title>{image.label}</Card.Title>
        </Card.Body>
      </Card>
    </motion.div>
  );
};

const DropZone = ({ onDrop, feedback }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    drop: (item) => onDrop(item.image),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;
  let borderColor = isActive ? "green" : canDrop ? "lightgreen" : "gray";
  const glowColor = feedback === "correct" ? "green" : feedback === "incorrect" ? "red" : "transparent";

  return (
    <motion.div
      ref={drop}
      initial={{ scale: 1 }}
      animate={{ scale: isActive ? 1.1 : 1 }}
      transition={{ duration: 0.3 }}
      style={{
        border: `2px dashed ${borderColor}`,
        padding: "2rem",
        textAlign: "center",
        backgroundColor: isActive ? "#e0ffe0" : "#f8f9fa",
        boxShadow: feedback ? `0 0 15px 5px ${glowColor}` : "none",
        transition: "box-shadow 0.5s ease",
        height: "150px",
      }}
    >
      {isActive ? "Release to drop" : "Drag an image here"}
    </motion.div>
  );
};

const Level1_4 = () => {
  const navigate = useNavigate();
  const { updateProgress } = useContext(ProgressContext);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [currentWord, setCurrentWord] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [completed, setCompleted] = useState(false);
  const [progress, setProgress] = useState([0, 0, 0]);

  const token = useSelector((state) => state.LoginReducer?.token);

  const dispatch = useDispatch();
  const updateDetails = (newDetails) => {
    dispatch(updateStudentDetail(newDetails));
  };

  const handleQuestionComplete = (questionNumber) => {
    const isLast = questionNumber === "question3";
    try {
      updateRequestMethod(MARK_QUESTION_COMPLETED, { lesson: "lesson1_4", question: questionNumber, isLessonCompleted: isLast }, "application/json", token)
        .then((response) => {
          console.log(response);
          updateDetails(response.data.updatedStudentDetail);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("Error in updating question completion status:", error);
    }
  };

  const words = [
    {
      questionNumber: "question1",
      audio: audios.shipFrench,
      correctImage: hpImages.ship,
      label: "Ship",
    },
    {
      questionNumber: "question2",
      audio: audios.carFrench,
      correctImage: hpImages.car,
      label: "Car",
    },
    {
      questionNumber: "question3",
      audio: audios.airplaneFrench,
      correctImage: hpImages.airplane,
      label: "Airplane",
    },
  ];

  const images = [
    { src: hpImages.car, label: "Car" },
    { src: hpImages.train, label: "train" },
    { src: hpImages.airplane, label: "Airplane" },
    { src: hpImages.ship, label: "Ship" },
  ];

  // const progressPercentage = (currentWord / words.length) * 100;

  const triggerCelebration = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  };

  useEffect(() => {
    if (completed) {
      triggerCelebration();
    }
  }, [completed]);

  const playCelebrationSound = () => {
    const clappingAudio = new Audio(audios.clapping);
    clappingAudio.play();

    setTimeout(() => {
      clappingAudio.pause();
    }, 3000);
  };

  const playFailureSound = () => {
    const failureAudio = new Audio(audios.failure);
    failureAudio.play();

    setTimeout(() => {
      failureAudio.pause();
    }, 3000);
  };

  const handleDrop = (image) => {
    if (image.src === words[currentWord].correctImage) {
      setFeedback("correct");
      setFeedbackMessage("Correct! 🎉");
      playCelebrationSound();
      triggerCelebration();

      handleQuestionComplete(words[currentWord].questionNumber);

      // Update the progress for the current question
      setProgress((prevProgress) => {
        const newProgress = [...prevProgress];
        newProgress[currentWord] = 100; // Mark as complete
        return newProgress;
      });

      setTimeout(() => {
        if (currentWord < words.length - 1) {
          setCurrentWord(currentWord + 1);
        } else {
          setCompleted(true);
          updateProgress("Mandarin", "MandarinL4", 0);
        }
        setFeedbackMessage("");
        setFeedback("");
      }, 1000);
    } else {
      setFeedback("incorrect");
      playFailureSound();
      setFeedbackMessage("Try again! ❌");
    }
  };

  const restartGame = () => {
    setCurrentWord(0);
    setCompleted(false);
    setFeedbackMessage("");
    setFeedback("");
    setProgress([0, 0, 0]);
  };

  return (
    <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
      <Container
        className="mt-4"
        style={{
          transition: "background-color 0.5s ease",
          backgroundColor: feedback === "correct" ? "#e0ffe0" : feedback === "incorrect" ? "#ffe0e0" : "white",
          padding: "2rem",
          borderRadius: "10px",
        }}
      >
        {!completed ? (
          <>
            <h2 className="text-center" style={{ color: "#007bff", fontWeight: "bold" }}>
              {/* Question {currentWord + 1} of {words.length} */}
              Animal {currentWord + 1} of {words.length}
            </h2>

            {/* Progress Bars for each question */}
            <Row className="mb-3">
              {words.map((word, index) => (
                <Col key={index}>
                  <ProgressBar
                    now={progress[index]}
                    // label={`${progress[index]}%`} // Display the progress percentage
                    style={{ height: "15px" }}
                    variant={progress[index] === 100 ? "success" : "info"} // Green for completed
                  />
                  <p className="text-center mt-1">Q{index + 1}</p>
                </Col>
              ))}
            </Row>

            {/* Add Progress Bar */}
            {/* <ProgressBar
              animated
              now={progressPercentage} // Set progress value
              label={`${progressPercentage.toFixed(1)}%`} // Display progress percentage
              style={{ height: "20px", marginBottom: "20px" }} // Styling for the progress bar
            /> */}

            <motion.audio key={currentWord} controls initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
              <source src={words[currentWord].audio} type="audio/mp3" />
              Your browser does not support the audio element.
            </motion.audio>

            <Row className="justify-content-center mb-4">
              <Col xs={12} sm={6} md={4} lg={3}>
                <DropZone feedback={feedback} onDrop={handleDrop} />
              </Col>
            </Row>

            <Row className="justify-content-center">
              {images.map((image) => (
                <Col xs={6} sm={4} md={3} lg={2} key={image.src}>
                  <ImageCard image={image} />
                </Col>
              ))}
            </Row>

            {feedbackMessage && (
              <div className="text-center mt-4">
                <h4>{feedbackMessage}</h4>
              </div>
            )}

            {/* <Row className="mt-3">
              <Col md={8}>
                <h5>Select the correct image based on the audio prompt:</h5>
                <Row>
                  {images.slice(0, 2).map((image) => (
                    <Col key={image.src} md={6} className="d-flex justify-content-center">
                      <ImageCard image={image} />
                    </Col>
                  ))}
                </Row>
                <Row className="mt-2">
                  {images.slice(2).map((image) => (
                    <Col key={image.src} md={6} className="d-flex justify-content-center">
                      <ImageCard image={image} />
                    </Col>
                  ))}
                </Row>
              </Col>

              <Col md={4}>
                <h5>Drag here:</h5>
                <DropZone onDrop={handleDrop} feedback={feedback} />
                {feedbackMessage && (
                  <motion.p className="mt-3 feedback" initial={{ opacity: 0, scale: 0.9 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5 }} style={{ color: feedback === "correct" ? "green" : "red", fontWeight: "bold" }}>
                    {feedbackMessage}
                  </motion.p>
                )}
              </Col>
            </Row> */}
          </>
        ) : (
          <>
            <Container className="p-4 mt-4 text-center" style={styles.container}>
              <h2 className="text-center" style={{ color: "green", fontWeight: "bold" }}>
                🥳 Congratulations! 🎉 🏆
              </h2>
              <Button className="btn btn-primary" onClick={restartGame}>
                Restart Game
              </Button>
              <br />
              <br />
              <Button variant="primary" onClick={() => navigate("/dashboard/french_lessons/level1_1")}>
                Back to Lessons
              </Button>
              <Confetti />
            </Container>
          </>
        )}
      </Container>
    </DndProvider>
  );
};

const styles = {
  container: {
    backgroundColor: "#f9f9f9",
    borderRadius: "12px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  header: {
    color: "#4A90E2",
    marginBottom: "1.5rem",
  },
};

export default Level1_4;
