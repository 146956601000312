import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import images from "../../../images";

const Level1_2 = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  // const videoRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(true);
  // const [isFullScreen, setIsFullScreen] = useState(false);

  // const handlePlayPause = () => {
  //   if (isPlaying) {
  //     videoRef.current.pause();
  //   } else {
  //     videoRef.current.play();
  //   }
  //   setIsPlaying(!isPlaying);
  // };

  // const handleFullScreenToggle = () => {
  //   if (!isFullScreen) {
  //       videoRef.current.requestFullscreen();
  //   } else {
  //       document.exitFullscreen();
  //   }
  //   setIsFullScreen(!isFullScreen);
  // };

  return (
    <Container>
      <div className="flex flex-col items-center justify-center w-screen h-screen">
        <video
          // ref={videoRef}
          className="w-3/4 h-3/4 object-cover"
          src={images.spanishAnimatedVdo}
          autoPlay
          loop
          controls
          style={{
            width: "100%",
            maxHeight: "80vh",
            borderRadius: "20px",
            border: "2px solid",
          }}
        />

        {/* Play/Pause button */}
        {/* <button className="mt-4 bg-gray-800 text-black px-6 py-2 rounded-full" onClick={handlePlayPause}>
        {isPlaying ? "Pause" : "Play"}
      </button> */}

        {/* Full-Screen/Small-Screen button */}
        {/* <button className="mt-2 bg-blue-600 text-black px-6 py-2 rounded-full" onClick={handleFullScreenToggle}>
        {isFullScreen ? "Exit Full Screen" : "Full Screen"}
      </button> */}
      </div>

      <Row className="justify-content-end">
        <Col md={4} className="d-flex justify-content-end">
          <Button variant="secondary" className="mb-3 mt-5" onClick={goBack}>
            Go back to Lesson
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Level1_2;
