import { useRef } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Fade } from "react-awesome-reveal";
import "../../css/DemoVdos.css";
import { storiesVdos } from "../../constants/data.js";

const AnimatedVdos = () => {
  const scrollRef = useRef(null);

  const scrollLeftDir = () => smoothScroll("left");
  const scrollRightDir = () => smoothScroll("right");

  // const scroll = (direction) => {
  //   const { current } = scrollRef;
  //   if (current && current.scrollLeft != null) {
  //     if (direction === "left") {
  //       current.scrollLeft -= 1250;
  //     } else if (direction === "right") {
  //       current.scrollLeft += 1250;
  //     }
  //   }
  // };

  const smoothScroll = (direction) => {
    const { current } = scrollRef;
    if (current && current.scrollLeft != null) {
      let scrollDistance = 1300;
      if (window.innerWidth < 400) {
        scrollDistance = 365;
      }

      let step = 20;
      let interval = 10;
      let scrolled = 0;

      const scrollStep = setInterval(() => {
        if (direction === "left") {
          current.scrollLeft -= step;
        } else if (direction === "right") {
          current.scrollLeft += step;
        }
        scrolled += step;

        if (scrolled >= scrollDistance) {
          clearInterval(scrollStep);
        }
      }, interval);
    }
  };

  return (
    <div className="demovdos-stories">
      <div className="demovdos-content">
        <Fade bottom>
          <h1 className="title fs-2 text-decoration m-0">
            STORIES <span style={{ color: "#00E5EA" }}>1-5</span> <hr />
          </h1>
        </Fade>
      </div>
      <div className="demovdos-images-stories">
        <div className="demovdos-images_container" ref={scrollRef}>
          {storiesVdos.map((video) => (
            <div key={video.id} className="demovdos-images_card">
              <h1 className="title">{video.name}</h1>
              <br />
              <video
                className="demovdos-image"
                src={video.vdo}
                alt={`img-${video.id}`}
                controls
                style={{
                  width: "100%",
                  height: "100vh",
                  borderRadius: "20px",
                  border: "2px solid",
                }}
              />
            </div>
          ))}
        </div>
        <div className="demovdos-images_arrows-story">
          <div className="demovdos-arrows-div-story">
            <BsArrowLeftShort className="demovdos-arrow-icon-story" onClick={scrollLeftDir} />
          </div>
          <div className="demovdos-arrows-div">
            <BsArrowRightShort className="demovdos-arrow-icon-story" onClick={scrollRightDir} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedVdos;
