import React from 'react';
import { Container, Row, Col, Card, Button, ProgressBar, ListGroup, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ParentHome = () => {
    return (
        <Container style={{ marginTop: '20px' }}>
            <h2 style={{ color: '#7335b7', marginBottom: '20px' }}>Parent Dashboard</h2>
            <Row>
                {/* Summary of Progress */}
                <Col md={6}>
                    <Card style={{ marginBottom: '20px' }}>
                        <Card.Body>
                            <Card.Title style={{ color: '#7335b7' }}>Overall Progress</Card.Title>
                            <ProgressBar now={75} label={`75%`} variant="info" style={{ height: '20px', fontSize: '14px' }} />
                            <p style={{ marginTop: '10px' }}>
                                Your child has completed <strong>75%</strong> of the curriculum.
                            </p>
                            <Link to="/dashboard/progress-towards-goals">
                                <Button variant="primary" style={{ backgroundColor: '#7335b7', borderColor: '#7335b7' }}>View Detailed Progress</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Learning Goals Summary */}
                <Col md={6}>
                    <Card style={{ marginBottom: '20px' }}>
                        <Card.Body>
                            <Card.Title style={{ color: '#7335b7' }}>Learning Goals</Card.Title>
                            <ListGroup>
                                <ListGroup.Item>
                                    <h6>Complete Lesson 5</h6>
                                    <p>Time Goal: 2 hours, Score Goal: 85%</p>
                                    <ProgressBar now={50} label={`50%`} variant="info" style={{ height: '20px', fontSize: '14px' }} />
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <h6>Mandarin Speaking Practice</h6>
                                    <p>Time Goal: 30 mins, Score Goal: 90%</p>
                                    <ProgressBar now={100} label={`100%`} variant="success" style={{ height: '20px', fontSize: '14px' }} />
                                </ListGroup.Item>
                            </ListGroup>
                            <Link to="/dashboard/learning-goals">
                                <Button variant="primary" style={{ backgroundColor: '#7335b7', borderColor: '#7335b7', marginTop: '10px' }}>Manage Learning Goals</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                {/* Child's Strengths and Weaknesses */}
                <Col md={6}>
                    <Card style={{ marginBottom: '20px' }}>
                        <Card.Body>
                            <Card.Title style={{ color: '#7335b7' }}>Strengths & Weaknesses</Card.Title>
                            <ListGroup>
                                <ListGroup.Item>
                                    <Badge bg="success" style={{ marginRight: '10px' }}>Strength</Badge>
                                    Reading Comprehension
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Badge bg="danger" style={{ marginRight: '10px' }}>Weakness</Badge>
                                    Mandarin Pronunciation
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Recent Activity */}
                <Col md={6}>
                    <Card style={{ marginBottom: '20px' }}>
                        <Card.Body>
                            <Card.Title style={{ color: '#7335b7' }}>Recent Activity</Card.Title>
                            <ListGroup>
                                <ListGroup.Item>
                                    <p>Completed Lesson 5 - Scored 80%</p>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <p>Spent 1 hour on Mandarin Writing Practice</p>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <p>Reviewed Lesson 3 Vocabulary</p>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                {/* Teacher Feedback */}
                <Col md={12}>
                    <Card style={{ marginBottom: '20px' }}>
                        <Card.Body>
                            <Card.Title style={{ color: '#7335b7' }}>Teacher Feedback</Card.Title>
                            <p>Your child's progress has been consistent. Keep encouraging them to practice speaking skills daily. Consider scheduling a one-on-one session for additional pronunciation practice.</p>
                            <Link to="/dashboard/teacher-feedback">
                                <Button variant="primary" style={{ backgroundColor: '#7335b7', borderColor: '#7335b7' }}>View Full Feedback</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ParentHome;
