import React from 'react';
import { Container, Row, Col, Card, Button, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const AccountManagement = () => {

    const navigate = useNavigate();

    const accountDetails = {
        name: 'John Doe',
        email: 'john.doe@example.com',
        memberSince: 'January 2022',
    };

    const kidsData = [
        { name: 'Jane Doe', age: 7, level: 'Intermediate' },
        { name: 'Jim Doe', age: 9, level: 'Beginner' },
    ];

    const subscription = {
        type: 'Premium',
        expiryDate: 'December 2024',
    };

    return (
        <Container style={{ padding: '20px' }}>
            <h2 style={{ color: '#7335b7', textAlign: 'center', marginBottom: '20px' }}>Account Management</h2>
            <Row>
                <Col md={6}>
                    <Card style={{ borderColor: '#7335b7' }}>
                        <Card.Header style={{ backgroundColor: '#7335b7', color: 'white' }}>Account Details</Card.Header>
                        <Card.Body>
                            <Card.Title>Name: {accountDetails.name}</Card.Title>
                            <Card.Text>Email: {accountDetails.email}</Card.Text>
                            <Card.Text>Member Since: {accountDetails.memberSince}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card style={{ borderColor: '#7335b7' }}>
                        <Card.Header style={{ backgroundColor: '#7335b7', color: 'white' }}>Subscription Details</Card.Header>
                        <Card.Body>
                            <Card.Title>Subscription Type: {subscription.type}</Card.Title>
                            <Card.Text>Expiry Date: {subscription.expiryDate}</Card.Text>
                            <Button onClick={() => navigate("/dashboard/subscription-payments")} style={{ backgroundColor: '#7335b7', borderColor: '#7335b7', marginTop: '10px' }}>Upgrade</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Card style={{ borderColor: '#7335b7' }}>
                        <Card.Header style={{ backgroundColor: '#7335b7', color: 'white' }}>Kids Information</Card.Header>
                        <ListGroup variant="flush">
                            {kidsData.map((kid, index) => (
                                <ListGroup.Item key={index}>
                                    <strong>Name:</strong> {kid.name} <br />
                                    <strong>Age:</strong> {kid.age} <br />
                                    <strong>Learning Level:</strong> {kid.level}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default AccountManagement;
