import React, { useState, useEffect } from "react";
import { Home, AboutUs, Testimonials, Team, ProductMeasures, ElevateLearning, PilotProgram, ContactUs, WelcomeSection, DashboardDemo, Faq } from "./Landing Page";
import PopupForm from "./PopupForm.js";
import { MANAGE_USER_VALIDATE_COOKIE } from "../api/server.js";
import { postRequestMethod } from "../api/api.js";
import { LOGIN, UPDATE_STUDENT_DETAIL, updateStudentDetail } from "../redux/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const LandingPage = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateDetails = (newDetails) => {
    dispatch(updateStudentDetail(newDetails));
  };

  // const hasAuthToken = () => {
  //   console.log(document.cookie.split(';'));
  //   return document.cookie.split(';').some((cookie) => cookie.trim().startsWith('authToken='));
  // };

  useEffect(() => {
    // Check for authToken cookie first
    // if (!hasAuthToken()) {
    //   setLoading(false);
    //   console.log("No authToken cookie found");
    //   return;
    // }

    postRequestMethod(MANAGE_USER_VALIDATE_COOKIE, "", { dummy: "dummy" })
      .then((res) => {
        const { user, token } = res.data;
        sessionStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("token", JSON.stringify(token));
        sessionStorage.setItem("type", user.type);
        if (user.type === "Student" || user.type === "Parent") {
          sessionStorage.setItem("studentDetail", JSON.stringify(res.data.studentDetail));
          updateDetails(res.data.studentDetail);
        }
        dispatch({ type: LOGIN, isAuthenticated: true, token: token, user: user, role: user.type });
        setLoading(false);
        navigate("/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting Login:", error);
      });
  }, [navigate]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 w-100">
        <Spinner animation="grow" variant="success" />
      </div>
    );
  }

  return (
    <>
      <div id="home">
        <Home />

        <AboutUs />

        <Testimonials />

        <Team />

        <ProductMeasures />

        <ElevateLearning />

        <PilotProgram />

        <ContactUs />

        <WelcomeSection />

        <DashboardDemo />

        <Faq />
      </div>

      <PopupForm />
    </>
  );
};

export default LandingPage;
