import React from "react";
import { Button } from "@chakra-ui/react";
import images from "../../../images";
import { getRequest, postRequestMethod } from "../../../api/api";
import { MANAGE_PAYMENT_CHECKOUT, MANAGE_GET_RAZORPAY_KEY, MANAGE_PAYMENT_VERIFICATION } from "../../../api/server";
// import axios from "axios";

const PaymentButton = ({ onPurchaseClick }) => {
  // console.log(onPurchaseClick);

  const checkoutHandler = async (amount) => {
    // const {
    //   data: { key },
    // } = await axios.get(MANAGE_GET_RAZORPAY_KEY);

    const {
      data: { key },
    } = await getRequest(MANAGE_GET_RAZORPAY_KEY, "");

    // console.log(key);

    // const {
    //   data: { order },
    // } = await axios.post(MANAGE_PAYMENT_CHECKOUT, {
    //   amount,
    // });

    const {
      data: { order },
    } = await postRequestMethod(MANAGE_PAYMENT_CHECKOUT, "application/json", {
      amount,
    });
    // console.log(order);

    const options = {
      key: key,
      amount: order.amount,
      currency: "USD",
      name: "Happy-Plum",
      description: "Happy-Plum Subscription Plans",
      image: images.logo,
      order_id: order.id,
      callback_url: MANAGE_PAYMENT_VERIFICATION,
      prefill: {
        name: "",
        contact: "",
        email: "",
      },
      notes: {
        address: "Boston, MA, USA",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  return (
    <Button
      style={{
        backgroundColor: "#7335b7",
        borderColor: "#7335b7",
        color: "white",
        position: "absolute",
        bottom: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        padding: "5px 8px",
        borderRadius: "7px",
      }}
      onClick={() => checkoutHandler(onPurchaseClick)}
    >
      Purchase
    </Button>
  );
};

export default PaymentButton;
