import React from 'react'
import { Container, Row, Card, Col } from 'react-bootstrap'

function SupportYourChild() {
    return (
        <Container>
            <h2 style={{ color: '#7335b7', marginBottom: '20px', textAlign: "center" }}>Support Your Child</h2>
            <h6>We have used artificial intelligence to analyse your child's performance and listed some suggestions here for you to support your child and help them learn efficiently.</h6>
            <Row style={{ margin: "20px 0 20px 0" }}>
                <Col style={{ borderColor: "#7335b7" }}>
                    <Card style={{ borderColor: "#7335b7" }}>
                        <Card.Header style={{ backgroundColor: "#7335b7" }}>
                            <h4 style={{ color: "white" }}>Suggestion 1</h4>
                        </Card.Header>
                        <Card.Body>
                            <p>Generate suggestion using AI.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row style={{ margin: "20px 0 20px 0" }}>
                <Col style={{ borderColor: "#7335b7" }}>
                    <Card style={{ borderColor: "#7335b7" }}>
                        <Card.Header style={{ backgroundColor: "#7335b7" }}>
                            <h4 style={{ color: "white" }}>Suggestion 2</h4>
                        </Card.Header>
                        <Card.Body>
                            <p>Generate suggestion using AI.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default SupportYourChild
