import React, { useState } from 'react';
import { Container, Row, Col, Form, ListGroup, Button, InputGroup, Dropdown } from 'react-bootstrap';

const NotificationSearchBar = ({ searchQuery, onSearchChange }) => (
    <InputGroup className="mb-3">
        <Form.Control
            type="text"
            placeholder="Search notifications"
            value={searchQuery}
            onChange={(e) => onSearchChange(e.target.value)}
            style={{ borderColor: '#7335b7' }}
        />
        <Button style={{ backgroundColor: '#7335b7', borderColor: '#7335b7' }}>Search</Button>
    </InputGroup>
);

const NotificationList = ({ notifications, onSelectNotification, onDeleteNotification, onToggleRead }) => (
    <ListGroup>
        {notifications.map((notification, index) => (
            <ListGroup.Item
                className='pe-0'
                key={index}
                action
                onClick={() => onSelectNotification(notification)}
                style={{
                    backgroundColor: notification.read ? 'white' : '#7335b7',
                    color: notification.read ? '#7335b7' : 'white',
                    cursor: 'pointer',
                    position: 'relative',
                }}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <strong>{notification.title}</strong>
                        <br />
                        <small>{notification.date} - {notification.time}</small>
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle variant="link" style={{ color: "transparent", }} id="dropdown-basic">
                            <span style={{ color: notification.read ? '#7335b7' : 'white', }}>&#x2022;&#x2022;&#x2022;</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => onDeleteNotification(notification.id)}>Delete</Dropdown.Item>
                            <Dropdown.Item onClick={() => onToggleRead(notification.id)}>
                                {notification.read ? 'Mark Unread' : 'Mark Read'}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </ListGroup.Item>
        ))}
    </ListGroup>
);

const NotificationDetail = ({ notification }) => (
    <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '10px', border: '1px solid #7335b7' }}>
        <h5 style={{ color: '#7335b7' }}>{notification.title}</h5>
        <p>{notification.content}</p>
        <small>
            {notification.date} - {notification.time}
        </small>
    </div>
);

const Notifications = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [notifications, setNotifications] = useState([
        { id: 1, title: 'New Lesson Available', content: 'A new lesson is available for your child.', date: '2024-08-20', time: '10:00 AM', read: false },
        { id: 2, title: 'Homework Reminder', content: 'Don\'t forget to submit your homework by Friday.', date: '2024-08-19', time: '2:00 PM', read: false },
        { id: 3, title: 'Parent-Teacher Meeting', content: 'Your parent-teacher meeting is scheduled for next week.', date: '2024-08-18', time: '11:00 AM', read: false },
        // Add more notifications as needed
    ]);

    const filteredNotifications = notifications.filter(notification =>
        notification.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchChange = (query) => setSearchQuery(query);

    const handleSelectNotification = (notification) => {
        if (!notification.read) handleToggleRead(notification.id);
        setSelectedNotification(notification)
    }

    const handleDeleteNotification = (id) => {
        setNotifications(notifications.filter(notification => notification.id !== id));
    };

    const handleToggleRead = (id) => {
        setNotifications(notifications.map(notification =>
            notification.id === id ? { ...notification, read: !notification.read } : notification
        ));
    };

    return (
        <Container>
            <Row>
                <Col md={4}>
                    <NotificationSearchBar searchQuery={searchQuery} onSearchChange={handleSearchChange} />
                    <NotificationList
                        notifications={filteredNotifications}
                        onSelectNotification={handleSelectNotification}
                        onDeleteNotification={handleDeleteNotification}
                        onToggleRead={handleToggleRead}
                    />
                </Col>
                <Col md={8}>
                    {selectedNotification && <NotificationDetail notification={selectedNotification} />}
                </Col>
            </Row>
        </Container>
    );
};

export default Notifications;
