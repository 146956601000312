import React, { useState, useEffect } from "react";
import { FaMicrophone, FaP, FaPlay } from "react-icons/fa6";
import { HiSpeakerWave } from "react-icons/hi2";
import speakingIcon from "../../../images/speaking-icon.png";
import earIconActive from "../../../images/ear-active-icon.png";
import earIconNonActive from "../../../images/ear-nonactive-icon.png";
import { levels } from "./conversationData";
import {
    Button,
    Container,
    Row,
    Col,
    Card,
    Alert,
    Spinner,
    Dropdown,
    DropdownButton,
    Modal
} from "react-bootstrap";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { OpenAI } from "openai";

import { MARK_SUBLEVEL_COMPLETED } from "../../../api/server";
import { updateRequestMethod } from "../../../api/api";
import { updateStudentDetail } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
    dangerouslyAllowBrowser: true,
});

function RealLifeScenario() {
    const [selectedLevel, setSelectedLevel] = useState("1");
    const [selectedSublevel, setSelectedSublevel] = useState("1.1");
    const [currentConversation, setCurrentConversation] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

    useEffect(() => {
        if (transcript && !listening) {
            verifySpeech().then(({ isSimilar, score }) => {
                if (isSimilar) {
                    speakWellDone(score);
                } else {
                    speakTryAgain();
                }
            });
        }
    }, [listening, transcript]);

    const dispatch = useDispatch();
    const token = useSelector((state) => state.LoginReducer?.token);
    const studentDetail = useSelector((state) => state.StudentDetailReducer?.studentDetail);

    if (studentDetail) console.log(studentDetail);
    if (token) console.log(token);

    const updateDetails = (newDetails) => {
        dispatch(updateStudentDetail(newDetails));
    };

    // Helper function to determine if a level is unlocked and completed
    const isLevelUnlocked = (level) => {
        return (studentDetail.studentReport.levelScores[`level${level}`]?.unlocked ?? false);
    };

    // Helper function to determine if a sublevel is unlocked and completed
    const isSublevelUnlocked = (sublevel) => {
        return (studentDetail.studentReport.levelScores[`level${selectedLevel}`][`level${sublevel.replace('.', '_')}`]?.unlocked ?? false);
    };

    // console.log(isLevelActive(selectedLevel), isSublevelActive(selectedLevel, selectedSublevel));

    const [subLevelScore, setSubLevelScore] = useState(0);
    const [botConversation, setBotConversation] = useState([
        {
            role: "system",
            content: `You are a Mandarin language learning assistant for kids. You need to check if the student is learning correctly or not. 
            You will be given a Text and Response, and you need to check if the response is somewhat similar to the text or not. 
            Be lenient while checking. Respond with Yes or No for easy integration.`,
        },
    ]);

    const verifySpeech = async () => {
        if (transcript) {
            setLoading(true);
            setBotConversation((prev) => [
                ...prev,
                { role: "user", content: `Text: ${currentConversation[currentIndex]?.mandarin?.characters}, Response: ${transcript}` }
            ]);

            try {
                // Make API call to get similarity response
                const response = await openai.chat.completions.create({
                    model: "gpt-3.5-turbo",
                    messages: botConversation,
                });
                const assistantMessage = response.choices[0].message.content;

                setBotConversation((prev) => [
                    ...prev,
                    { role: "assistant", content: assistantMessage }
                ]);

                let score;
                const isSimilar = assistantMessage.includes("Yes");
                console.log(currentConversation[currentIndex]?.mandarin?.characters, transcript);
                console.log(assistantMessage, isSimilar);

                if (isSimilar) {
                    if (currentIndex === currentConversation.length - 1) {
                        // Make API call to get the score
                        const scoreResponse = await openai.chat.completions.create({
                            model: "gpt-3.5-turbo",
                            messages: [...botConversation, { role: "system", content: `The level has ended. provide a score out of 10 based on the student's performance in the format: score: <score>. This will be stored in the database.` }],
                        });
                        const scoreMatch = scoreResponse.choices[0].message.content.match(/score:\s*(\d+)/i);
                        score = scoreMatch ? parseInt(scoreMatch[1], 10) : null;
                        // setSubLevelScore(score);
                        console.log(score);
                    }
                }

                return { isSimilar, score };
            } catch (error) {
                console.error("Error with OpenAI:", error);
                return false;
            } finally {
                setLoading(false);
            }
        }
    };

    if (!browserSupportsSpeechRecognition) {
        return <Alert variant="danger">Browser doesn't support speech recognition.</Alert>;
    }

    const speakTryAgain = () => {
        if ('speechSynthesis' in window) {
            window.speechSynthesis.cancel(); // Clear any queued speech tasks
            const utterance = new SpeechSynthesisUtterance("Let's try again! repeat after me.");
            utterance.lang = "en-US";
            // utterance.rate = 0.7;
            window.speechSynthesis.speak(utterance);
            utterance.onend = () => {
                window.speechSynthesis.cancel();
                const mandarinText = currentConversation[currentIndex]?.mandarin?.characters || "Text missing";
                const utteranceMandarin = new SpeechSynthesisUtterance(mandarinText);
                utteranceMandarin.lang = "zh-CN";
                utteranceMandarin.rate = 0.7;
                window.speechSynthesis.speak(utteranceMandarin);
                utteranceMandarin.onend = () => {
                    resetTranscript();
                    SpeechRecognition.startListening({
                        language: "zh-CN",
                        continuous: false,
                    });
                }
            };
        } else {
            alert('Text-to-speech is not supported in this browser.');
        }
    };

    const speakWellDone = (score) => {
        if ('speechSynthesis' in window) {
            window.speechSynthesis.cancel(); // Clear any queued speech tasks
            const utterance = new SpeechSynthesisUtterance("Well done! Let's move ahead!");
            utterance.lang = "en-US";
            // utterance.rate = 0.7;
            window.speechSynthesis.speak(utterance);
            utterance.onend = () => {
                setCurrentIndex((prevIndex) => prevIndex + 1);
            };
            if (currentIndex === currentConversation.length - 1) {
                try {
                    updateRequestMethod(`${MARK_SUBLEVEL_COMPLETED}?subLevel=${selectedSublevel}&score=${score}`, {}, "application/json", token)
                        .then(response => {
                            console.log(response);
                            updateDetails(response.data.updatedStudentDetail);
                            handleLevelComplete();
                        }).catch(error => {
                            console.log(error);
                        });
                } catch (error) {
                    console.log("Error in updating sublevel completion status:", error);
                }
            }
        } else {
            alert('Text-to-speech is not supported in this browser.');
        }
    };

    const startConversation = () => {
        setCurrentConversation([]); // Reset conversation when level changes
        const levelData = levels.find((level) => level.level === selectedLevel);
        if (levelData) {
            const sublevelData = levelData.sublevels.find((sub) => sub.sublevel === selectedSublevel);
            if (sublevelData) {
                setCurrentConversation(sublevelData.conversations);
                setCurrentIndex(0);
            }
        }
    };

    const handleLevelChange = (level) => {
        SpeechRecognition.stopListening();
        setCurrentConversation([]); // Reset conversation when level changes
        setSelectedLevel(level);
        setSelectedSublevel(`${level}.1`); // Reset sublevel to the first sublevel of the selected level
    };

    const handleSublevelChange = (sublevel) => {
        SpeechRecognition.stopListening();
        setCurrentConversation([]); // Reset conversation when sublevel changes
        setSelectedSublevel(sublevel);
    };

    const handleNextLevel = () => {
        if (selectedSublevel.endsWith("5")) {
            SpeechRecognition.stopListening();
            setCurrentConversation([]); // Reset conversation when level changes
            setSelectedLevel(selectedLevel + 1);
            setSelectedSublevel(`${selectedLevel + 1}.1`);
        } else {
            SpeechRecognition.stopListening();
            const nextSublevel = parseFloat(selectedSublevel) + 0.1;
            setCurrentConversation([]); // Reset conversation when sublevel changes
            setSelectedSublevel(nextSublevel.toFixed(1));
        }
        setShowModal(false);
    };

    const handleLevelComplete = () => {
        setShowModal(true);
    };

    if (!studentDetail || !studentDetail.studentReport) {
        return <div>Loading...</div>; // Display a loading state while the data is being fetched or rehydrated
    }

    if (!token) {
        return <div>Loading...</div>; // Display a loading state while the data is being fetched or rehydrated
    }

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-5" style={{ color: "#7335b7" }}>
                Language Learning Assistant
            </h1>

            {/* <button onClick={() => {
                try {
                    updateRequestMethod(`${MARK_SUBLEVEL_COMPLETED}?subLevel=${1.5}&score=${subLevelScore}`, {}, "application/json", token)
                        .then(response => {
                            console.log(response);
                            updateDetails(response.data.updatedStudentDetail);
                        }).catch(error => {
                            console.log(error);
                        });
                } catch (error) {
                    console.log("Error in updating sublevel completion status:", error);
                }
            }}>click</button> */}

            <div className="d-flex justify-content-center mb-4">
                <DropdownButton
                    id="dropdown-level"
                    title={`Level: ${selectedLevel}`}
                    onSelect={handleLevelChange}
                    className="me-2"
                    variant="outline-primary"
                    style={{ borderColor: "#7335b7", color: "#7335b7" }}
                >
                    {levels.map((level) => (
                        <Dropdown.Item
                            key={level.level}
                            eventKey={level.level}
                            active={selectedLevel === level.level}
                            disabled={!isLevelUnlocked(level.level)}
                        >
                            Level {level.level}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>

                <DropdownButton
                    id="dropdown-sublevel"
                    title={`Sublevel: ${selectedSublevel}`}
                    onSelect={handleSublevelChange}
                    className="ms-2"
                    variant="outline-primary"
                    style={{ borderColor: "#7335b7", color: "#7335b7" }}
                >
                    {levels
                        .find((level) => level.level === selectedLevel)
                        ?.sublevels.map((sub) => (
                            <Dropdown.Item
                                key={sub.sublevel}
                                eventKey={sub.sublevel}
                                active={selectedSublevel === sub.sublevel}
                                disabled={!isSublevelUnlocked(sub.sublevel)}
                            >
                                Sublevel {sub.sublevel}
                            </Dropdown.Item>
                        ))}
                </DropdownButton>
            </div>

            <div className="text-center mb-4">
                <Button
                    onClick={startConversation}
                    style={{ backgroundColor: "#7335b7", color: "white", border: "none" }}
                >
                    <FaPlay size={20} />
                </Button>
            </div>

            {currentConversation.length > 0 &&
                currentConversation.map((item, index) =>
                    index <= currentIndex ? (
                        <Card key={index} className="my-3 shadow-sm">
                            <Card.Body>
                                <Card.Text>{item.english}</Card.Text>
                                <Card.Text style={{ color: "#7335b7", fontWeight: "bold" }}>
                                    {item.mandarin.pinyin}
                                </Card.Text>
                                <Card.Text style={{ color: "#7335b7", fontWeight: "bold" }}>
                                    {item.mandarin.characters}
                                </Card.Text>
                                {index % 2 === 0 ? (
                                    <TextToSpeech
                                        text={item.mandarin.characters}
                                        setCurrentIndex={setCurrentIndex}
                                        isAssistant={currentIndex % 2 === 0}
                                    />
                                ) : (
                                    <div className="text-center">
                                        <TextToSpeech
                                            text={item.mandarin.characters}
                                            setCurrentIndex={setCurrentIndex}
                                            isAssistant={currentIndex % 2 === 0}
                                        />
                                        <div
                                            className="mb-3"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (listening) {
                                                    SpeechRecognition.stopListening();
                                                } else {
                                                    SpeechRecognition.startListening({
                                                        language: "zh-CN",
                                                        continuous: false,
                                                    });
                                                }
                                            }}
                                        >
                                            {listening ? (
                                                // <FaMicrophone color="green" size={35} />
                                                <img src={earIconActive} alt="ear-icon" style={{ width: "60px", height: "60px" }} />
                                            ) : (
                                                // <FaMicrophone color="#7335b7" size={35} />
                                                <img src={earIconNonActive} alt="ear-icon" style={{ width: "60px", height: "60px" }} />
                                            )}
                                        </div>

                                        <Button
                                            onClick={resetTranscript}
                                            disabled={!transcript}
                                            variant="outline-primary"
                                            style={{ borderColor: "#7335b7", color: "#7335b7" }}
                                        >
                                            Reset
                                        </Button>
                                        <p>
                                            <strong>Student:</strong> {transcript || "...waiting for input"}
                                        </p>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    ) : null
                )}

            {/* Modal for Next Level/ Sublevel */}
            <Modal show={showModal} onHide={() => setShowModal(false)} className="d-flex justify-content-center align-items-center">
                <Modal.Header style={{ backgroundColor: "#7335b7", color: "white", position: "relative" }}>
                    <Modal.Title>Congratulations! You have cleared this level!</Modal.Title>
                    <button
                        onClick={() => setShowModal(false)}
                        style={{ position: "absolute", top: "10px", right: "10px", backgroundColor: "#7335b7", color: "white", border: "none" }}
                    ><strong>X</strong>
                    </button>
                </Modal.Header>


                <Modal.Body style={{ textAlign: "center", color: "#7335b7" }}><strong>Let's go to the next level!</strong></Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="primary" style={{ backgroundColor: "#7335b7", borderColor: "#7335b7" }} onClick={handleNextLevel}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default RealLifeScenario;

const TextToSpeech = React.memo(({ text, setCurrentIndex, isAssistant }) => {
    const speakText = () => {
        if ('speechSynthesis' in window) {
            window.speechSynthesis.cancel(); // Clear any queued speech tasks
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.lang = "zh-CN";
            utterance.rate = 0.7;
            utterance.onend = () => {
                if (isAssistant) {
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                    SpeechRecognition.startListening({
                        language: "zh-CN",
                        continuous: false,
                    });
                };
            }
            window.speechSynthesis.speak(utterance);
        } else {
            alert('Text-to-speech is not supported in this browser.');
        }
    };

    useEffect(() => {
        // Automatically speak the text when the component is first rendered
        if (isAssistant) {
            try {
                speakText();
            } catch (error) {
                console.error('Error with speech synthesis:', error);
            }
        }
    }, [text]);

    const handleSpeakAgain = () => {
        try {
            SpeechRecognition.stopListening();
            speakText();
        } catch (error) {
            console.error('Error with speech synthesis:', error);
            alert('An error occurred while trying to speak the text.');
        }
    };



    return (
        <div>
            <Button
                onClick={handleSpeakAgain}
                style={{ backgroundColor: '#7335b7', borderColor: '#7335b7', color: 'white', marginBottom: '10px' }}
            >
                {/* <HiSpeakerWave size={20} /> */}
                <img src={speakingIcon} alt="speaking-icon" style={{ width: "30px", height: "30px" }} />
            </Button>
            {!('speechSynthesis' in window) && (
                <Alert variant="danger" className="mt-3">
                    Text-to-speech is not supported in this browser.
                </Alert>
            )}
        </div>
    );
})
