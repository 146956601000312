import React, { useState } from 'react';
import { Container, Row, Col, Card, ProgressBar, ListGroup } from 'react-bootstrap';

const ProgressTowardsGoals = () => {
    const [goalsProgress, setGoalsProgress] = useState([
        {
            id: 1,
            description: 'Complete Lesson 5 in Mandarin Writing',
            timeGoal: 2,
            timeSpent: 1.5,
            scoreGoal: 85,
            scoreAchieved: 80,
            status: 'In Progress',
        },
        {
            id: 2,
            description: 'Practice Mandarin Speaking for 30 minutes daily',
            timeGoal: 0.5,
            timeSpent: 0.5,
            scoreGoal: 90,
            scoreAchieved: 92,
            status: 'Completed',
        },
    ]);

    return (
        <Container style={{ marginTop: '20px' }}>
            <h2 style={{ color: '#7335b7', marginBottom: '20px' }}>Progress Towards Goals</h2>

            <Card>
                <Card.Body>
                    <Card.Title style={{ color: '#7335b7' }}>Goals Progress</Card.Title>
                    <ListGroup>
                        {goalsProgress.map((goal) => (
                            <ListGroup.Item key={goal.id}>
                                <Row>
                                    <Col md={8}>
                                        <h5>{goal.description}</h5>
                                        <p>Status: <strong>{goal.status}</strong></p>
                                        <p>Time Goal: {goal.timeGoal} hour(s), Time Spent: {goal.timeSpent} hour(s)</p>
                                        <p>Score Goal: {goal.scoreGoal}%, Score Achieved: {goal.scoreAchieved}%</p>
                                    </Col>
                                    <Col md={4}>
                                        <h6>Time Progress</h6>
                                        <ProgressBar
                                            now={(goal.timeSpent / goal.timeGoal) * 100}
                                            label={`${((goal.timeSpent / goal.timeGoal) * 100).toFixed(2)}%`}
                                            variant="info"
                                            style={{ height: '20px', fontSize: '14px', backgroundColor: '#d9c5f0' }}
                                        />
                                        <h6 className="mt-3">Score Progress</h6>
                                        <ProgressBar
                                            now={goal.scoreAchieved}
                                            label={`${goal.scoreAchieved}%`}
                                            variant={goal.scoreAchieved >= goal.scoreGoal ? "success" : "danger"}
                                            style={{ height: '20px', fontSize: '14px', backgroundColor: '#d9c5f0' }}
                                        />
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default ProgressTowardsGoals;
