import { useState, useRef } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Fade } from "react-awesome-reveal";
import "../../css/DemoVdos.css";
import { demoVdos } from "../../constants/data.js";

const DemoVdos = () => {
  const scrollRef = useRef(null);

  const scrollLeftDir = () => smoothScroll("left");
  const scrollRightDir = () => smoothScroll("right");

  // const scroll = (direction) => {
  //   const { current } = scrollRef;
  //   if (current && current.scrollLeft != null) {
  //     if (direction === "left") {
  //       current.scrollLeft -= 1250;
  //     } else if (direction === "right") {
  //       current.scrollLeft += 1250;
  //     }
  //   }
  // };

  // const scroll = (direction) => {
  //   const { current } = scrollRef;
  //   if (current && current.scrollLeft != null) {
  //     let scrollDistance = 1250; // Default scroll distance
  //     if (window.innerWidth < 400) {
  //       scrollDistance = 365; // Reduce scroll distance for small screens
  //     }

  //     if (direction === "left") {
  //       current.scrollLeft -= scrollDistance;
  //     } else if (direction === "right") {
  //       current.scrollLeft += scrollDistance;
  //     }
  //   }
  // };

  const smoothScroll = (direction) => {
    const { current } = scrollRef;
    if (current && current.scrollLeft != null) {
      let scrollDistance = 1250;
      if (window.innerWidth < 400) {
        scrollDistance = 365;
      }

      let step = 20;
      let interval = 10;
      let scrolled = 0;

      const scrollStep = setInterval(() => {
        if (direction === "left") {
          current.scrollLeft -= step;
        } else if (direction === "right") {
          current.scrollLeft += step;
        }
        scrolled += step;

        if (scrolled >= scrollDistance) {
          clearInterval(scrollStep);
        }
      }, interval);
    }
  };

  const [demoVdosArr] = useState(demoVdos);

  return (
    <div className="demovdos" id="manga">
      <div className="demovdos-content">
        <Fade bottom>
          <h1 className="title fs-2 text-decoration m-0">
            Watch <span style={{ color: "#00E5EA" }}>Happy Plum</span> in action <hr />
          </h1>
        </Fade>
      </div>
      <div className="demovdos-images">
        <div className="demovdos-images_container" ref={scrollRef}>
          {demoVdosArr.map((video) => (
            <div key={video.id} className="demovdos-images_card">
              <h1 className="title">{video.name}</h1>
              <br />
              <video
                className="demovdos-image"
                src={video.vdo}
                alt={`img-${video.id}`}
                controls
                style={{
                  width: "100%",
                  height: "80vh",
                  borderRadius: "20px",
                  border: "2px solid",
                }}
              />
            </div>
          ))}
        </div>
        <div className="demovdos-images_arrows">
          <div className="demovdos-arrows-div">
            <BsArrowLeftShort className="demovdos-arrow-icon" onClick={scrollLeftDir} />
          </div>
          <div className="demovdos-arrows-div">
            <BsArrowRightShort className="demovdos-arrow-icon" onClick={scrollRightDir} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoVdos;
