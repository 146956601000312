import React, { useState, useContext } from "react";
import { Button, Container, Row, Col, Image, Alert, ProgressBar } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import confetti from "canvas-confetti";
import { updateRequestMethod } from "../../../api/api";
import { MARK_QUESTION_COMPLETED } from "../../../api/server";
import { updateStudentDetail } from "../../../redux/action";
import { ProgressContext } from "../../../contexts/ProgressContext.js";

import audios from "../../../audio";
import { frenchQuestions } from "../../../constants/data";

const Level1_3 = () => {
  const navigate = useNavigate();
  const { updateProgress } = useContext(ProgressContext);

  const token = useSelector((state) => state.LoginReducer?.token);
  const dispatch = useDispatch();
  const updateDetails = (newDetails) => {
    dispatch(updateStudentDetail(newDetails));
  };

  const redirectToNextLevel = () => {
    navigate("/dashboard/french_lessons/level1_4");
  };

  const handleQuestionComplete = (questionNumber) => {
    const isLast = questionNumber === "question5";
    try {
      updateRequestMethod(MARK_QUESTION_COMPLETED, { lesson: "lesson1_3", question: questionNumber, isLessonCompleted: isLast }, "application/json", token)
        .then((response) => {
          console.log(response);
          updateDetails(response.data.updatedStudentDetail);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("Error in updating question completion status:", error);
    }
  };

  const [currentQuestion, setCurrentQuestion] = useState({
    level: "easy",
    index: 0,
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [isCorrect, setIsCorrect] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [fadeEffect, setFadeEffect] = useState(false);

  const [count, setCount] = useState({
    easy: 0,
    medium: 0,
    hard: 0,
  });

  const [levelCompleted, setLevelCompleted] = useState(false);

  const triggerCelebration = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { x: 0.3, y: 0.6 },
    });
  };

  const playCelebrationSound = () => {
    const clappingAudio = new Audio(audios.clapping);
    clappingAudio.play();

    setTimeout(() => {
      clappingAudio.pause();
    }, 3000);
  };

  const playFailureSound = () => {
    const failureAudio = new Audio(audios.failure);
    failureAudio.play();

    setTimeout(() => {
      failureAudio.pause();
    }, 3000);
  };

  // Function to recite text using speechSynthesis API
  const reciteText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  };

  // Function to stop the speech
  const stopRecitingText = () => {
    window.speechSynthesis.cancel();
  };

  const handleOptionClick = (index) => {
    setSelectedOption(index);
    const currentQuestionSet = frenchQuestions[currentQuestion.level][currentQuestion.index];

    if (index === currentQuestionSet.correctOption) {
      setFeedback("🎉 Great job! That’s the right choice! 🎯");
      setIsCorrect(true);

      setShowConfetti(true);
      playCelebrationSound();
      setTimeout(() => setShowConfetti(false), 3000);

      handleQuestionComplete(currentQuestionSet.questionNumber);

      setCount((prev) => ({ ...prev, [currentQuestion.level]: prev[currentQuestion.level] + 1 }));

      setFadeEffect(true);
      setTimeout(() => {
        if (currentQuestion.index < frenchQuestions[currentQuestion.level].length - 1) {
          setCurrentQuestion((prev) => ({
            ...prev,
            index: prev.index + 1,
          }));
        } else {
          if (currentQuestion.level === "easy") {
            setCurrentQuestion({ level: "medium", index: 0 });
          } else if (currentQuestion.level === "medium") {
            setCurrentQuestion({ level: "hard", index: 0 });
          } else if (currentQuestion.level === "hard" && currentQuestion.index === frenchQuestions.hard.length - 1) {
            setLevelCompleted(true);
            updateProgress("French", "FrenchL3", 100);
          }
        }
        setSelectedOption(null);
        setFeedback("");
        setIsCorrect(false);
        setFadeEffect(false);
      }, 3000);
    } else {
      setFeedback("❌ Oops! That’s not correct. Try again!");
      setIsCorrect(false);
      playFailureSound();

      setFadeEffect(true);
      setTimeout(() => {
        // if (currentQuestion.level === "medium") {
        //   setCurrentQuestion({ level: "easy", index: 0 });
        // } else if (currentQuestion.level === "hard") {
        //   setCurrentQuestion({ level: "medium", index: 0 });
        // }
        setSelectedOption(null);
        setFeedback("");
        setIsCorrect(false);
        setFadeEffect(false);
      }, 2000);
    }
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const getProgress = (level) => {
    const totalQuestions = frenchQuestions[level].length;
    const completedQuestions = count[level];
    return (completedQuestions / totalQuestions) * 100;
  };

  if (levelCompleted) {
    return (
      <>
        <Container className="p-4 mt-4 text-center" style={styles.container}>
          <h2 style={styles.congratsHeader}>🎉 Congratulations!🥳You’ve passed Level 1.3! 🏆</h2>
          {/* <Button variant="success" style={styles.restartButton} onClick={() => navigate("/dashboard/french_lessons")}>
            Restart Level
          </Button> */}
          <br />
          <Button variant="primary" style={styles.dashboardButton} onClick={() => navigate("/dashboard/french_lessons")}>
            Back to Lessons
          </Button>

          <Row className="justify-content-end">
            <Col md={4} className="d-flex justify-content-end">
              <Button variant="primary" className="mb-3 mt-5" onClick={redirectToNextLevel}>
                Next Level
              </Button>
            </Col>
          </Row>
        </Container>
        <Confetti />
      </>
    );
  }

  return (
    <Container className={`p-4 mt-4 ${fadeEffect ? "fade-out" : "fade-in"}`} style={styles.container}>
      <h4 style={styles.difficultyText}>Difficulty: {capitalizeFirstLetter(currentQuestion.level)}</h4>
      <h2 style={styles.header}>Lesson 1.3: Listening Practice</h2>

      <div style={styles.questionText}>
        <h5 style={styles.chineseText}>{frenchQuestions[currentQuestion.level][currentQuestion.index].text.chinese}</h5>
      </div>

      <audio key={currentQuestion.level + currentQuestion.index} controls style={styles.audio}>
        <source src={frenchQuestions[currentQuestion.level][currentQuestion.index].audio} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>

      <Row>
        <Col md={8}>
          <Row className="justify-content-center mb-3">
            {frenchQuestions[currentQuestion.level][currentQuestion.index].options.map((img, index) => (
              <Col xs={6} md={4} key={index} style={styles.optionCol}>
                <Image
                  src={img}
                  onClick={() => handleOptionClick(index)}
                  style={{
                    ...styles.image,
                    border: selectedOption === index ? (isCorrect ? "3px solid green" : "3px solid red") : "3px solid transparent",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.opacity = 0.6;
                    reciteText(frenchQuestions[currentQuestion.level][currentQuestion.index].text.english[index]);
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.opacity = 1;
                    stopRecitingText();
                  }}
                />
              </Col>
            ))}
          </Row>
          {feedback && (
            <Alert variant={isCorrect ? "success" : "danger"} style={styles.feedbackAlert}>
              {feedback}
            </Alert>
          )}
        </Col>
        <Col>
          <div style={styles.progressContainer}>
            <h5 style={styles.progressTitle}>Progress</h5>
            <ProgressBar style={styles.progressBar} variant="info" now={getProgress("easy")} label={`Easy: ${Math.round(getProgress("easy"))}%`} />
            <ProgressBar style={styles.progressBar} variant="warning" now={getProgress("medium")} label={`Medium: ${Math.round(getProgress("medium"))}%`} />
            <ProgressBar style={styles.progressBar} variant="danger" now={getProgress("hard")} label={`Hard: ${Math.round(getProgress("hard"))}%`} />
          </div>
        </Col>
      </Row>

      {showConfetti && triggerCelebration()}

      <style>
        {`
          .fade-in {
            opacity: 0;
            animation: fadeIn 1s forwards;
          }

          .fade-out {
            animation: fadeOut 1s forwards;
          }

          @keyframes fadeIn {
            from { opacity: 0.5; }
            to { opacity: 1; }
          }

          @keyframes fadeOut {
            from { opacity: 1; }
            to { opacity: 0.5; }
          }
        `}
      </style>
    </Container>
  );
};

const styles = {
  container: {
    backgroundColor: "#f9f9f9",
    borderRadius: "12px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  header: {
    color: "#4A90E2",
    marginBottom: "1.5rem",
  },
  difficultyText: {
    color: "#333",
    fontStyle: "italic",
  },
  questionText: {
    fontSize: "1.25rem",
    fontWeight: "bold",
  },
  chineseText: {
    fontSize: "1.5rem",
    color: "#555",
  },
  image: {
    width: "100%",
    height: "auto",
    cursor: "pointer",
    transition: "border 0.3s ease",
  },
  feedbackAlert: {
    marginTop: "1rem",
  },
  congratsHeader: {
    color: "#28a745",
    marginBottom: "2rem",
  },
  restartButton: {
    padding: "0.75rem 2rem",
    fontSize: "1.25rem",
  },
  audio: {
    marginBottom: "1rem",
  },
  progressContainer: {
    marginTop: "2rem",
  },
  progressTitle: {
    fontSize: "1.25rem",
    fontWeight: "bold",
  },
  progressBar: {
    marginBottom: "0.5rem",
  },
};

export default Level1_3;
