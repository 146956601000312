import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom"; // Add this import
import { categories } from "./ai-games.data";

export default function GamingConfiguration({ selectedGame, setSelectedGame }) {
  const navigate = useNavigate(); // Add this hook
  const [formData, setFormData] = useState({
    difficulty: "Beginner",
    wordCount: 8,
    category: categories[0]?.value || "",
    timeLimit: "10",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);

    const queryParams = new URLSearchParams({
      difficulty: formData.difficulty,
      category: formData.category,
      wordCount: formData.wordCount,
      timeLimit: formData.timeLimit,
    }).toString();

    navigate(`/dashboard/games/ai-games/${selectedGame?.title}?${queryParams}`);
  };

  return (
    <Modal
      show={selectedGame !== null}
      onHide={() => setSelectedGame(null)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="!tw-text-2xl">
          {selectedGame?.title}{" "}
          <span className="tw-ml-2">{selectedGame?.icon}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="sm:tw-px-2" onSubmit={handleSubmit}>
          <Form.Group
            className="my-4 tw-px-3 tw-flex tw-items-center tw-gap-x-2"
            controlId="difficulty"
          >
            <Form.Label className="tw-w-8/12 tw-font-medium">
              Difficulty
            </Form.Label>
            <Form.Select
              className="tw-w-4/12"
              name="difficulty"
              value={formData.difficulty}
              onChange={handleInputChange}
            >
              {["Beginner", "Intermediate", "Advanced"].map((difficulty) => (
                <option key={difficulty} value={difficulty}>
                  {difficulty}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group
            className="my-4 tw-px-3 tw-flex tw-items-center tw-gap-x-2"
            controlId="wordCount"
          >
            <Form.Label className="tw-w-8/12 tw-font-medium">
              Number of Words
            </Form.Label>
            <Form.Select
              className="tw-w-4/12"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
            >
              {categories.map((category) => (
                <option key={category.value} value={category.value}>
                  {category.label} {category.icon}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group
            className="my-4 tw-px-3 tw-flex tw-items-center tw-gap-x-2"
            controlId="wordCount"
          >
            <Form.Label className="tw-w-8/12 tw-font-medium">
              Word Count
            </Form.Label>
            <Form.Select
              className="tw-w-4/12"
              name="wordCount"
              value={formData.wordCount}
              onChange={handleInputChange}
            >
              {["8", "12", "16", "20", "24"].map((count) => (
                <option key={count} value={count}>
                  {count} words
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group
            className="my-4 tw-px-3 tw-flex tw-items-center tw-gap-x-2"
            controlId="timeLimit"
          >
            <Form.Label className="tw-w-8/12 tw-font-medium">
              Time Limit
            </Form.Label>
            <Form.Select
              className="tw-w-4/12"
              name="timeLimit"
              value={formData.timeLimit}
              onChange={handleInputChange}
            >
              {["10", "20", "30", "40", "50", "60"].map((time) => (
                <option key={time} value={time}>
                  {time} seconds
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setSelectedGame(null)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Start Game
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
