import React from 'react';
import { Container, Row, Col, Card, ProgressBar, ListGroup, Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// import 'bootstrap/dist/css/bootstrap.min.css';

const ChildProfile = () => {
    const primaryColor = '#7335b7';

    const user = useSelector((state) => state.LoginReducer?.user);
    console.log(user);

    const childData = {
        name: user.fullName,
        age: '',
        level: '',
        totalLessons: 50,
        completedLessons: 32,
        streak: 0,
        vocabulary: '',
        recentAchievements: [
            'Mastered 20 new characters',
            'Completed "Family Members" unit',
            'First perfect score in listening quiz'
        ],
        upcomingLessons: [
            'Colors and Shapes',
            'Animals',
            'Numbers 1-100'
        ]
    };

    return (
        <Container className="mt-5">
            <Row>
                <Col md={4}>
                    <Card style={{ borderColor: primaryColor }}>
                        <Card.Body>
                            <Card.Title style={{ color: primaryColor }}>{childData.name}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Age: {childData.age}</Card.Subtitle>
                            <Card.Text>
                                Level: {childData.level}<br />
                                Vocabulary: {childData.vocabulary}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={8}>
                    <h2 style={{ color: primaryColor }}>Learning Progress</h2>
                    <ProgressBar now={(childData.completedLessons / childData.totalLessons) * 100}
                        label={`${childData.completedLessons}/${childData.totalLessons} Lessons`}
                        style={{ backgroundColor: primaryColor }} />
                    <p className="mt-2">Current streak: {childData.streak} days</p>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={6}>
                    <h3 style={{ color: primaryColor }}>Recent Achievements</h3>
                    <ListGroup>
                        {childData.recentAchievements.map((achievement, index) => (
                            <ListGroup.Item key={index}>
                                <Badge bg="success" className="me-2">✓</Badge>
                                {achievement}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col md={6}>
                    <h3 style={{ color: primaryColor }}>Upcoming Lessons</h3>
                    <ListGroup>
                        {childData.upcomingLessons.map((lesson, index) => (
                            <ListGroup.Item key={index}>
                                <Badge bg="primary" className="me-2">{index + 1}</Badge>
                                {lesson}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    );
};

export default ChildProfile;