import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Zoom } from "react-awesome-reveal";
import "../../css/CulturalHighlights.css";
import { culturesData } from "../../constants/data.js";

const CulturalHighlights = () => {
  const [selectedCulture, setSelectedCulture] = useState(null);

  const handleCultureSelect = (culture) => {
    setSelectedCulture(culture);
  };

  return (
    <Container className="mt-4">
      <Row className="mb-4">
        <Col md={12} className="d-flex justify-content-center">
          <div className="video-container">
            {selectedCulture ? (
              <video key={selectedCulture} width="100%" height="400" controls>
                <source src={culturesData[selectedCulture].videoURL} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <p className="text-center" style={{ color: "#686A73", fontSize: "20px" }}>
                <b>** Select a CULTURE to explore the Video **</b>
              </p>
            )}
          </div>
        </Col>
      </Row>

      <h2 className="text-center mb-4">Explore Different Cultures</h2>

      <Row className="d-flex justify-content-around">
        {Object.keys(culturesData).map((cultureKey) => (
          <Col md={3} sm={6} xs={12} key={cultureKey} className="mb-4">
            <Card
              onClick={() => handleCultureSelect(cultureKey)}
              className={`dashboard-clickable-card dashboard-hover-card smaller-card highlighted-border ${
                selectedCulture === cultureKey ? "activeLesson" : ""
              }`}
            >
              <Card.Img
                variant="top"
                src={culturesData[cultureKey].img}
                alt={cultureKey}
                className="smaller-card-img"
              />
              <Card.Body>
                <Card.Title className="text-center">{culturesData[cultureKey].title}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {selectedCulture && (
        <Row className="mt-5 justify-content-center">
          <Col md={8}>
            <Card className="shadow-sm">
              <Card.Header
                style={{
                  color: culturesData[selectedCulture].headerColor,
                  backgroundColor: culturesData[selectedCulture].headerBG,
                }}
              >
                <h4>{culturesData[selectedCulture].title}</h4>
              </Card.Header>

              <Card.Body>
                {/* Festivals Section */}
                <h3>Festivals</h3>
                <Row className="d-flex justify-content-around">
                  {culturesData[selectedCulture].festivals.map((festival, index) => (
                    <Col md={4} sm={6} xs={12} key={index} className="mb-4">
                      <Zoom>
                        <Card className="shadow-sm dashboard-hover-card">
                          <Card.Img variant="top" src={festival.img} alt={festival.name} />
                          <Card.Body>
                            <Card.Title>{festival.name}</Card.Title>
                          </Card.Body>
                        </Card>
                      </Zoom>
                    </Col>
                  ))}
                </Row>

                {/* Ancient Emperors Section */}
                <h3 className="mt-4">Ancient Emperors</h3>
                <Row className="d-flex justify-content-around">
                  {culturesData[selectedCulture].emperors.map((emperor, index) => (
                    <Col md={4} sm={6} xs={12} key={index} className="mb-4">
                      <Zoom>
                        <Card className="shadow-sm dashboard-hover-card">
                          <Card.Img variant="top" src={emperor.img} alt={emperor.name} />
                          <Card.Body>
                            <Card.Title>{emperor.name}</Card.Title>
                          </Card.Body>
                        </Card>
                      </Zoom>
                    </Col>
                  ))}
                </Row>

                {/* Living and Working Section */}
                <h3 className="mt-4">Living and Working</h3>
                <Row className="d-flex justify-content-center">
                  <Col md={6} sm={8} xs={12} className="mb-4">
                    <Zoom>
                      <Card className="shadow-sm dashboard-hover-card">
                        <Card.Img
                          variant="top"
                          src={culturesData[selectedCulture].living.img}
                          alt="Living and Working"
                        />
                        <Card.Body>
                          <Card.Text>{culturesData[selectedCulture].living.description}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Zoom>
                  </Col>
                </Row>

                {/* Daily Life Section */}
                <h3 className="mt-4">Daily Life</h3>
                <Row className="d-flex justify-content-center">
                  <Col md={6} sm={8} xs={12} className="mb-4">
                    <Zoom>
                      <Card className="shadow-sm dashboard-hover-card">
                        <Card.Img
                          variant="top"
                          src={culturesData[selectedCulture].daily.img}
                          alt="Daily Life"
                        />
                        <Card.Body>
                          <Card.Text>{culturesData[selectedCulture].daily.description}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Zoom>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default CulturalHighlights;
