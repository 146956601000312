import { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import confetti from "canvas-confetti";
import audios from "../../../../../audio";
import { quizzes } from "../../../../../constants/quizData";

const questions = quizzes.spanish_quiz.transportations_quiz;

const QuizApp = () => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [answerStatus, setAnswerStatus] = useState(null);
  const [isOptionDisabled, setIsOptionDisabled] = useState(false);
  const [quizFinished, setQuizFinished] = useState(false);

  const handleOptionClick = (option) => {
    if (isOptionDisabled) return;
    setSelectedOption(option);
    setIsOptionDisabled(true);

    if (option[1] === questions[currentQuestion].correct) {
      setScore(score + 1);
      setAnswerStatus("correct");
      playCelebrationSound();
      triggerCelebration();
    } else {
      setAnswerStatus("wrong");
      playFailureSound();
    }

    setTimeout(() => {
      setSelectedOption(null);
      setAnswerStatus(null);
      setIsOptionDisabled(false);

      if (currentQuestion + 1 < questions.length) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setQuizFinished(true);
      }
    }, 3000);
  };

  // Handle reattempt click
  const handleReattempt = () => {
    setCurrentQuestion(0);
    setScore(0);
    setQuizFinished(false);
    setSelectedOption(null);
    setAnswerStatus(null);
  };

  //
  const triggerCelebration = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { x: 0.5, y: 0.6 },
    });
  };

  const playCelebrationSound = () => {
    const clappingAudio = new Audio(audios.clapping);
    clappingAudio.play();

    setTimeout(() => {
      clappingAudio.pause();
    }, 3000);
  };

  const playFailureSound = () => {
    const failureAudio = new Audio(audios.failure);
    failureAudio.play();

    setTimeout(() => {
      failureAudio.pause();
    }, 3000);
  };

  const reciteText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "es-ES";
    window.speechSynthesis.speak(utterance);
  };

  const stopRecitingText = () => {
    window.speechSynthesis.cancel();
  };

  const progressPercentage = Math.round((currentQuestion / questions.length) * 100);

  return (
    <div className="quiz-container">
      {!quizFinished ? (
        <>
          <h1>Transportations Quiz</h1>

          {/* Progress Bar */}
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progressPercentage}%` }}></div>
          </div>

          <div className="question-container">
            <img src={questions[currentQuestion].image} alt="Animal" className="animal-image" />
            <div className="options-container">
              {questions[currentQuestion].options.map((option, index) => (
                <button key={index} className="option-btn" onClick={() => handleOptionClick(option)} disabled={isOptionDisabled} onMouseEnter={() => reciteText(option[1])} onMouseLeave={() => stopRecitingText()}>
                  {option[0]}
                </button>
              ))}
            </div>

            {answerStatus === "correct" && <h3 className="feedback">Correct!</h3>}
            {answerStatus === "wrong" && (
              <div className="feedback">
                <h3>Wrong answer.</h3>
                <h3>The correct answer is: {questions[currentQuestion].correct}</h3>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="score-container">
          <h1>Quiz Finished! 🎉🏆</h1>
          <h2>
            Your score: {score} out of {questions.length}
          </h2>

          {/* Reattempt button */}
          <button className="reattempt-btn" onClick={handleReattempt}>
            Reattempt the Quiz
          </button>
          <br />
          <br />
          <Button variant="primary" onClick={() => navigate("/dashboard/spanish_lessons/level1_1/quizzes")}>
            Back to Lessons
          </Button>
          <Confetti />
        </div>
      )}

      <style>
        {`
          body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
  }
  
  .quiz-container {
    width:600px;
    margin: 50px auto;
    padding: 15px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #c8bbe8;
  }

  /* Progress Bar */
  .progress-bar {
    height: 20px;
    width: 100%;
    background-color: #f4f4f9;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
  }

  .progress {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s ease-in-out;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .question-container {
    margin-top: 20px;
  }
  
  .animal-image {
    width: 200px;
    height: auto;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .options-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .score-container {
    margin-top: 50px;
  }
  
  .score-container h2 {
    font-size: 2rem;
    color: #333;
  }
  
  .option-btn {
    padding: 15px;
    width: 400px;
    margin: 10px 0;
    margin-left: 100px;
    background-color: #efe9f4;
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .option-btn:hover {
    background-color: #ddd;
  }
  
  .correct {
    background-color: #4caf50;
    color: white;
  }
  
  .incorrect {
    background-color: #f44336;
    color: white;
  }
  
  .option-btn:disabled {
    cursor: not-allowed;
  }

  .reattempt-btn {
    padding: 12px 24px;
    margin-top: 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
  }
  
  .reattempt-btn:hover {
    background-color: #45a049;
  }
  
  .reattempt-btn:active {
    background-color: #3e8e41;
    transform: scale(0.98);
  }
        `}
      </style>
    </div>
  );
};

export default QuizApp;
