import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Programs from "./Program.js";

const PilotProgramSection = () => {
  return (
    <section id="product-measures" className="product-measures-section text-center py-5 bg-white">
      <Container>
        <Row className="text-center">
          <Col lg={3}></Col>
          <Col lg={7}>
            <h1
              className="text-center mb-4"
              style={{
                borderBottom: "4px solid #156AA7",
                paddingBottom: "10px",
                animation: "fade-in 1.5s ease-in-out",
              }}
            >
              <b>Pilot Program for Schools</b>
            </h1>
          </Col>
          <Col lg={3}></Col>
        </Row>
        <Row>
          <Programs />
        </Row>
      </Container>
    </section>
  );
};

export default PilotProgramSection;
