import images from "../images";

const quizzes = {
  english_quiz: {
    fruits_quiz: [
      {
        image: images.apple,
        options: ["Apple", "Banana", "Orange", "Grape"],
        correct: "Apple",
      },
      {
        image: images.banana,
        options: ["Pineapple", "Banana", "Strawberry", "Mango"],
        correct: "Banana",
      },
      {
        image: images.orange,
        options: ["Kiwi", "Orange", "Peach", "Watermelon"],
        correct: "Orange",
      },
      {
        image: images.grape,
        options: ["Apple", "Grape", "Pineapple", "Banana"],
        correct: "Grape",
      },
      {
        image: images.pineapple,
        options: ["Strawberry", "Pineapple", "Mango", "Peach"],
        correct: "Pineapple",
      },
      {
        image: images.strawberry,
        options: ["Watermelon", "Banana", "Strawberry", "Kiwi"],
        correct: "Strawberry",
      },
      {
        image: images.watermelon,
        options: ["Peach", "Watermelon", "Apple", "Orange"],
        correct: "Watermelon",
      },
      {
        image: images.kiwi,
        options: ["Kiwi", "Grape", "Banana", "Strawberry"],
        correct: "Kiwi",
      },
      {
        image: images.mango,
        options: ["Mango", "Apple", "Peach", "Pineapple"],
        correct: "Mango",
      },
      {
        image: images.peach,
        options: ["Orange", "Banana", "Peach", "Watermelon"],
        correct: "Peach",
      },
    ],

    vegetables_quiz: [
      {
        image: images.tomato,
        options: ["Tomato", "Spinach", "Cucumber", "Onion"],
        correct: "Tomato",
      },
      {
        image: images.spinach,
        options: ["Tomato", "Spinach", "Cucumber", "Onion"],
        correct: "Spinach",
      },
      {
        image: images.cucumber,
        options: ["Tomato", "Spinach", "Cucumber", "Onion"],
        correct: "Cucumber",
      },
      {
        image: images.onion,
        options: ["Tomato", "Spinach", "Cucumber", "Onion"],
        correct: "Onion",
      },
      {
        image: images.broccoli,
        options: ["Broccoli", "Potato", "Carrot", "Cabbage"],
        correct: "Broccoli",
      },
      {
        image: images.potato,
        options: ["Broccoli", "Potato", "Carrot", "Cabbage"],
        correct: "Potato",
      },
      {
        image: images.carrot,
        options: ["Broccoli", "Potato", "Carrot", "Cabbage"],
        correct: "Carrot",
      },
      {
        image: images.cabbage,
        options: ["Broccoli", "Potato", "Carrot", "Cabbage"],
        correct: "Cabbage",
      },
      {
        image: images.cauliflower,
        options: ["Broccoli", "Potato", "Cauliflower", "Pumpkin"],
        correct: "Cauliflower",
      },
      {
        image: images.pumpkin,
        options: ["Broccoli", "Potato", "Cauliflower", "Pumpkin"],
        correct: "Pumpkin",
      },
    ],

    animals_quiz: [
      {
        image: images.lion,
        options: ["Elephant", "Lion", "Tiger", "Bear"],
        correct: "Lion",
      },
      {
        image: images.dog,
        options: ["Rabbit", "Elephant", "Lion", "Dog"],
        correct: "Dog",
      },
      {
        image: images.elephant,
        options: ["Lion", "Elephant", "Snake", "Zebra"],
        correct: "Elephant",
      },
      {
        image: images.giraffe,
        options: ["Giraffe", "Zebra", "Lion", "Tiger"],
        correct: "Giraffe",
      },
      {
        image: images.monkey,
        options: ["Snake", "Elephant", "Monkey", "Zebra"],
        correct: "Monkey",
      },
      {
        image: images.rabbit,
        options: ["Snake", "Zebra", "Lion", "Rabbit"],
        correct: "Rabbit",
      },
      {
        image: images.sheep,
        options: ["Monkey", "Elephant", "Sheep", "Zebra"],
        correct: "Sheep",
      },
      {
        image: images.snake,
        options: ["Snake", "Sheep", "Tiger", "Zebra"],
        correct: "Snake",
      },
      {
        image: images.tiger,
        options: ["Sheep", "Monkey", "Zebra", "Tiger"],
        correct: "Tiger",
      },
      {
        image: images.zebra,
        options: ["Lion", "Tiger", "Zebra", "Giraffe"],
        correct: "Zebra",
      },
    ],

    colors_quiz: [
      {
        image: images.red,
        options: [
          ["Red", "Red"],
          ["Blue", "Blue"],
          ["Green", "Green"],
          ["Violet", "Violet"],
        ],
        correct: "Red",
      },
      {
        image: images.blue,
        options: [
          ["Pink", "Pink"],
          ["Blue", "Blue"],
          ["Yellow", "Yellow"],
          ["Green", "Green"],
        ],
        correct: "Blue",
      },
      {
        image: images.green,
        options: [
          ["Green", "Green"],
          ["Yellow", "Yellow"],
          ["Red", "Red"],
          ["Pink", "Pink"],
        ],
        correct: "Green",
      },
      {
        image: images.violet,
        options: [
          ["Orange", "Orange"],
          ["Yellow", "Yellow"],
          ["Violet", "Violet"],
          ["Blue", "Blue"],
        ],
        correct: "Violet",
      },
      {
        image: images.yellow,
        options: [
          ["Yellow", "Yellow"],
          ["Pink", "Pink"],
          ["Red", "Red"],
          ["Green", "Green"],
        ],
        correct: "Yellow",
      },
      {
        image: images.pink,
        options: [
          ["Violet", "Violet"],
          ["Orange", "Orange"],
          ["Pink", "Pink"],
          ["Blue", "Blue"],
        ],
        correct: "Pink",
      },
      {
        image: images.orange,
        options: [
          ["Orange", "Orange"],
          ["Red", "Red"],
          ["Violet", "Violet"],
          ["Green", "Green"],
        ],
        correct: "Orange",
      },
    ],

    transportations_quiz: [
      {
        image: images.car,
        options: [
          ["Car", "Car"],
          ["Bus", "Bus"],
          ["Bicycle", "Bicycle"],
          ["Train", "Train"],
        ],
        correct: "Car",
      },
      {
        image: images.bus,
        options: [
          ["Airplane", "Airplane"],
          ["Bus", "Bus"],
          ["Ship", "Ship"],
          ["Bicycle", "Bicycle"],
        ],
        correct: "Bus",
      },
      {
        image: images.bicycle,
        options: [
          ["Bicycle", "Bicycle"],
          ["Train", "Train"],
          ["Car", "Car"],
          ["Airplane", "Airplane"],
        ],
        correct: "Bicycle",
      },
      {
        image: images.train,
        options: [
          ["Ship", "Ship"],
          ["Train", "Train"],
          ["Bus", "Bus"],
          ["Car", "Car"],
        ],
        correct: "Train",
      },
      {
        image: images.airplane,
        options: [
          ["Airplane", "Airplane"],
          ["Car", "Car"],
          ["Bicycle", "Bicycle"],
          ["Ship", "Ship"],
        ],
        correct: "Airplane",
      },
      {
        image: images.ship,
        options: [
          ["Train", "Train"],
          ["Ship", "Ship"],
          ["Bus", "Bus"],
          ["Bicycle", "Bicycle"],
        ],
        correct: "Ship",
      },
      {
        image: images.subway,
        options: [
          ["Bus", "Bus"],
          ["Train", "Train"],
          ["Car", "Car"],
          ["Subway", "Subway"],
        ],
        correct: "Subway",
      },
    ],

    weathers_quiz: [
      {
        image: images.sunny,
        options: [
          ["Sunny", "Sunny"],
          ["Rainy", "Rainy"],
          ["Cloudy", "Cloudy"],
          ["Snowy", "Snowy"],
        ],
        correct: "Sunny",
      },
      {
        image: images.rainy,
        options: [
          ["Windy", "Windy"],
          ["Rainy", "Rainy"],
          ["Stormy", "Stormy"],
          ["Foggy", "Foggy"],
        ],
        correct: "Rainy",
      },
      {
        image: images.cloudy,
        options: [
          ["Cloudy", "Cloudy"],
          ["Sunny", "Sunny"],
          ["Foggy", "Foggy"],
          ["Cold", "Cold"],
        ],
        correct: "Cloudy",
      },
      {
        image: images.snowy,
        options: [
          ["Snowy", "Snowy"],
          ["Windy", "Windy"],
          ["Rainy", "Rainy"],
          ["Cold", "Cold"],
        ],
        correct: "Snowy",
      },
      {
        image: images.windy,
        options: [
          ["Sunny", "Sunny"],
          ["Windy", "Windy"],
          ["Stormy", "Stormy"],
          ["Rainy", "Rainy"],
        ],
        correct: "Windy",
      },
      {
        image: images.stormy,
        options: [
          ["Cloudy", "Cloudy"],
          ["Sunny", "Sunny"],
          ["Rainy", "Rainy"],
          ["Stormy", "Stormy"],
        ],
        correct: "Stormy",
      },
      {
        image: images.foggy,
        options: [
          ["Sunny", "Sunny"],
          ["Foggy", "Foggy"],
          ["Cloudy", "Cloudy"],
          ["Rainy", "Rainy"],
        ],
        correct: "Foggy",
      },
      {
        image: images.cold,
        options: [
          ["Windy", "Windy"],
          ["Foggy", "Foggy"],
          ["Cold", "Cold"],
          ["Stormy", "Stormy"],
        ],
        correct: "Cold",
      },
    ],

    shapes_quiz: [
      {
        image: images.circle,
        options: [
          ["Circle", "Circle"],
          ["Square", "Square"],
          ["Rectangle", "Rectangle"],
          ["Triangle", "Triangle"],
        ],
        correct: "Circle",
      },
      {
        image: images.square,
        options: [
          ["Triangle", "Triangle"],
          ["Rhombus", "Rhombus"],
          ["Square", "Square"],
          ["Pentagon", "Pentagon"],
        ],
        correct: "Square",
      },
      {
        image: images.rectangle,
        options: [
          ["Rectangle", "Rectangle"],
          ["Oval", "Oval"],
          ["Square", "Square"],
          ["Circle", "Circle"],
        ],
        correct: "Rectangle",
      },
      {
        image: images.triangle,
        options: [
          ["Triangle", "Triangle"],
          ["Pentagon", "Pentagon"],
          ["Rhombus", "Rhombus"],
          ["Square", "Square"],
        ],
        correct: "Triangle",
      },
      {
        image: images.rhombus,
        options: [
          ["Rhombus", "Rhombus"],
          ["Square", "Square"],
          ["Oval", "Oval"],
          ["Pentagon", "Pentagon"],
        ],
        correct: "Rhombus",
      },
      {
        image: images.pentagon,
        options: [
          ["Pentagon", "Pentagon"],
          ["Circle", "Circle"],
          ["Square", "Square"],
          ["Rectangle", "Rectangle"],
        ],
        correct: "Pentagon",
      },
      {
        image: images.oval,
        options: [
          ["Circle", "Circle"],
          ["Square", "Square"],
          ["Oval", "Oval"],
          ["Rectangle", "Rectangle"],
        ],
        correct: "Oval",
      },
    ],

    daysofweek_quiz: [
      {
        image: images.monday,
        options: [
          ["Monday", "Monday"],
          ["Tuesday", "Tuesday"],
          ["Wednesday", "Wednesday"],
          ["Thursday", "Thursday"],
        ],
        correct: "Monday",
      },
      {
        image: images.tuesday,
        options: [
          ["Friday", "Friday"],
          ["Tuesday", "Tuesday"],
          ["Sunday", "Sunday"],
          ["Saturday", "Saturday"],
        ],
        correct: "Tuesday",
      },
      {
        image: images.wednesday,
        options: [
          ["Wednesday", "Wednesday"],
          ["Thursday", "Thursday"],
          ["Friday", "Friday"],
          ["Monday", "Monday"],
        ],
        correct: "Wednesday",
      },
      {
        image: images.thursday,
        options: [
          ["Saturday", "Saturday"],
          ["Thursday", "Thursday"],
          ["Tuesday", "Tuesday"],
          ["Friday", "Friday"],
        ],
        correct: "Thursday",
      },
      {
        image: images.friday,
        options: [
          ["Thursday", "Thursday"],
          ["Friday", "Friday"],
          ["Saturday", "Saturday"],
          ["Sunday", "Sunday"],
        ],
        correct: "Friday",
      },
      {
        image: images.saturday,
        options: [
          ["Sunday", "Sunday"],
          ["Saturday", "Saturday"],
          ["Monday", "Monday"],
          ["Tuesday", "Tuesday"],
        ],
        correct: "Saturday",
      },
      {
        image: images.sunday,
        options: [
          ["Saturday", "Saturday"],
          ["Friday", "Friday"],
          ["Sunday", "Sunday"],
          ["Wednesday", "Wednesday"],
        ],
        correct: "Sunday",
      },
    ],
  },

  mandarin_quiz: {
    fruits_quiz: [
      {
        image: images.apple,
        options: [
          ["Apple", "苹果"],
          ["Banana", "香蕉"],
          ["Orange", "橙子"],
          ["Grape", "葡萄"],
        ],
        correct: "苹果",
      },
      {
        image: images.banana,
        options: [
          ["Pineapple", "菠萝"],
          ["Banana", "香蕉"],
          ["Strawberry", "草莓"],
          ["Mango", "芒果"],
        ],
        correct: "香蕉",
      },
      {
        image: images.orange,
        options: [
          ["Kiwi", "奇异果"],
          ["Orange", "橙子"],
          ["Peach", "桃子"],
          ["Watermelon", "西瓜"],
        ],
        correct: "橙子",
      },
      {
        image: images.grape,
        options: [
          ["Apple", "苹果"],
          ["Grape", "葡萄"],
          ["Pineapple", "菠萝"],
          ["Banana", "香蕉"],
        ],
        correct: "葡萄",
      },
      {
        image: images.pineapple,
        options: [
          ["Strawberry", "草莓"],
          ["Pineapple", "菠萝"],
          ["Mango", "芒果"],
          ["Peach", "桃子"],
        ],
        correct: "菠萝",
      },
      {
        image: images.strawberry,
        options: [
          ["Watermelon", "西瓜"],
          ["Banana", "香蕉"],
          ["Strawberry", "草莓"],
          ["Kiwi", "奇异果"],
        ],
        correct: "草莓",
      },
      {
        image: images.watermelon,
        options: [
          ["Peach", "桃子"],
          ["Watermelon", "西瓜"],
          ["Apple", "苹果"],
          ["Orange", "橙子"],
        ],
        correct: "西瓜",
      },
      {
        image: images.kiwi,
        options: [
          ["Kiwi", "奇异果"],
          ["Grape", "葡萄"],
          ["Banana", "香蕉"],
          ["Strawberry", "草莓"],
        ],
        correct: "奇异果",
      },
      {
        image: images.mango,
        options: [
          ["Mango", "芒果"],
          ["Apple", "苹果"],
          ["Peach", "桃子"],
          ["Pineapple", "菠萝"],
        ],
        correct: "芒果",
      },
      {
        image: images.peach,
        options: [
          ["Orange", "橙子"],
          ["Banana", "香蕉"],
          ["Peach", "桃子"],
          ["Watermelon", "西瓜"],
        ],
        correct: "桃子",
      },
    ],

    vegetables_quiz: [
      {
        image: images.tomato,
        options: [
          ["Tomato", "西红柿"],
          ["Spinach", "菠菜"],
          ["Cucumber", "黄瓜"],
          ["Onion", "洋葱"],
        ],
        correct: "西红柿",
      },
      {
        image: images.spinach,
        options: [
          ["Tomato", "西红柿"],
          ["Spinach", "菠菜"],
          ["Cucumber", "黄瓜"],
          ["Onion", "洋葱"],
        ],
        correct: "菠菜",
      },
      {
        image: images.cucumber,
        options: [
          ["Tomato", "西红柿"],
          ["Spinach", "菠菜"],
          ["Cucumber", "黄瓜"],
          ["Onion", "洋葱"],
        ],
        correct: "黄瓜",
      },
      {
        image: images.onion,
        options: [
          ["Tomato", "西红柿"],
          ["Spinach", "菠菜"],
          ["Cucumber", "黄瓜"],
          ["Onion", "洋葱"],
        ],
        correct: "洋葱",
      },
      {
        image: images.broccoli,
        options: [
          ["Broccoli", "西兰花"],
          ["Potato", "土豆"],
          ["Carrot", "胡萝卜"],
          ["Cabbage", "卷心菜"],
        ],
        correct: "西兰花",
      },
      {
        image: images.potato,
        options: [
          ["Broccoli", "西兰花"],
          ["Potato", "土豆"],
          ["Carrot", "胡萝卜"],
          ["Cabbage", "卷心菜"],
        ],
        correct: "土豆",
      },
      {
        image: images.carrot,
        options: [
          ["Broccoli", "西兰花"],
          ["Potato", "土豆"],
          ["Carrot", "胡萝卜"],
          ["Cabbage", "卷心菜"],
        ],
        correct: "胡萝卜",
      },
      {
        image: images.cabbage,
        options: [
          ["Broccoli", "西兰花"],
          ["Potato", "土豆"],
          ["Carrot", "胡萝卜"],
          ["Cabbage", "卷心菜"],
        ],
        correct: "卷心菜",
      },
      {
        image: images.cauliflower,
        options: [
          ["Broccoli", "西兰花"],
          ["Potato", "土豆"],
          ["Cauliflower", "花椰菜"],
          ["Pumpkin", "南瓜"],
        ],
        correct: "花椰菜",
      },
      {
        image: images.pumpkin,
        options: [
          ["Broccoli", "西兰花"],
          ["Potato", "土豆"],
          ["Cauliflower", "花椰菜"],
          ["Pumpkin", "南瓜"],
        ],
        correct: "南瓜",
      },
    ],

    animals_quiz: [
      {
        image: images.lion,
        options: [
          ["Elephant", "大象"],
          ["Lion", "狮子"],
          ["Tiger", "老虎"],
          ["Bear", "熊"],
        ],
        correct: "狮子",
      },
      {
        image: images.dog,
        options: [
          ["Rabbit", "兔子"],
          ["Elephant", "大象"],
          ["Lion", "狮子"],
          ["Dog", "狗"],
        ],
        correct: "狗",
      },
      {
        image: images.elephant,
        options: [
          ["Lion", "狮子"],
          ["Elephant", "大象"],
          ["Snake", "蛇"],
          ["Zebra", "斑马"],
        ],
        correct: "大象",
      },
      {
        image: images.giraffe,
        options: [
          ["Giraffe", "长颈鹿"],
          ["Zebra", "斑马"],
          ["Lion", "狮子"],
          ["Tiger", "老虎"],
        ],
        correct: "长颈鹿",
      },
      {
        image: images.monkey,
        options: [
          ["Snake", "蛇"],
          ["Elephant", "大象"],
          ["Monkey", "猴子"],
          ["Zebra", "斑马"],
        ],
        correct: "猴子",
      },
      {
        image: images.rabbit,
        options: [
          ["Snake", "蛇"],
          ["Zebra", "斑马"],
          ["Lion", "狮子"],
          ["Rabbit", "兔子"],
        ],
        correct: "兔子",
      },
      {
        image: images.sheep,
        options: [
          ["Monkey", "猴子"],
          ["Elephant", "大象"],
          ["Sheep", "羊"],
          ["Zebra", "斑马"],
        ],
        correct: "羊",
      },
      {
        image: images.snake,
        options: [
          ["Snake", "蛇"],
          ["Sheep", "羊"],
          ["Tiger", "老虎"],
          ["Zebra", "斑马"],
        ],
        correct: "蛇",
      },
      {
        image: images.tiger,
        options: [
          ["Sheep", "羊"],
          ["Monkey", "猴子"],
          ["Zebra", "斑马"],
          ["Tiger", "老虎"],
        ],
        correct: "老虎",
      },
      {
        image: images.zebra,
        options: [
          ["Lion", "狮子"],
          ["Tiger", "老虎"],
          ["Zebra", "斑马"],
          ["Giraffe", "长颈鹿"],
        ],
        correct: "斑马",
      },
    ],

    colors_quiz: [
      {
        image: images.red,
        options: [
          ["Red", "红色"],
          ["Blue", "蓝色"],
          ["Green", "绿色"],
          ["Yellow", "黄色"],
        ],
        correct: "红色",
      },
      {
        image: images.blue,
        options: [
          ["Red", "红色"],
          ["Blue", "蓝色"],
          ["Green", "绿色"],
          ["Violet", "紫色"],
        ],
        correct: "蓝色",
      },
      {
        image: images.green,
        options: [
          ["Red", "红色"],
          ["Green", "绿色"],
          ["Yellow", "黄色"],
          ["Pink", "粉色"],
        ],
        correct: "绿色",
      },
      {
        image: images.violet,
        options: [
          ["Violet", "紫色"],
          ["Pink", "粉色"],
          ["Orange", "橙色"],
          ["Yellow", "黄色"],
        ],
        correct: "紫色",
      },
      {
        image: images.yellow,
        options: [
          ["Yellow", "黄色"],
          ["Orange", "橙色"],
          ["Pink", "粉色"],
          ["Green", "绿色"],
        ],
        correct: "黄色",
      },
      {
        image: images.pink,
        options: [
          ["Pink", "粉色"],
          ["Orange", "橙色"],
          ["Violet", "紫色"],
          ["Blue", "蓝色"],
        ],
        correct: "粉色",
      },
      {
        image: images.orangee,
        options: [
          ["Red", "红色"],
          ["Green", "绿色"],
          ["Yellow", "黄色"],
          ["Orange", "橙色"],
        ],
        correct: "橙色",
      },
    ],

    transportations_quiz: [
      {
        image: images.car,
        options: [
          ["Car", "汽车"],
          ["Bus", "公交车"],
          ["Bicycle", "自行车"],
          ["Train", "火车"],
        ],
        correct: "汽车",
      },
      {
        image: images.bus,
        options: [
          ["Bicycle", "自行车"],
          ["Bus", "公交车"],
          ["Car", "汽车"],
          ["Airplane", "飞机"],
        ],
        correct: "公交车",
      },
      {
        image: images.bicycle,
        options: [
          ["Train", "火车"],
          ["Airplane", "飞机"],
          ["Bicycle", "自行车"],
          ["Ship", "船"],
        ],
        correct: "自行车",
      },
      {
        image: images.train,
        options: [
          ["Car", "汽车"],
          ["Train", "火车"],
          ["Subway", "地铁"],
          ["Ship", "船"],
        ],
        correct: "火车",
      },
      {
        image: images.airplane,
        options: [
          ["Airplane", "飞机"],
          ["Ship", "船"],
          ["Subway", "地铁"],
          ["Bicycle", "自行车"],
        ],
        correct: "飞机",
      },
      {
        image: images.ship,
        options: [
          ["Ship", "船"],
          ["Bicycle", "自行车"],
          ["Bus", "公交车"],
          ["Car", "汽车"],
        ],
        correct: "船",
      },
      {
        image: images.subway,
        options: [
          ["Subway", "地铁"],
          ["Train", "火车"],
          ["Airplane", "飞机"],
          ["Car", "汽车"],
        ],
        correct: "地铁",
      },
    ],

    weathers_quiz: [
      {
        image: images.sunny,
        options: [
          ["Sunny", "晴天"],
          ["Rainy", "下雨"],
          ["Cloudy", "多云"],
          ["Snowy", "下雪"],
        ],
        correct: "晴天",
      },
      {
        image: images.rainy,
        options: [
          ["Sunny", "晴天"],
          ["Rainy", "下雨"],
          ["Cloudy", "多云"],
          ["Windy", "刮风"],
        ],
        correct: "下雨",
      },
      {
        image: images.cloudy,
        options: [
          ["Snowy", "下雪"],
          ["Windy", "刮风"],
          ["Cloudy", "多云"],
          ["Stormy", "暴风雨"],
        ],
        correct: "多云",
      },
      {
        image: images.snowy,
        options: [
          ["Cold", "冷"],
          ["Snowy", "下雪"],
          ["Foggy", "雾霾"],
          ["Rainy", "下雨"],
        ],
        correct: "下雪",
      },
      {
        image: images.windy,
        options: [
          ["Windy", "刮风"],
          ["Stormy", "暴风雨"],
          ["Foggy", "雾霾"],
          ["Cloudy", "多云"],
        ],
        correct: "刮风",
      },
      {
        image: images.stormy,
        options: [
          ["Cold", "冷"],
          ["Stormy", "暴风雨"],
          ["Foggy", "雾霾"],
          ["Sunny", "晴天"],
        ],
        correct: "暴风雨",
      },
      {
        image: images.foggy,
        options: [
          ["Sunny", "晴天"],
          ["Foggy", "雾霾"],
          ["Rainy", "下雨"],
          ["Cold", "冷"],
        ],
        correct: "雾霾",
      },
      {
        image: images.cold,
        options: [
          ["Cold", "冷"],
          ["Windy", "刮风"],
          ["Snowy", "下雪"],
          ["Stormy", "暴风雨"],
        ],
        correct: "冷",
      },
    ],

    shapes_quiz: [
      {
        image: images.circle,
        options: [
          ["Circle", "圆形"],
          ["Square", "正方形"],
          ["Rectangle", "矩形"],
          ["Triangle", "三角形"],
        ],
        correct: "圆形",
      },
      {
        image: images.square,
        options: [
          ["Pentagon", "五边形"],
          ["Square", "正方形"],
          ["Oval", "椭圆形"],
          ["Rhombus", "菱形"],
        ],
        correct: "正方形",
      },
      {
        image: images.rectangle,
        options: [
          ["Rectangle", "矩形"],
          ["Triangle", "三角形"],
          ["Rhombus", "菱形"],
          ["Circle", "圆形"],
        ],
        correct: "矩形",
      },
      {
        image: images.triangle,
        options: [
          ["Rhombus", "菱形"],
          ["Triangle", "三角形"],
          ["Pentagon", "五边形"],
          ["Square", "正方形"],
        ],
        correct: "三角形",
      },
      {
        image: images.rhombus,
        options: [
          ["Oval", "椭圆形"],
          ["Circle", "圆形"],
          ["Rhombus", "菱形"],
          ["Square", "正方形"],
        ],
        correct: "菱形",
      },
      {
        image: images.pentagon,
        options: [
          ["Pentagon", "五边形"],
          ["Triangle", "三角形"],
          ["Rectangle", "矩形"],
          ["Oval", "椭圆形"],
        ],
        correct: "五边形",
      },
      {
        image: images.oval,
        options: [
          ["Square", "正方形"],
          ["Circle", "圆形"],
          ["Oval", "椭圆形"],
          ["Rectangle", "矩形"],
        ],
        correct: "椭圆形",
      },
    ],

    daysofweek_quiz: [
      {
        image: images.monday,
        options: [
          ["Monday", "星期一"],
          ["Tuesday", "星期二"],
          ["Wednesday", "星期三"],
          ["Thursday", "星期四"],
        ],
        correct: "星期一",
      },
      {
        image: images.tuesday,
        options: [
          ["Monday", "星期一"],
          ["Tuesday", "星期二"],
          ["Wednesday", "星期三"],
          ["Thursday", "星期四"],
        ],
        correct: "星期二",
      },
      {
        image: images.wednesday,
        options: [
          ["Monday", "星期一"],
          ["Tuesday", "星期二"],
          ["Wednesday", "星期三"],
          ["Thursday", "星期四"],
        ],
        correct: "星期三",
      },
      {
        image: images.thursday,
        options: [
          ["Monday", "星期一"],
          ["Tuesday", "星期二"],
          ["Wednesday", "星期三"],
          ["Thursday", "星期四"],
        ],
        correct: "星期四",
      },
      {
        image: images.friday,
        options: [
          ["Friday", "星期五"],
          ["Saturday", "星期六"],
          ["Sunday", "星期天"],
          ["Monday", "星期一"],
        ],
        correct: "星期五",
      },
      {
        image: images.saturday,
        options: [
          ["Friday", "星期五"],
          ["Saturday", "星期六"],
          ["Sunday", "星期天"],
          ["Monday", "星期一"],
        ],
        correct: "星期六",
      },
      {
        image: images.sunday,
        options: [
          ["Friday", "星期五"],
          ["Saturday", "星期六"],
          ["Sunday", "星期天"],
          ["Monday", "星期一"],
        ],
        correct: "星期天",
      },
    ],
  },

  french_quiz: {
    fruits_quiz: [
      {
        image: images.apple,
        options: [
          ["Apple", "Pomme"],
          ["Banana", "Banane"],
          ["Orange", "Orange"],
          ["Grape", "Raisin"],
        ],
        correct: "Pomme",
      },
      {
        image: images.banana,
        options: [
          ["Pineapple", "Ananas"],
          ["Banana", "Banane"],
          ["Strawberry", "Fraise"],
          ["Mango", "Mangue"],
        ],
        correct: "Banane",
      },
      {
        image: images.orange,
        options: [
          ["Kiwi", "Kiwi"],
          ["Orange", "Orange"],
          ["Peach", "Pêche"],
          ["Watermelon", "Pastèque"],
        ],
        correct: "Orange",
      },
      {
        image: images.grape,
        options: [
          ["Apple", "Pomme"],
          ["Grape", "Raisin"],
          ["Pineapple", "Ananas"],
          ["Banana", "Banane"],
        ],
        correct: "Raisin",
      },
      {
        image: images.pineapple,
        options: [
          ["Strawberry", "Fraise"],
          ["Pineapple", "Ananas"],
          ["Mango", "Mangue"],
          ["Peach", "Pêche"],
        ],
        correct: "Ananas",
      },
      {
        image: images.strawberry,
        options: [
          ["Watermelon", "Pastèque"],
          ["Banana", "Banane"],
          ["Strawberry", "Fraise"],
          ["Kiwi", "Kiwi"],
        ],
        correct: "Fraise",
      },
      {
        image: images.watermelon,
        options: [
          ["Peach", "Pêche"],
          ["Watermelon", "Pastèque"],
          ["Apple", "Pomme"],
          ["Orange", "Orange"],
        ],
        correct: "Pastèque",
      },
      {
        image: images.kiwi,
        options: [
          ["Kiwi", "Kiwi"],
          ["Grape", "Raisin"],
          ["Banana", "Banane"],
          ["Strawberry", "Fraise"],
        ],
        correct: "Kiwi",
      },
      {
        image: images.mango,
        options: [
          ["Mango", "Mangue"],
          ["Apple", "Pomme"],
          ["Peach", "Pêche"],
          ["Pineapple", "Ananas"],
        ],
        correct: "Mangue",
      },
      {
        image: images.peach,
        options: [
          ["Orange", "Orange"],
          ["Banana", "Banane"],
          ["Peach", "Pêche"],
          ["Watermelon", "Pastèque"],
        ],
        correct: "Pêche",
      },
    ],

    vegetables_quiz: [
      {
        image: images.tomato,
        options: [
          ["Tomato", "Tomate"],
          ["Spinach", "Épinard"],
          ["Cucumber", "Concombre"],
          ["Onion", "Oignon"],
        ],
        correct: "Tomate",
      },
      {
        image: images.spinach,
        options: [
          ["Tomato", "Tomate"],
          ["Spinach", "Épinard"],
          ["Cucumber", "Concombre"],
          ["Onion", "Oignon"],
        ],
        correct: "Épinard",
      },
      {
        image: images.cucumber,
        options: [
          ["Tomato", "Tomate"],
          ["Spinach", "Épinard"],
          ["Cucumber", "Concombre"],
          ["Onion", "Oignon"],
        ],
        correct: "Concombre",
      },
      {
        image: images.onion,
        options: [
          ["Tomato", "Tomate"],
          ["Spinach", "Épinard"],
          ["Cucumber", "Concombre"],
          ["Onion", "Oignon"],
        ],
        correct: "Oignon",
      },
      {
        image: images.broccoli,
        options: [
          ["Broccoli", "Brocoli"],
          ["Potato", "Pomme de terre"],
          ["Carrot", "Carotte"],
          ["Cabbage", "Chou"],
        ],
        correct: "Brocoli",
      },
      {
        image: images.potato,
        options: [
          ["Broccoli", "Brocoli"],
          ["Potato", "Pomme de terre"],
          ["Carrot", "Carotte"],
          ["Cabbage", "Chou"],
        ],
        correct: "Pomme de terre",
      },
      {
        image: images.carrot,
        options: [
          ["Broccoli", "Brocoli"],
          ["Potato", "Pomme de terre"],
          ["Carrot", "Carotte"],
          ["Cabbage", "Chou"],
        ],
        correct: "Carotte",
      },
      {
        image: images.cabbage,
        options: [
          ["Broccoli", "Brocoli"],
          ["Potato", "Pomme de terre"],
          ["Carrot", "Carotte"],
          ["Cabbage", "Chou"],
        ],
        correct: "Chou",
      },
      {
        image: images.cauliflower,
        options: [
          ["Broccoli", "Brocoli"],
          ["Potato", "Pomme de terre"],
          ["Cauliflower", "Chou-fleur"],
          ["Pumpkin", "Citrouille"],
        ],
        correct: "Chou-fleur",
      },
      {
        image: images.pumpkin,
        options: [
          ["Broccoli", "Brocoli"],
          ["Potato", "Pomme de terre"],
          ["Cauliflower", "Chou-fleur"],
          ["Pumpkin", "Citrouille"],
        ],
        correct: "Citrouille",
      },
    ],

    animals_quiz: [
      {
        image: images.lion,
        options: [
          ["Elephant", "Éléphant"],
          ["Lion", "Lion"],
          ["Tiger", "Tigre"],
          ["Bear", "Ours"],
        ],
        correct: "Lion",
      },
      {
        image: images.dog,
        options: [
          ["Rabbit", "Lapin"],
          ["Elephant", "Éléphant"],
          ["Lion", "Lion"],
          ["Dog", "Chien"],
        ],
        correct: "Chien",
      },
      {
        image: images.elephant,
        options: [
          ["Lion", "Lion"],
          ["Elephant", "Éléphant"],
          ["Snake", "Serpent"],
          ["Zebra", "Zèbre"],
        ],
        correct: "Éléphant",
      },
      {
        image: images.giraffe,
        options: [
          ["Giraffe", "Girafe"],
          ["Zebra", "Zèbre"],
          ["Lion", "Lion"],
          ["Tiger", "Tigre"],
        ],
        correct: "Girafe",
      },
      {
        image: images.monkey,
        options: [
          ["Snake", "Serpent"],
          ["Elephant", "Éléphant"],
          ["Monkey", "Singe"],
          ["Zebra", "Zèbre"],
        ],
        correct: "Singe",
      },
      {
        image: images.rabbit,
        options: [
          ["Snake", "Serpent"],
          ["Zebra", "Zèbre"],
          ["Lion", "Lion"],
          ["Rabbit", "Lapin"],
        ],
        correct: "Lapin",
      },
      {
        image: images.sheep,
        options: [
          ["Monkey", "Singe"],
          ["Elephant", "Éléphant"],
          ["Sheep", "Mouton"],
          ["Zebra", "Zèbre"],
        ],
        correct: "Mouton",
      },
      {
        image: images.snake,
        options: [
          ["Snake", "Serpent"],
          ["Sheep", "Mouton"],
          ["Tiger", "Tigre"],
          ["Zebra", "Zèbre"],
        ],
        correct: "Serpent",
      },
      {
        image: images.tiger,
        options: [
          ["Sheep", "Mouton"],
          ["Monkey", "Singe"],
          ["Zebra", "Zèbre"],
          ["Tiger", "Tigre"],
        ],
        correct: "Tigre",
      },
      {
        image: images.zebra,
        options: [
          ["Lion", "Lion"],
          ["Tiger", "Tigre"],
          ["Zebra", "Zèbre"],
          ["Giraffe", "Girafe"],
        ],
        correct: "Zèbre",
      },
    ],

    colors_quiz: [
      {
        image: images.red,
        options: [
          ["Red", "Rouge"],
          ["Blue", "Bleu"],
          ["Green", "Vert"],
          ["Yellow", "Jaune"],
        ],
        correct: "Rouge",
      },
      {
        image: images.blue,
        options: [
          ["Red", "Rouge"],
          ["Blue", "Bleu"],
          ["Green", "Vert"],
          ["Violet", "Violet"],
        ],
        correct: "Bleu",
      },
      {
        image: images.green,
        options: [
          ["Red", "Rouge"],
          ["Green", "Vert"],
          ["Yellow", "Jaune"],
          ["Pink", "Rose"],
        ],
        correct: "Vert",
      },
      {
        image: images.violet,
        options: [
          ["Violet", "Violet"],
          ["Pink", "Rose"],
          ["Orange", "Orange"],
          ["Yellow", "Jaune"],
        ],
        correct: "Violet",
      },
      {
        image: images.yellow,
        options: [
          ["Yellow", "Jaune"],
          ["Orange", "Orange"],
          ["Pink", "Rose"],
          ["Green", "Vert"],
        ],
        correct: "Jaune",
      },
      {
        image: images.pink,
        options: [
          ["Pink", "Rose"],
          ["Orange", "Orange"],
          ["Violet", "Violet"],
          ["Blue", "Bleu"],
        ],
        correct: "Rose",
      },
      {
        image: images.orangee,
        options: [
          ["Red", "Rouge"],
          ["Green", "Vert"],
          ["Yellow", "Jaune"],
          ["Orange", "Orange"],
        ],
        correct: "Orange",
      },
    ],

    transportations_quiz: [
      {
        image: images.car,
        options: [
          ["Car", "Voiture"],
          ["Bus", "Autobus"],
          ["Bicycle", "Vélo"],
          ["Train", "Train"],
        ],
        correct: "Voiture",
      },
      {
        image: images.bus,
        options: [
          ["Bicycle", "Vélo"],
          ["Bus", "Autobus"],
          ["Car", "Voiture"],
          ["Airplane", "Avion"],
        ],
        correct: "Autobus",
      },
      {
        image: images.bicycle,
        options: [
          ["Train", "Train"],
          ["Airplane", "Avion"],
          ["Bicycle", "Vélo"],
          ["Ship", "Bateau"],
        ],
        correct: "Vélo",
      },
      {
        image: images.train,
        options: [
          ["Car", "Voiture"],
          ["Train", "Train"],
          ["Subway", "Métro"],
          ["Ship", "Bateau"],
        ],
        correct: "Train",
      },
      {
        image: images.airplane,
        options: [
          ["Airplane", "Avion"],
          ["Ship", "Bateau"],
          ["Subway", "Métro"],
          ["Bicycle", "Vélo"],
        ],
        correct: "Avion",
      },
      {
        image: images.ship,
        options: [
          ["Ship", "Bateau"],
          ["Bicycle", "Vélo"],
          ["Bus", "Autobus"],
          ["Car", "Voiture"],
        ],
        correct: "Bateau",
      },
      {
        image: images.subway,
        options: [
          ["Subway", "Métro"],
          ["Train", "Train"],
          ["Airplane", "Avion"],
          ["Car", "Voiture"],
        ],
        correct: "Métro",
      },
    ],

    weathers_quiz: [
      {
        image: images.sunny,
        options: [
          ["Sunny", "Ensoleillé"],
          ["Rainy", "Pluvieux"],
          ["Cloudy", "Nuageux"],
          ["Snowy", "Neigeux"],
        ],
        correct: "Ensoleillé",
      },
      {
        image: images.rainy,
        options: [
          ["Sunny", "Ensoleillé"],
          ["Rainy", "Pluvieux"],
          ["Cloudy", "Nuageux"],
          ["Windy", "Venteux"],
        ],
        correct: "Pluvieux",
      },
      {
        image: images.cloudy,
        options: [
          ["Snowy", "Neigeux"],
          ["Windy", "Venteux"],
          ["Cloudy", "Nuageux"],
          ["Stormy", "Orageux"],
        ],
        correct: "Nuageux",
      },
      {
        image: images.snowy,
        options: [
          ["Cold", "Froid"],
          ["Snowy", "Neigeux"],
          ["Foggy", "Brumeux"],
          ["Rainy", "Pluvieux"],
        ],
        correct: "Neigeux",
      },
      {
        image: images.windy,
        options: [
          ["Windy", "Venteux"],
          ["Stormy", "Orageux"],
          ["Foggy", "Brumeux"],
          ["Cloudy", "Nuageux"],
        ],
        correct: "Venteux",
      },
      {
        image: images.stormy,
        options: [
          ["Cold", "Froid"],
          ["Stormy", "Orageux"],
          ["Foggy", "Brumeux"],
          ["Sunny", "Ensoleillé"],
        ],
        correct: "Orageux",
      },
      {
        image: images.foggy,
        options: [
          ["Sunny", "Ensoleillé"],
          ["Foggy", "Brumeux"],
          ["Rainy", "Pluvieux"],
          ["Cold", "Froid"],
        ],
        correct: "Brumeux",
      },
      {
        image: images.cold,
        options: [
          ["Cold", "Froid"],
          ["Windy", "Venteux"],
          ["Snowy", "Neigeux"],
          ["Stormy", "Orageux"],
        ],
        correct: "Froid",
      },
    ],

    shapes_quiz: [
      {
        image: images.circle,
        options: [
          ["Circle", "Cercle"],
          ["Square", "Carré"],
          ["Rectangle", "Rectangle"],
          ["Triangle", "Triangle"],
        ],
        correct: "Cercle",
      },
      {
        image: images.square,
        options: [
          ["Pentagon", "Pentagone"],
          ["Square", "Carré"],
          ["Oval", "Ovale"],
          ["Rhombus", "Losange"],
        ],
        correct: "Carré",
      },
      {
        image: images.rectangle,
        options: [
          ["Rectangle", "Rectangle"],
          ["Triangle", "Triangle"],
          ["Rhombus", "Losange"],
          ["Circle", "Cercle"],
        ],
        correct: "Rectangle",
      },
      {
        image: images.triangle,
        options: [
          ["Rhombus", "Losange"],
          ["Triangle", "Triangle"],
          ["Pentagon", "Pentagone"],
          ["Square", "Carré"],
        ],
        correct: "Triangle",
      },
      {
        image: images.rhombus,
        options: [
          ["Oval", "Ovale"],
          ["Circle", "Cercle"],
          ["Rhombus", "Losange"],
          ["Square", "Carré"],
        ],
        correct: "Losange",
      },
      {
        image: images.pentagon,
        options: [
          ["Pentagon", "Pentagone"],
          ["Triangle", "Triangle"],
          ["Rectangle", "Rectangle"],
          ["Oval", "Ovale"],
        ],
        correct: "Pentagone",
      },
      {
        image: images.oval,
        options: [
          ["Square", "Carré"],
          ["Circle", "Cercle"],
          ["Oval", "Ovale"],
          ["Rectangle", "Rectangle"],
        ],
        correct: "Ovale",
      },
    ],

    daysofweek_quiz: [
      {
        image: images.monday,
        options: [
          ["Monday", "Lundi"],
          ["Tuesday", "Mardi"],
          ["Wednesday", "Mercredi"],
          ["Thursday", "Jeudi"],
        ],
        correct: "Lundi",
      },
      {
        image: images.tuesday,
        options: [
          ["Monday", "Lundi"],
          ["Tuesday", "Mardi"],
          ["Wednesday", "Mercredi"],
          ["Thursday", "Jeudi"],
        ],
        correct: "Mardi",
      },
      {
        image: images.wednesday,
        options: [
          ["Monday", "Lundi"],
          ["Tuesday", "Mardi"],
          ["Wednesday", "Mercredi"],
          ["Thursday", "Jeudi"],
        ],
        correct: "Mercredi",
      },
      {
        image: images.thursday,
        options: [
          ["Monday", "Lundi"],
          ["Tuesday", "Mardi"],
          ["Wednesday", "Mercredi"],
          ["Thursday", "Jeudi"],
        ],
        correct: "Jeudi",
      },
      {
        image: images.friday,
        options: [
          ["Friday", "Vendredi"],
          ["Saturday", "Samedi"],
          ["Sunday", "Dimanche"],
          ["Monday", "Lundi"],
        ],
        correct: "Vendredi",
      },
      {
        image: images.saturday,
        options: [
          ["Friday", "Vendredi"],
          ["Saturday", "Samedi"],
          ["Sunday", "Dimanche"],
          ["Monday", "Lundi"],
        ],
        correct: "Samedi",
      },
      {
        image: images.sunday,
        options: [
          ["Friday", "Vendredi"],
          ["Saturday", "Samedi"],
          ["Sunday", "Dimanche"],
          ["Monday", "Lundi"],
        ],
        correct: "Dimanche",
      },
    ],
  },

  spanish_quiz: {
    fruits_quiz: [
      {
        image: images.apple,
        options: [
          ["Apple", "Manzana"],
          ["Banana", "Plátano"],
          ["Orange", "Naranja"],
          ["Grape", "Uva"],
        ],
        correct: "Manzana",
      },
      {
        image: images.banana,
        options: [
          ["Pineapple", "Piña"],
          ["Banana", "Plátano"],
          ["Strawberry", "Fresa"],
          ["Mango", "Mango"],
        ],
        correct: "Plátano",
      },
      {
        image: images.orange,
        options: [
          ["Kiwi", "Kiwi"],
          ["Orange", "Naranja"],
          ["Peach", "Durazno"],
          ["Watermelon", "Sandía"],
        ],
        correct: "Naranja",
      },
      {
        image: images.grape,
        options: [
          ["Apple", "Manzana"],
          ["Grape", "Uva"],
          ["Pineapple", "Piña"],
          ["Banana", "Plátano"],
        ],
        correct: "Uva",
      },
      {
        image: images.pineapple,
        options: [
          ["Strawberry", "Fresa"],
          ["Pineapple", "Piña"],
          ["Mango", "Mango"],
          ["Peach", "Durazno"],
        ],
        correct: "Piña",
      },
      {
        image: images.strawberry,
        options: [
          ["Watermelon", "Sandía"],
          ["Banana", "Plátano"],
          ["Strawberry", "Fresa"],
          ["Kiwi", "Kiwi"],
        ],
        correct: "Fresa",
      },
      {
        image: images.watermelon,
        options: [
          ["Peach", "Durazno"],
          ["Watermelon", "Sandía"],
          ["Apple", "Manzana"],
          ["Orange", "Naranja"],
        ],
        correct: "Sandía",
      },
      {
        image: images.kiwi,
        options: [
          ["Kiwi", "Kiwi"],
          ["Grape", "Uva"],
          ["Banana", "Plátano"],
          ["Strawberry", "Fresa"],
        ],
        correct: "Kiwi",
      },
      {
        image: images.mango,
        options: [
          ["Mango", "Mango"],
          ["Apple", "Manzana"],
          ["Peach", "Durazno"],
          ["Pineapple", "Piña"],
        ],
        correct: "Mango",
      },
      {
        image: images.peach,
        options: [
          ["Orange", "Naranja"],
          ["Banana", "Plátano"],
          ["Peach", "Durazno"],
          ["Watermelon", "Sandía"],
        ],
        correct: "Durazno",
      },
    ],

    vegetables_quiz: [
      {
        image: images.tomato,
        options: [
          ["Tomato", "Tomate"],
          ["Spinach", "Espinaca"],
          ["Cucumber", "Pepino"],
          ["Onion", "Cebolla"],
        ],
        correct: "Tomate",
      },
      {
        image: images.spinach,
        options: [
          ["Tomato", "Tomate"],
          ["Spinach", "Espinaca"],
          ["Cucumber", "Pepino"],
          ["Onion", "Cebolla"],
        ],
        correct: "Espinaca",
      },
      {
        image: images.cucumber,
        options: [
          ["Tomato", "Tomate"],
          ["Spinach", "Espinaca"],
          ["Cucumber", "Pepino"],
          ["Onion", "Cebolla"],
        ],
        correct: "Pepino",
      },
      {
        image: images.onion,
        options: [
          ["Tomato", "Tomate"],
          ["Spinach", "Espinaca"],
          ["Cucumber", "Pepino"],
          ["Onion", "Cebolla"],
        ],
        correct: "Cebolla",
      },
      {
        image: images.broccoli,
        options: [
          ["Broccoli", "Brócoli"],
          ["Potato", "Patata"],
          ["Carrot", "Zanahoria"],
          ["Cabbage", "Repollo"],
        ],
        correct: "Brócoli",
      },
      {
        image: images.potato,
        options: [
          ["Broccoli", "Brócoli"],
          ["Potato", "Patata"],
          ["Carrot", "Zanahoria"],
          ["Cabbage", "Repollo"],
        ],
        correct: "Patata",
      },
      {
        image: images.carrot,
        options: [
          ["Broccoli", "Brócoli"],
          ["Potato", "Patata"],
          ["Carrot", "Zanahoria"],
          ["Cabbage", "Repollo"],
        ],
        correct: "Zanahoria",
      },
      {
        image: images.cabbage,
        options: [
          ["Broccoli", "Brócoli"],
          ["Potato", "Patata"],
          ["Carrot", "Zanahoria"],
          ["Cabbage", "Repollo"],
        ],
        correct: "Repollo",
      },
      {
        image: images.cauliflower,
        options: [
          ["Broccoli", "Brócoli"],
          ["Potato", "Patata"],
          ["Cauliflower", "Coliflor"],
          ["Pumpkin", "Calabaza"],
        ],
        correct: "Coliflor",
      },
      {
        image: images.pumpkin,
        options: [
          ["Broccoli", "Brócoli"],
          ["Potato", "Patata"],
          ["Cauliflower", "Coliflor"],
          ["Pumpkin", "Calabaza"],
        ],
        correct: "Calabaza",
      },
    ],

    animals_quiz: [
      {
        image: images.lion,
        options: [
          ["Elephant", "Elefante"],
          ["Lion", "León"],
          ["Tiger", "Tigre"],
          ["Bear", "Oso"],
        ],
        correct: "León",
      },
      {
        image: images.dog,
        options: [
          ["Rabbit", "Conejo"],
          ["Elephant", "Elefante"],
          ["Lion", "León"],
          ["Dog", "Perro"],
        ],
        correct: "Perro",
      },
      {
        image: images.elephant,
        options: [
          ["Lion", "León"],
          ["Elephant", "Elefante"],
          ["Snake", "Serpiente"],
          ["Zebra", "Cebra"],
        ],
        correct: "Elefante",
      },
      {
        image: images.giraffe,
        options: [
          ["Giraffe", "Jirafa"],
          ["Zebra", "Cebra"],
          ["Lion", "León"],
          ["Tiger", "Tigre"],
        ],
        correct: "Jirafa",
      },
      {
        image: images.monkey,
        options: [
          ["Snake", "Serpiente"],
          ["Elephant", "Elefante"],
          ["Monkey", "Mono"],
          ["Zebra", "Cebra"],
        ],
        correct: "Mono",
      },
      {
        image: images.rabbit,
        options: [
          ["Snake", "Serpiente"],
          ["Zebra", "Cebra"],
          ["Lion", "León"],
          ["Rabbit", "Conejo"],
        ],
        correct: "Conejo",
      },
      {
        image: images.sheep,
        options: [
          ["Monkey", "Mono"],
          ["Elephant", "Elefante"],
          ["Sheep", "Oveja"],
          ["Zebra", "Cebra"],
        ],
        correct: "Oveja",
      },
      {
        image: images.snake,
        options: [
          ["Snake", "Serpiente"],
          ["Sheep", "Oveja"],
          ["Tiger", "Tigre"],
          ["Zebra", "Cebra"],
        ],
        correct: "Serpiente",
      },
      {
        image: images.tiger,
        options: [
          ["Sheep", "Oveja"],
          ["Monkey", "Mono"],
          ["Zebra", "Cebra"],
          ["Tiger", "Tigre"],
        ],
        correct: "Tigre",
      },
      {
        image: images.zebra,
        options: [
          ["Lion", "León"],
          ["Tiger", "Tigre"],
          ["Zebra", "Cebra"],
          ["Giraffe", "Jirafa"],
        ],
        correct: "Cebra",
      },
    ],

    colors_quiz: [
      {
        image: images.red,
        options: [
          ["Red", "Rojo"],
          ["Blue", "Azul"],
          ["Green", "Verde"],
          ["Yellow", "Amarillo"],
        ],
        correct: "Rojo",
      },
      {
        image: images.blue,
        options: [
          ["Red", "Rojo"],
          ["Blue", "Azul"],
          ["Green", "Verde"],
          ["Violet", "Violeta"],
        ],
        correct: "Azul",
      },
      {
        image: images.green,
        options: [
          ["Red", "Rojo"],
          ["Green", "Verde"],
          ["Yellow", "Amarillo"],
          ["Pink", "Rosa"],
        ],
        correct: "Verde",
      },
      {
        image: images.violet,
        options: [
          ["Violet", "Violeta"],
          ["Pink", "Rosa"],
          ["Orange", "Naranja"],
          ["Yellow", "Amarillo"],
        ],
        correct: "Violeta",
      },
      {
        image: images.yellow,
        options: [
          ["Yellow", "Amarillo"],
          ["Orange", "Naranja"],
          ["Pink", "Rosa"],
          ["Green", "Verde"],
        ],
        correct: "Amarillo",
      },
      {
        image: images.pink,
        options: [
          ["Pink", "Rosa"],
          ["Orange", "Naranja"],
          ["Violet", "Violeta"],
          ["Blue", "Azul"],
        ],
        correct: "Rosa",
      },
      {
        image: images.orangee,
        options: [
          ["Red", "Rojo"],
          ["Green", "Verde"],
          ["Yellow", "Amarillo"],
          ["Orange", "Naranja"],
        ],
        correct: "Naranja",
      },
    ],

    transportations_quiz: [
      {
        image: images.car,
        options: [
          ["Car", "Coche"],
          ["Bus", "Autobús"],
          ["Bicycle", "Bicicleta"],
          ["Train", "Tren"],
        ],
        correct: "Coche",
      },
      {
        image: images.bus,
        options: [
          ["Bicycle", "Bicicleta"],
          ["Bus", "Autobús"],
          ["Car", "Coche"],
          ["Airplane", "Avión"],
        ],
        correct: "Autobús",
      },
      {
        image: images.bicycle,
        options: [
          ["Train", "Tren"],
          ["Airplane", "Avión"],
          ["Bicycle", "Bicicleta"],
          ["Ship", "Barco"],
        ],
        correct: "Bicicleta",
      },
      {
        image: images.train,
        options: [
          ["Car", "Coche"],
          ["Train", "Tren"],
          ["Subway", "Metro"],
          ["Ship", "Barco"],
        ],
        correct: "Tren",
      },
      {
        image: images.airplane,
        options: [
          ["Airplane", "Avión"],
          ["Ship", "Barco"],
          ["Subway", "Metro"],
          ["Bicycle", "Bicicleta"],
        ],
        correct: "Avión",
      },
      {
        image: images.ship,
        options: [
          ["Ship", "Barco"],
          ["Bicycle", "Bicicleta"],
          ["Bus", "Autobús"],
          ["Car", "Coche"],
        ],
        correct: "Barco",
      },
      {
        image: images.subway,
        options: [
          ["Subway", "Metro"],
          ["Train", "Tren"],
          ["Airplane", "Avión"],
          ["Car", "Coche"],
        ],
        correct: "Metro",
      },
    ],

    weathers_quiz: [
      {
        image: images.sunny,
        options: [
          ["Sunny", "Soleado"],
          ["Rainy", "Lluvioso"],
          ["Cloudy", "Nublado"],
          ["Snowy", "Nevado"],
        ],
        correct: "Soleado",
      },
      {
        image: images.rainy,
        options: [
          ["Sunny", "Soleado"],
          ["Rainy", "Lluvioso"],
          ["Cloudy", "Nublado"],
          ["Windy", "Ventoso"],
        ],
        correct: "Lluvioso",
      },
      {
        image: images.cloudy,
        options: [
          ["Snowy", "Nevado"],
          ["Windy", "Ventoso"],
          ["Cloudy", "Nublado"],
          ["Stormy", "Tormentoso"],
        ],
        correct: "Nublado",
      },
      {
        image: images.snowy,
        options: [
          ["Cold", "Frío"],
          ["Snowy", "Nevado"],
          ["Foggy", "Nebuloso"],
          ["Rainy", "Lluvioso"],
        ],
        correct: "Nevado",
      },
      {
        image: images.windy,
        options: [
          ["Windy", "Ventoso"],
          ["Stormy", "Tormentoso"],
          ["Foggy", "Nebuloso"],
          ["Cloudy", "Nublado"],
        ],
        correct: "Ventoso",
      },
      {
        image: images.stormy,
        options: [
          ["Cold", "Frío"],
          ["Stormy", "Tormentoso"],
          ["Foggy", "Nebuloso"],
          ["Sunny", "Soleado"],
        ],
        correct: "Tormentoso",
      },
      {
        image: images.foggy,
        options: [
          ["Sunny", "Soleado"],
          ["Foggy", "Nebuloso"],
          ["Rainy", "Lluvioso"],
          ["Cold", "Frío"],
        ],
        correct: "Nebuloso",
      },
      {
        image: images.cold,
        options: [
          ["Cold", "Frío"],
          ["Windy", "Ventoso"],
          ["Snowy", "Nevado"],
          ["Stormy", "Tormentoso"],
        ],
        correct: "Frío",
      },
    ],

    shapes_quiz: [
      {
        image: images.circle,
        options: [
          ["Circle", "Círculo"],
          ["Square", "Cuadrado"],
          ["Rectangle", "Rectángulo"],
          ["Triangle", "Triángulo"],
        ],
        correct: "Círculo",
      },
      {
        image: images.square,
        options: [
          ["Pentagon", "Pentágono"],
          ["Square", "Cuadrado"],
          ["Oval", "Óvalo"],
          ["Rhombus", "Rombo"],
        ],
        correct: "Cuadrado",
      },
      {
        image: images.rectangle,
        options: [
          ["Rectangle", "Rectángulo"],
          ["Triangle", "Triángulo"],
          ["Rhombus", "Rombo"],
          ["Circle", "Círculo"],
        ],
        correct: "Rectángulo",
      },
      {
        image: images.triangle,
        options: [
          ["Rhombus", "Rombo"],
          ["Triangle", "Triángulo"],
          ["Pentagon", "Pentágono"],
          ["Square", "Cuadrado"],
        ],
        correct: "Triángulo",
      },
      {
        image: images.rhombus,
        options: [
          ["Oval", "Óvalo"],
          ["Circle", "Círculo"],
          ["Rhombus", "Rombo"],
          ["Square", "Cuadrado"],
        ],
        correct: "Rombo",
      },
      {
        image: images.pentagon,
        options: [
          ["Pentagon", "Pentágono"],
          ["Triangle", "Triángulo"],
          ["Rectangle", "Rectángulo"],
          ["Oval", "Óvalo"],
        ],
        correct: "Pentágono",
      },
      {
        image: images.oval,
        options: [
          ["Square", "Cuadrado"],
          ["Circle", "Círculo"],
          ["Oval", "Óvalo"],
          ["Rectangle", "Rectángulo"],
        ],
        correct: "Óvalo",
      },
    ],

    daysofweek_quiz: [
      {
        image: images.monday,
        options: [
          ["Monday", "Lunes"],
          ["Tuesday", "Martes"],
          ["Wednesday", "Miércoles"],
          ["Thursday", "Jueves"],
        ],
        correct: "Lunes",
      },
      {
        image: images.tuesday,
        options: [
          ["Monday", "Lunes"],
          ["Tuesday", "Martes"],
          ["Wednesday", "Miércoles"],
          ["Thursday", "Jueves"],
        ],
        correct: "Martes",
      },
      {
        image: images.wednesday,
        options: [
          ["Monday", "Lunes"],
          ["Tuesday", "Martes"],
          ["Wednesday", "Miércoles"],
          ["Thursday", "Jueves"],
        ],
        correct: "Miércoles",
      },
      {
        image: images.thursday,
        options: [
          ["Monday", "Lunes"],
          ["Tuesday", "Martes"],
          ["Wednesday", "Miércoles"],
          ["Thursday", "Jueves"],
        ],
        correct: "Jueves",
      },
      {
        image: images.friday,
        options: [
          ["Friday", "Viernes"],
          ["Saturday", "Sábado"],
          ["Sunday", "Domingo"],
          ["Monday", "Lunes"],
        ],
        correct: "Viernes",
      },
      {
        image: images.saturday,
        options: [
          ["Friday", "Viernes"],
          ["Saturday", "Sábado"],
          ["Sunday", "Domingo"],
          ["Monday", "Lunes"],
        ],
        correct: "Sábado",
      },
      {
        image: images.sunday,
        options: [
          ["Friday", "Viernes"],
          ["Saturday", "Sábado"],
          ["Sunday", "Domingo"],
          ["Monday", "Lunes"],
        ],
        correct: "Domingo",
      },
    ],
  },
};

export { quizzes };
