import React from "react";
import { Tabs, Tab, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// WORDS
import ni from "../../audio/words/ni.opus";
import wo from "../../audio/words/wo.opus";
import hao from "../../audio/words/hao.opus";
import nihao from "../../audio/words/nihao.opus";
import zaijian from "../../audio/words/zaijian.opus";
import mama from "../../audio/words/mama.opus";
import baba from "../../audio/words/baba.opus";
import gege from "../../audio/words/gege.opus";
import didi from "../../audio/words/didi.opus";
import jiejie from "../../audio/words/jiejie.opus";
import meimei from "../../audio/words/meimei.opus";
import jia from "../../audio/words/jia.opus";
import xuesheng from "../../audio/words/xuesheng.opus";
import laoshi from "../../audio/words/laoshi.opus";

// PHRASES
import howAreYou from "../../audio/phrases/howAreYou.opus";
import iAmFine from "../../audio/phrases/iAmFine.opus";
import whatIsYourName from "../../audio/phrases/whatIsYourName.opus";
import myNameIs from "../../audio/phrases/myNameIs.opus";
import howOldAreYou from "../../audio/phrases/howOldAreYou.opus";
import iAm_YearsOld from "../../audio/phrases/iAm_YearsOld.opus";
import howManyPeopleAreInYourFamily from "../../audio/phrases/howManyPeopleAreInYourFamily.opus";
import myFamilyHas_NumberOfPeople from "../../audio/phrases/myFamilyHas_NumberOfPeople.opus";
import whoIsInYourFamily from "../../audio/phrases/whoIsInYourFamily.opus";
import myFamilyHas from "../../audio/phrases/myFamilyHas.opus";

// NUMBERS
import one from "../../audio/numbers/one.opus";
import two from "../../audio/numbers/two.opus";
import three from "../../audio/numbers/three.opus";
import four from "../../audio/numbers/four.opus";
import five from "../../audio/numbers/five.opus";
import six from "../../audio/numbers/six.opus";
import seven from "../../audio/numbers/seven.opus";
import eight from "../../audio/numbers/eight.opus";
import nine from "../../audio/numbers/nine.opus";
import ten from "../../audio/numbers/ten.opus";

const Goal = () => {
  const words = [
    { mandarin: "你", pronunciation: "Nǐ (You)", audio: ni },
    { mandarin: "我", pronunciation: "Wǒ (I, me)", audio: wo },
    { mandarin: "好", pronunciation: "Hǎo (fine/good)", audio: hao },
    { mandarin: "你好", pronunciation: "Nǐhǎo (Hello)", audio: nihao },
    { mandarin: "再见", pronunciation: "Zàijiàn (Goodbye)", audio: zaijian },
    { mandarin: "妈妈", pronunciation: "Māmā (Mom)", audio: mama },
    { mandarin: "爸爸", pronunciation: "Bàba (Dad)", audio: baba },
    { mandarin: "哥哥", pronunciation: "Gēgē (Older brother)", audio: gege },
    { mandarin: "弟弟", pronunciation: "Dìdì (Younger brother)", audio: didi },
    { mandarin: "姐姐", pronunciation: "Jiějiě (Older sister)", audio: jiejie },
    { mandarin: "妹妹", pronunciation: "Mèimei (Younger sister)", audio: meimei },
    { mandarin: "家", pronunciation: "Jiā (Family)", audio: jia },
    { mandarin: "学生", pronunciation: "Xuéshēng (Student)", audio: xuesheng },
    { mandarin: "老师", pronunciation: "Lǎoshī (Teacher)", audio: laoshi },
  ];

  const phrases = [
    { mandarin: "你好吗", pronunciation: "Nǐ hǎo ma? (How are you?)", audio: howAreYou },
    { mandarin: "我很好", pronunciation: "Wǒ hěn hǎo. (I am fine.)", audio: iAmFine },
    { mandarin: "你叫什么名字", pronunciation: "Nǐ jiào shénme míngzì? (What is your name?)", audio: whatIsYourName },
    { mandarin: "我叫", pronunciation: "Wǒ jiào… (My name is…)", audio: myNameIs },
    { mandarin: "你几岁 OR 你多大", pronunciation: "Nǐ jǐ suì? OR Nǐ duōdà? (How old are you?)", audio: howOldAreYou },
    { mandarin: "我…岁", pronunciation: "Wǒ___suì. (I am ___ years old.)", audio: iAm_YearsOld },
    { mandarin: "你家有几口人", pronunciation: "Nǐ jiā yǒu jǐ kǒu rén? (How many people are in your family?)", audio: howManyPeopleAreInYourFamily },
    { mandarin: "我家有…口人", pronunciation: "Wǒ jiā yǒu.. kǒu rén (My family has .. number of people)", audio: myFamilyHas_NumberOfPeople },
    { mandarin: "你家里有谁?", pronunciation: "Nǐ jiā li yǒu shéi? (Who's in your family?)", audio: whoIsInYourFamily },
    { mandarin: "我家里有…", pronunciation: "Wǒ jiā li yǒu... (My family has...)", audio: myFamilyHas },
  ];

  const numbers = [
    { mandarin: "一", pronunciation: "Yī (One)", audio: one },
    { mandarin: "二", pronunciation: "Èr (Two)", audio: two },
    { mandarin: "三", pronunciation: "Sān (Three)", audio: three },
    { mandarin: "四", pronunciation: "Sì (Four)", audio: four },
    { mandarin: "五", pronunciation: "Wǔ (Five)", audio: five },
    { mandarin: "六", pronunciation: "Liù (Six)", audio: six },
    { mandarin: "七", pronunciation: "Qī (Seven)", audio: seven },
    { mandarin: "八", pronunciation: "Bā (Eight)", audio: eight },
    { mandarin: "九", pronunciation: "Jiǔ (Nine)", audio: nine },
    { mandarin: "十", pronunciation: "Shí (Ten)", audio: ten },
  ];

  const renderTableRows = (data) => {
    return data.map((item, index) => (
      <tr key={index}>
        <td style={{ color: "#7335b7" }}>
          <strong>{item.mandarin}</strong>
        </td>
        <td style={{ color: "#7335b7" }}>{item.pronunciation}</td>
        <td>
          <audio controls style={{ border: "1px solid #7335b7", borderRadius: "30px", color: "#7335b7" }}>
            <source src={item.audio} type="audio/ogg" />
            Your browser does not support the audio element.
          </audio>
        </td>
      </tr>
    ));
  };

  return (
    <div style={{ padding: "20px", backgroundColor: "#ffffff" }}>
      <h2 style={{ color: "#7335b7", textAlign: "center" }}>
        <strong>LEARNING GOALS 1: Self Introduction</strong>
      </h2>
      <p style={{ textAlign: "center" }}>
        <strong>Student will be able to hold a short conversation and answer simple questions using these words and phrases:</strong>
      </p>
      <Tabs defaultActiveKey="words" id="learning-tabs" className="mb-3">
        <Tab eventKey="words" title="Words">
          <Table striped bordered hover>
            <thead style={{ backgroundColor: "#7335b7", color: "white" }}>
              <tr>
                <th>Mandarin</th>
                <th>Pronunciation & Meaning</th>
                <th>Audio</th>
              </tr>
            </thead>
            <tbody>{renderTableRows(words)}</tbody>
          </Table>
        </Tab>
        <Tab eventKey="phrases" title="Phrases">
          <Table striped bordered hover>
            <thead style={{ backgroundColor: "#7335b7", color: "white" }}>
              <tr>
                <th>Mandarin</th>
                <th>Pronunciation & Meaning</th>
                <th>Audio</th>
              </tr>
            </thead>
            <tbody>{renderTableRows(phrases)}</tbody>
          </Table>
        </Tab>
        <Tab eventKey="numbers" title="Numbers">
          <Table striped bordered hover>
            <thead style={{ backgroundColor: "#7335b7", color: "white" }}>
              <tr>
                <th>Mandarin</th>
                <th>Pronunciation & Meaning</th>
                <th>Audio</th>
              </tr>
            </thead>
            <tbody>{renderTableRows(numbers)}</tbody>
          </Table>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Goal;
