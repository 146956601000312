import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Zoom } from "react-awesome-reveal";
import { testimonials } from "../../constants/data.js";

const TestimonialsSection = () => {
  const [showAllTestimonials, setAllTestimonials] = useState(false);

  // Function to toggle between showing all and limited testimonials
  const handleShowAllTestimonials = () => {
    setAllTestimonials((prevState) => !prevState);
  };

  // Testimonials to show based on state
  const testimonialsToShow = showAllTestimonials ? testimonials : testimonials.slice(0, 3);

  return (
    <section id="testimonials" className="testimonials-section text-center py-5">
      <br />
      <Container>
        <Row className="text-center">
          <Col lg={4}></Col>
          <Col lg={4}>
            <div className="heading-container">
              <Zoom>
                <h1 className="subtitle mb-4 text-center" style={{ fontWeight: "600", fontSize: "1.3rem", color: "#7F8383" }}>
                  Reviewed by People
                </h1>
                <h3
                  className="text-center mb-4"
                  style={{
                    borderBottom: "4px solid #156AA7",
                    paddingBottom: "10px",
                    fontWeight: "800",
                    fontSize: "2.5rem",
                  }}
                >
                  Client’s Testimonials
                </h3>
              </Zoom>
            </div>
          </Col>
          <Col lg={4}></Col>
        </Row>

        {/* Testimonials Cards */}
        <div className="cards-container">
          <Row xs={1} sm={2} md={2} lg={3} className="justify-content-center">
            {testimonialsToShow.map((testimonial, index) => (
              <Col key={index} className="mb-4">
                <Zoom>
                  <div className="card-container">
                    <Card className="h-100 shadow-sm landing-card-hover custom-card">
                      <div className="p-4 font-bold">{testimonial.description}</div>
                      <Card.Body>
                        <h5 className="title mb-2">~ {testimonial.name}</h5>
                        <br />
                        <p className="description text-muted">{testimonial.person}</p>
                      </Card.Body>
                    </Card>
                  </div>
                </Zoom>
              </Col>
            ))}
          </Row>
        </div>

        {/* Show More or Show Less button */}
        <Row className="text-center mt-4">
          <Col>
            <Button
              variant="light"
              onClick={handleShowAllTestimonials}
              style={{
                fontWeight: "bold",
                borderRadius: "20px",
                padding: "10px 20px",
                color: "#565A5A",
                background: "#E5E7E7",
              }}
            >
              {showAllTestimonials ? "Show Less" : "More Reviews"}
            </Button>
          </Col>
        </Row>
      </Container>

      {/* Adding some spacing */}
      <style jsx>{`
        .cards-container {
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }
        .card-container {
          transition: transform 0.3s ease, opacity 0.3s ease;
          opacity: 0;
          animation: fadeInUp 0.5s forwards;
        }

        .card-container:hover {
          transform: scale(1.05);
        }

        .custom-card {
          background-color: #00e5ea;
          transition: all 0.3s ease;
        }

        @keyframes fadeInUp {
          0% {
            opacity: 0;
            transform: translateY(30px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </section>
  );
};

export default TestimonialsSection;
