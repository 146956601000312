import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import images from "../../../images";

const Level1_2 = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <div className="flex flex-col items-center justify-center w-screen h-screen">
        {/* <Link to={"http://202.131.113.154/kids_anim/kids_anim_1.html"}> */}
        <video
          className="w-3/4 h-3/4 object-cover"
          src={images.chineseAnimatedVdo}
          // src="http://202.131.113.154/kids_anim/kids_anim_1.html"
          autoPlay
          loop
          controls
          style={{
            width: "100%",
            maxHeight: "80vh",
            borderRadius: "20px",
            border: "2px solid",
          }}
        />
        {/* </Link> */}
      </div>

      <Row className="justify-content-end">
        <Col md={4} className="d-flex justify-content-end">
          <Button variant="secondary" className="mb-3 mt-5" onClick={goBack}>
            Go back to Lesson
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Level1_2;
