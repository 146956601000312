import React, { useState } from 'react';
import { Container, Row, Col, Form, ListGroup, Button, InputGroup } from 'react-bootstrap';

const TeacherSearchBar = ({ teachers, onSelectTeacher }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const filteredTeachers = teachers.filter(teacher =>
        teacher.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <InputGroup className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Search for a teacher"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ borderColor: '#7335b7' }}
                />
                <Button style={{ backgroundColor: '#7335b7', borderColor: '#7335b7' }}>Search</Button>
            </InputGroup>
            <ListGroup>
                {filteredTeachers.map((teacher, index) => (
                    <ListGroup.Item
                        key={index}
                        action
                        onClick={() => onSelectTeacher(teacher)}
                        style={{ backgroundColor: '#7335b7', color: 'white', cursor: 'pointer' }}
                    >
                        {teacher}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

const MessageItem = ({ sender, message }) => (
    <div
        style={{
            backgroundColor: sender === 'parent' ? '#7335b7' : '#f1f1f1',
            color: sender === 'parent' ? 'white' : '#7335b7',
            padding: '10px',
            borderRadius: '15px',
            margin: '10px 0',
            textAlign: sender === 'parent' ? 'right' : 'left'
        }}
    >
        {message}
    </div>
);

const MessageInterface = ({ selectedTeacher, messages, onSendMessage }) => {
    const [newMessage, setNewMessage] = useState('');

    const handleSendMessage = () => {
        if (newMessage.trim()) {
            onSendMessage(newMessage);
            setNewMessage('');
        }
    };

    return (
        <div>
            <h5 style={{ color: '#7335b7' }}>Chat with {selectedTeacher}</h5>
            <div
                style={{
                    border: '1px solid #7335b7',
                    borderRadius: '10px',
                    padding: '10px',
                    height: '300px',
                    overflowY: 'scroll',
                    backgroundColor: 'white'
                }}
            >
                {messages.map((msg, index) => (
                    <MessageItem key={index} sender={msg.sender} message={msg.message} />
                ))}
            </div>
            <InputGroup className="mt-3">
                <Form.Control
                    type="text"
                    placeholder="Type your message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    style={{ borderColor: '#7335b7' }}
                />
                <Button
                    style={{ backgroundColor: '#7335b7', borderColor: '#7335b7' }}
                    onClick={handleSendMessage}
                >
                    Send
                </Button>
            </InputGroup>
        </div>
    );
};

const Messages = () => {
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [messages, setMessages] = useState([]);

    const teachers = ['Mr. Smith', 'Ms. Johnson', 'Mr. Lee'];

    const handleSelectTeacher = (teacher) => {
        setSelectedTeacher(teacher);
        setMessages([]);
    };

    const handleSendMessage = (message) => {
        setMessages([...messages, { sender: 'parent', message }]);
        // Simulate a teacher's response
        setTimeout(() => {
            setMessages((prevMessages) => [
                ...prevMessages,
                { sender: 'teacher', message: `Teacher's response to: ${message}` }
            ]);
        }, 1000);
    };

    return (
        <Container>
            <Row>
                <Col md={4}>
                    <TeacherSearchBar teachers={teachers} onSelectTeacher={handleSelectTeacher} />
                </Col>
                <Col md={8}>
                    {selectedTeacher && (
                        <MessageInterface
                            selectedTeacher={selectedTeacher}
                            messages={messages}
                            onSendMessage={handleSendMessage}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default Messages;
