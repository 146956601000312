import { LOGIN, LOGOUT } from "../action";

export const loginInitialState = {
  user: undefined,
  isAuthenticated: false,
  token: "",
  role: "",
};

const LoginReducer = (state = loginInitialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.user,
        isAuthenticated: action.isAuthenticated,
        token: action.token,
        role: action.role,
      };

    case LOGOUT:
      return {
        ...state,
        user: undefined,
        isAuthenticated: action.isAuthenticated,
        userToken: "",
        role: "",
      };

    default:
      return { ...state };
  }
};

export default LoginReducer;
