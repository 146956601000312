import React from "react";
import { Container, Row } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";
import AnimatedVdos from "./AnimatedVdos";

const AnimatedStories = () => {
  return (
    <section id="watchdemo" className="home-section text-center">
      <Container>
        <Row
          className="align-items-center d-flex justify-content-center py-5"
          style={{
            backgroundColor: "#0741ff",
            borderRadius: "20px",
            color: "#ffffff",
          }}
        >
          <Slide direction="right">
            <AnimatedVdos />
          </Slide>
        </Row>
      </Container>
    </section>
  );
};

export default AnimatedStories;
