import React, { createContext, useState } from "react";

export const ProgressContext = createContext();

export const ProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState({
    EnglishL1: 0,
    EnglishL2: 0,
    EnglishL3: 0,
    EnglishL4: 0,
    English: 0,
    MandarinL1: 0,
    MandarinL2: 0,
    MandarinL3: 0,
    MandarinL4: 0,
    Mandarin: 0,
    SpanishL1: 0,
    SpanishL2: 0,
    SpanishL3: 0,
    SpanishL4: 0,
    Spanish: 0,
    FrenchL1: 0,
    FrenchL2: 0,
    FrenchL3: 0,
    FrenchL4: 0,
    French: 0,
  });

  const updateProgress = (language, lesson, newProgress) => {
    setProgress((prevProgress) => {
      const updatedLanguageProgress = {
        ...prevProgress,
        [lesson]: newProgress,
      };

      // Summing individual lesson progress for the overall language
      if (language === "English") {
        updatedLanguageProgress.English = (updatedLanguageProgress.EnglishL1 + updatedLanguageProgress.EnglishL2 + updatedLanguageProgress.EnglishL3 + updatedLanguageProgress.EnglishL4) / 4;
      } else if (language === "Mandarin") {
        updatedLanguageProgress.Mandarin = (updatedLanguageProgress.MandarinL1 + updatedLanguageProgress.MandarinL2 + updatedLanguageProgress.MandarinL3 + updatedLanguageProgress.MandarinL4) / 4;
      } else if (language === "Spanish") {
        updatedLanguageProgress.Spanish = (updatedLanguageProgress.SpanishL1 + updatedLanguageProgress.SpanishL2 + updatedLanguageProgress.SpanishL3 + updatedLanguageProgress.SpanishL4) / 4;
      } else if (language === "French") {
        updatedLanguageProgress.French = (updatedLanguageProgress.FrenchL1 + updatedLanguageProgress.FrenchL2 + updatedLanguageProgress.FrenchL3 + updatedLanguageProgress.FrenchL4) / 4;
      }

      return updatedLanguageProgress;
    });
  };

  return <ProgressContext.Provider value={{ progress, updateProgress }}>{children}</ProgressContext.Provider>;
};
