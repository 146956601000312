import React from "react";
import { Container, Row, Col, Card, Badge } from "react-bootstrap";

import PaymentButton from "./PaymentButton";

const SubscriptionCard = ({ title, benefits, plan, price, isRecommended, onPurchaseClick }) => {
  return (
    <Card
      style={{
        borderColor: "#7335b7",
        height: "100%",
        position: "relative",
        paddingBottom: "50px",
      }}
    >
      <Card.Header className="d-flex justify-content-between align-items-center" style={{ backgroundColor: "#7335b7", color: "white" }}>
        <span>{title}</span>
        {isRecommended && (
          <Badge bg="light" text="dark">
            Recommended
          </Badge>
        )}
      </Card.Header>
      <Card.Body>
        <h5
          style={{
            backgroundColor: "#7335b2",
            color: "white",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          → {plan} {price}/year
        </h5>
        <ul>
          {benefits.map((benefit, index) => (
            <li key={index} style={{ color: "#7335b7" }}>
              {benefit}
            </li>
          ))}
        </ul>
      </Card.Body>
      <PaymentButton onPurchaseClick={onPurchaseClick} />
    </Card>
  );
};

const Subscriptions = () => {
  const subscriptions = [
    {
      title: "Happy Plum Basic",
      plan: "Essential Family Plan: $",
      price: 35,
      benefits: ["Access to limited language lessons", "Limited progress reports", "Limited vocabulary exercises", "24/7 support"],
      isRecommended: false,
    },
    {
      title: "Happy Plum Pro",
      plan: "Plus Family Plan: $",
      price: 75,
      benefits: ["Access to all lessons", "Daily progress reports", "Advanced vocabulary exercises", "Priority email support", "Access to practice quizzes", "24/7 support"],
      isRecommended: true,
    },
    {
      title: "Happy Plum Premium",
      plan: "Premium Family Plan: $",
      price: 105,
      benefits: ["Advanced AI evaluation", "Access to avatars for gamified experience", "Access to all lessons and quizzes", "Real-time progress tracking", "Personalized feedback", "Unlimited vocabulary exercises", "24/7 support"],
      isRecommended: false,
    },
  ];

  const currentSubscription = "Pro";
  const expiryDate = "31-12-2024";

  // const handlePurchaseClick = (title) => {
  //   console.log(`Purchase clicked for ${title}`);
  // };

  return (
    <Container style={{ padding: "20px" }}>
      <h2 style={{ color: "#7335b7", textAlign: "center" }}>Subscription Plans</h2>
      <p style={{ color: "#7335b7", textAlign: "center" }}>
        Current Subscription: <b>{currentSubscription}</b> (Expires on {expiryDate})
      </p>
      <Row className="g-4">
        {subscriptions.map((subscription, index) => (
          <Col md={4} key={index}>
            <SubscriptionCard
              title={subscription.title}
              plan={subscription.plan}
              price={subscription.price}
              benefits={subscription.benefits}
              isRecommended={subscription.isRecommended}
              // onPurchaseClick={() => handlePurchaseClick(subscription.price)}
              onPurchaseClick={subscription.price}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Subscriptions;
