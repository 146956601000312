import React from 'react';
import { Container, Row, Col, ProgressBar, Card, ListGroup, Badge } from 'react-bootstrap';

const ProgressSummary = () => {
    // Dummy Data
    const overallProgress = 75; // Overall progress percentage
    const lessons = [
        { id: 1, title: 'Lesson 1: Introduction to Mandarin', progress: 100 },
        { id: 2, title: 'Lesson 2: Basic Characters', progress: 80 },
        { id: 3, title: 'Lesson 3: Common Phrases', progress: 60 },
        { id: 4, title: 'Lesson 4: Pronunciation', progress: 40 },
    ];

    const goals = [
        { id: 1, description: 'Complete Lesson 3 by the end of the week', status: 'In Progress' },
        { id: 2, description: 'Practice pronunciation for 30 minutes daily', status: 'Achieved' },
    ];

    const totalTimeSpent = '12 hours 30 minutes'; // Total time spent learning

    return (
        <Container style={{ marginTop: '20px' }}>
            <h2 style={{ color: '#7335b7', marginBottom: '20px' }}>Progress Summary</h2>
            <Row>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Overall Progress</Card.Title>
                            <ProgressBar
                                now={overallProgress}
                                label={`${overallProgress}%`}
                                variant="primary"
                                style={{ height: '25px', fontSize: '16px', backgroundColor: '#d9c5f0' }}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Time Spent Learning</Card.Title>
                            <h4 style={{ color: '#7335b7' }}>{totalTimeSpent}</h4>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Individual Lesson Progress</Card.Title>
                            {lessons.map((lesson) => (
                                <div key={lesson.id} style={{ marginBottom: '15px' }}>
                                    <h6>{lesson.title}</h6>
                                    <ProgressBar
                                        now={lesson.progress}
                                        label={`${lesson.progress}%`}
                                        variant="info"
                                        style={{ height: '20px', fontSize: '14px', backgroundColor: '#d9c5f0' }}
                                    />
                                </div>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Goals Overview</Card.Title>
                            <ListGroup>
                                {goals.map((goal) => (
                                    <ListGroup.Item key={goal.id}>
                                        {goal.description}
                                        <Badge
                                            pill
                                            variant={goal.status === 'Achieved' ? 'success' : 'warning'}
                                            style={{ float: 'right', backgroundColor: goal.status === 'Achieved' ? '#28a745' : '#ffc107' }}
                                        >
                                            {goal.status}
                                        </Badge>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ProgressSummary;
