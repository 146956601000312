import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
// import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";

// const tagManagerArgs = {
//   gtmId: "GTM-NQ4GMQ7S",
// };

// TagManager.initialize(tagManagerArgs);

// ReactGA.initialize("G-DV51F4VH0Q");
ReactGA.initialize("G-VYES9DEL6K");

ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Custom Title" });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

reportWebVitals();
