import axios from "axios";

const getInitialized = (contentType, token, params, responseType) => {
  let api = axios.create({
    withCredentials: true,
    params: params ? params : undefined,
    responseType: responseType ? responseType : undefined,
    headers: {
      "Content-Type": contentType || "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  return api;
};

export const getRequest = (url, contentType) => {
  return getInitialized(contentType)
    .get(url, contentType)
    .catch((error) => {
      console.error(error);
    });
};

export const getRequestById = (url, id, contentType) => {
  const updatedUrl = `${url}/${id}`;
  return getInitialized(contentType)
    .get(updatedUrl, contentType)
    .catch((error) => {
      console.error(error);
    });
};

export const getRequestByParams = (url, id, contentType, params) => {
  const updatedUrl = `${url}/${id}`;
  return getInitialized(contentType, "", params)
    .get(updatedUrl, contentType)
    .catch((error) => {
      console.error(error);
    });
};

export const postRequestMethod = (url, contentType, data) => {
  return getInitialized(contentType).post(url, data);
};

export const updateRequestMethod = (url, data, contentType, token) => {
  const api = getInitialized(contentType, token);

  const request = api.put(url, data);

  if (!request) {
    console.error("Invalid method provided for updateRequest");
    return Promise.reject("Invalid method");
  }

  return request.catch((error) => {
    console.error(error);
    throw error;
  });
};

export const updateRequest = (url, id, data, contentType) => {
  const updatedUrl = `${url}/${id}`;
  return getInitialized(contentType)
    .put(updatedUrl, data, contentType)
    .catch((error) => {
      console.error(error);
    });
};

export const deleteRequest = (url, id, contentType) => {
  const deleteUrl = `${url}/${id}`;
  return getInitialized(contentType)
    .delete(deleteUrl, contentType)
    .catch((error) => {
      console.error(error);
    });
};
