import React from "react";
import { Link } from "react-router-dom";
// import { Zoom, Slide } from "react-awesome-reveal";
import { Container, Row, Col, Button } from "react-bootstrap";

const ElevateLearning = () => {
  return (
    <section id="about" className="about-section py-5" style={{ backgroundColor: "#7335b7", color: "#ffffff" }}>
      <br />
      <Container>
        <h3 className="title mb-5 text-center" style={{ fontSize: "2.25rem", fontWeight: "700" }}>
          Elevate your Learning Experience with Happy Plum
        </h3>

        <Row className="justify-content-center">
          <Col lg={9} md={6} className="mb-4 d-flex">
            <div
              className="card"
              style={{
                borderRadius: "15px",
                boxShadow: "0 6px 16px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#ffffff",
                color: "#000000",
                padding: "1rem",
              }}
            >
              <div className="card-body">
                <p className="card-text" style={{ fontSize: "1.5rem", lineHeight: "1.75rem" }}>
                  Our LMS connects your child's language learning journey with parents and teachers, ensuring personalized education for future global leaders. Join us in this exciting adventure to cultivate your child's success in the 21st century.
                </p>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <Link to="https://calendly.com/happyplumint/30min" className="text-decoration-none">
                  <Button
                    variant="light"
                    style={{
                      marginRight: "10px",
                      fontWeight: "bold",
                      borderRadius: "20px",
                      padding: "10px 20px",
                      color: "#ffffff",
                      background: "#7335b7",
                    }}
                  >
                    get started
                  </Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ElevateLearning;
