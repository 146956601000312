import React from "react";
import { Container, Row, Col, Card, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../css/Lessons.css";
import images from "../../images";

const Lessons = () => {
  const navigate = useNavigate();

  const cardArray = [
    {
      title: "SELECT YOUR LEVEL",
      description: "Select your Level",
      color: "blue",
      image: <img src={images.selectLevel} alt="select-level" className="lesson-image-dashboard" />,
      onClick: () => navigate("/dashboard/select-level"),
    },
    {
      title: "STORIES",
      description: "Stories",
      color: "green",
      image: <img src={images.animatedStories} alt="stories" className="lesson-image-dashboard" />,
      onClick: () => navigate("/dashboard/stories"),
    },
  ];
  return (
    <Container className="lesson-fade-in">
      <br />
      <br />
      <br />
      <Row>
        <Col lg={12}>
          <div className="section-title course-home margin-bottom-80 center-align">
            <h2 className="title" style={{ paddingRight: "15%" }}>
              {/* Our Lessons */}
            </h2>
          </div>
        </Col>
      </Row>

      <Row>
        {cardArray.map((lesson, index) => (
          <Col lg={6} md={5} sm={15} key={index} className="lesson-card-col">
            <Card
              style={{
                cursor: "pointer",
                border: `5px solid ${lesson.color}`,
                transition: "transform 0.3s ease-in-out, box-shadow 0.3s",
                minHeight: "35vw",
              }}
              onClick={lesson.onClick}
              className="text-center lesson-card-hover"
            >
              <Card.Body
                className="d-flex flex-column justify-content-between lesson-image-dashboard"
                style={{
                  backgroundColor: "#fff",
                  transition: "background-color 0.3s",
                  // minHeight: "40vw",
                }}
              >
                {lesson.image}
                <Card.Title style={{ color: lesson.color }}>{lesson.title}</Card.Title>
                {/* <Card.Text>{lesson.description}</Card.Text> */}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Lessons;
