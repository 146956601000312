import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import images from "../images";

function Header() {
  return (
    <>
      {/* Navbar */}
      <Navbar collapseOnSelect expand="lg" className="sticky-top appbar" style={{ backgroundColor: "#7335b7", borderBottom: "1px solid #ffffff" }}>
        <Container>
          <Navbar.Brand style={{ color: "#ffffff" }} href="/#home">
            <img alt="Happy Plum Logo" src={images.logo} width="30" height="30" className="d-inline-block align-top" /> Happy Plum
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" className="text-white" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto" style={{ display: "flex", gap: "1rem" }}>
              <Nav.Link style={{ color: "#ffffff", fontWeight: "600" }} href="/#about">
                About Us
              </Nav.Link>
              <Nav.Link style={{ color: "#ffffff", fontWeight: "600" }} href="/#testimonials">
                Testimonials
              </Nav.Link>
              <Nav.Link style={{ color: "#ffffff", fontWeight: "600" }} href="/#team">
                Meet the Team
              </Nav.Link>
              <Nav.Link style={{ color: "#ffffff", fontWeight: "600" }} href="/#contact">
                Contact Us
              </Nav.Link>
              <Nav.Link style={{ color: "#ffffff", fontWeight: "600" }} href="/#faq">
                FAQs
              </Nav.Link>
            </Nav>
            <div className="ms-auto">
              <Nav.Link className="login-btn" style={{ color: "#7335b7", backgroundColor: "#ffffff", borderRadius: "4px", padding: "0.5rem 1rem", fontWeight: "700" }} href="/login">
                Login
              </Nav.Link>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
