import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button, ListGroup } from "react-bootstrap";

const SetGoal = () => {
  const [goals, setGoals] = useState([
    {
      id: 1,
      description: "Complete Lesson 5 in Mandarin Writing",
      timeGoal: 2, // in hours
      scoreGoal: 85, // out of 100
    },
    {
      id: 2,
      description: "Practice Mandarin Speaking for 30 minutes daily",
      timeGoal: 0.5, // in hours
      scoreGoal: 90, // out of 100
    },
  ]);

  const [newGoal, setNewGoal] = useState("");
  const [timeGoal, setTimeGoal] = useState("");
  const [scoreGoal, setScoreGoal] = useState("");

  const handleAddGoal = () => {
    const nextId = goals.length ? goals[goals.length - 1].id + 1 : 1;
    setGoals([
      ...goals,
      {
        id: nextId,
        description: newGoal,
        timeGoal: parseFloat(timeGoal),
        scoreGoal: parseInt(scoreGoal),
      },
    ]);
    setNewGoal("");
    setTimeGoal("");
    setScoreGoal("");
  };

  return (
    <Container style={{ marginTop: "20px" }}>
      <h2 style={{ color: "#7335b7", marginBottom: "20px" }}>Learning Goals</h2>

      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title style={{ color: "#7335b7" }}>Current Goals</Card.Title>
              <ListGroup>
                {goals.map((goal) => (
                  <ListGroup.Item key={goal.id}>
                    <h5>{goal.description}</h5>
                    <p>Time Goal: {goal.timeGoal} hour(s)</p>
                    <p>Score Goal: {goal.scoreGoal}%</p>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title style={{ color: "#7335b7" }}>Set a New Goal</Card.Title>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Goal Description</Form.Label>
                  <Form.Control type="text" value={newGoal} onChange={(e) => setNewGoal(e.target.value)} placeholder="Enter new learning goal" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Time Goal (hours)</Form.Label>
                  <Form.Control type="number" value={timeGoal} onChange={(e) => setTimeGoal(e.target.value)} placeholder="Enter time goal" min="0" step="0.1" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Score Goal (%)</Form.Label>
                  <Form.Control type="number" value={scoreGoal} onChange={(e) => setScoreGoal(e.target.value)} placeholder="Enter score goal" min="0" max="100" />
                </Form.Group>
                <Button variant="primary" onClick={handleAddGoal} style={{ backgroundColor: "#7335b7", borderColor: "#7335b7" }}>
                  Add Goal
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SetGoal;
