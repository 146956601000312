import { combineReducers } from "@reduxjs/toolkit";
import LoginReducer from "./Reducer/LoginReducer";
import StudentDetailReducer from "./Reducer/StudentDetailReducer";

export const RootReducer = combineReducers({
  LoginReducer: LoginReducer,
  StudentDetailReducer: StudentDetailReducer,
});

// const store = createStore(allApplicationReducer);

// export default store;
