import english_q1 from "./phrases/english_quest/english_q1.mp3";
import english_q2 from "./phrases/english_quest/english_q2.mp3";
import english_q3 from "./phrases/english_quest/english_q3.mp3";
import english_q4 from "./phrases/english_quest/english_q4.mp3";
import english_q5 from "./phrases/english_quest/english_q5.mp3";

import english1 from "./words/english_words/english_dad.mp3";
import english2 from "./words/english_words/english_brother.mp3";
import english3 from "./words/english_words/english_mom.mp3";

import chinese_q1 from "./phrases/mandarin_quest/mandarin_q1.mp3";
import chinese_q2 from "./phrases/mandarin_quest/mandarin_q2.mp3";
import chinese_q3 from "./phrases/mandarin_quest/mandarin_q3.mp3";
import chinese_q4 from "./phrases/mandarin_quest/mandarin_q4.mp3";
import chinese_q5 from "./phrases/mandarin_quest/mandarin_q5.mp3";

import chinese1 from "./words/baba.opus";
import chinese2 from "./words/didi.opus";
import chinese3 from "./words/mama.opus";

import spanish_q1 from "./phrases/spanish_quest/spanish_q1.mp3";
import spanish_q2 from "./phrases/spanish_quest/spanish_q2.mp3";
import spanish_q3 from "./phrases/spanish_quest/spanish_q3.mp3";
import spanish_q4 from "./phrases/spanish_quest/spanish_q4.mp3";
import spanish_q5 from "./phrases/spanish_quest/spanish_q5.mp3";

import spanish1 from "./words/spanish_words/spanish_dad.mp3";
import spanish2 from "./words/spanish_words/spanish_brother.mp3";
import spanish3 from "./words/spanish_words/spanish_mom.mp3";

import french_q1 from "./phrases/french_quest/french_q1.mp3";
import french_q2 from "./phrases/french_quest/french_q2.mp3";
import french_q3 from "./phrases/french_quest/french_q3.mp3";
import french_q4 from "./phrases/french_quest/french_q4.mp3";
import french_q5 from "./phrases/french_quest/french_q5.mp3";

import french1 from "./words/french_words/french_dad.mp3";
import french2 from "./words/french_words/french_brother.mp3";
import french3 from "./words/french_words/french_mom.mp3";

import clapping from "./sounds/clapping.mp3";
import failure from "./sounds/failure.mp3";

import appleEnglish from "./words/lessons/english/apple.mp3";
import mangoEnglish from "./words/lessons/english/mango.mp3";
import bananaEnglish from "./words/lessons/english/banana.mp3";
import carrotEnglish from "./words/lessons/english/carrot.mp3";
import tomatoEnglish from "./words/lessons/english/tomato.mp3";
import spinachEnglish from "./words/lessons/english/spinach.mp3";
import tigerEnglish from "./words/lessons/english/tiger.mp3";
import lionEnglish from "./words/lessons/english/lion.mp3";
import catEnglish from "./words/lessons/english/cat.mp3";
import carEnglish from "./words/lessons/english/car.mp3";
import shipEnglish from "./words/lessons/english/ship.mp3";
import airplaneEnglish from "./words/lessons/english/airplane.mp3";
import redEnglish from "./words/lessons/english/red.mp3";
import blueEnglish from "./words/lessons/english/blue.mp3";
import greenEnglish from "./words/lessons/english/green.mp3";

import appleMandarin from "./words/lessons/mandarin/apple.mp3";
import mangoMandarin from "./words/lessons/mandarin/mango.mp3";
import bananaMandarin from "./words/lessons/mandarin/banana.mp3";
import carrotMandarin from "./words/lessons/mandarin/carrot.mp3";
import tomatoMandarin from "./words/lessons/mandarin/tomato.mp3";
import spinachMandarin from "./words/lessons/mandarin/spinach.mp3";
import tigerMandarin from "./words/lessons/mandarin/tiger.mp3";
import lionMandarin from "./words/lessons/mandarin/lion.mp3";
import catMandarin from "./words/lessons/mandarin/cat.mp3";
import carMandarin from "./words/lessons/mandarin/car.mp3";
import shipMandarin from "./words/lessons/mandarin/ship.mp3";
import airplaneMandarin from "./words/lessons/mandarin/airplane.mp3";
import redMandarin from "./words/lessons/mandarin/red.mp3";
import blueMandarin from "./words/lessons/mandarin/blue.mp3";
import greenMandarin from "./words/lessons/mandarin/green.mp3";

import appleFrench from "./words/lessons/french/apple.mp3";
import mangoFrench from "./words/lessons/french/mango.mp3";
import bananaFrench from "./words/lessons/french/banana.mp3";
import carrotFrench from "./words/lessons/french/carrot.mp3";
import tomatoFrench from "./words/lessons/french/tomato.mp3";
import spinachFrench from "./words/lessons/french/spinach.mp3";
import tigerFrench from "./words/lessons/french/tiger.mp3";
import lionFrench from "./words/lessons/french/lion.mp3";
import catFrench from "./words/lessons/french/cat.mp3";
import carFrench from "./words/lessons/french/car.mp3";
import shipFrench from "./words/lessons/french/ship.mp3";
import airplaneFrench from "./words/lessons/french/airplane.mp3";
import redFrench from "./words/lessons/french/red.mp3";
import blueFrench from "./words/lessons/french/blue.mp3";
import greenFrench from "./words/lessons/french/green.mp3";

import appleSpanish from "./words/lessons/spanish/apple.mp3";
import mangoSpanish from "./words/lessons/spanish/mango.mp3";
import bananaSpanish from "./words/lessons/spanish/banana.mp3";
import carrotSpanish from "./words/lessons/spanish/carrot.mp3";
import tomatoSpanish from "./words/lessons/spanish/tomato.mp3";
import spinachSpanish from "./words/lessons/spanish/spinach.mp3";
import tigerSpanish from "./words/lessons/spanish/tiger.mp3";
import lionSpanish from "./words/lessons/spanish/lion.mp3";
import catSpanish from "./words/lessons/spanish/cat.mp3";
import carSpanish from "./words/lessons/spanish/car.mp3";
import shipSpanish from "./words/lessons/spanish/ship.mp3";
import airplaneSpanish from "./words/lessons/spanish/airplane.mp3";
import redSpanish from "./words/lessons/spanish/red.mp3";
import blueSpanish from "./words/lessons/spanish/blue.mp3";
import greenSpanish from "./words/lessons/spanish/green.mp3";

const audios = {
  appleEnglish,
  mangoEnglish,
  bananaEnglish,
  carrotEnglish,
  tomatoEnglish,
  spinachEnglish,
  tigerEnglish,
  lionEnglish,
  catEnglish,
  carEnglish,
  shipEnglish,
  airplaneEnglish,
  redEnglish,
  blueEnglish,
  greenEnglish,
  appleMandarin,
  mangoMandarin,
  bananaMandarin,
  carrotMandarin,
  tomatoMandarin,
  spinachMandarin,
  tigerMandarin,
  lionMandarin,
  catMandarin,
  carMandarin,
  shipMandarin,
  airplaneMandarin,
  redMandarin,
  blueMandarin,
  greenMandarin,
  appleFrench,
  mangoFrench,
  bananaFrench,
  carrotFrench,
  tomatoFrench,
  spinachFrench,
  tigerFrench,
  lionFrench,
  catFrench,
  carFrench,
  shipFrench,
  airplaneFrench,
  redFrench,
  blueFrench,
  greenFrench,
  appleSpanish,
  mangoSpanish,
  bananaSpanish,
  carrotSpanish,
  tomatoSpanish,
  spinachSpanish,
  tigerSpanish,
  lionSpanish,
  catSpanish,
  carSpanish,
  shipSpanish,
  airplaneSpanish,
  redSpanish,
  blueSpanish,
  greenSpanish,
  english_q1,
  english_q2,
  english_q3,
  english_q4,
  english_q5,
  english1,
  english2,
  english3,
  chinese_q1,
  chinese_q2,
  chinese_q3,
  chinese_q4,
  chinese_q5,
  chinese1,
  chinese2,
  chinese3,
  spanish_q1,
  spanish_q2,
  spanish_q3,
  spanish_q4,
  spanish_q5,
  spanish1,
  spanish2,
  spanish3,
  french_q1,
  french_q2,
  french_q3,
  french_q4,
  french_q5,
  french1,
  french2,
  french3,
  clapping,
  failure,
};

export default audios;
