import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import images from "../../../../images";

const categories = [
  {
    title: "Fruits Quiz",
    color: "#8BC34A",
    path: "/dashboard/spanish_lessons/level1_1/quizzes/fruits-quiz",
    image: images.fruits,
  },
  {
    title: "Vegetables Quiz",
    color: "#03A9F4",
    path: "/dashboard/spanish_lessons/level1_1/quizzes/vegetables-quiz",
    image: images.vegetables,
  },
  {
    title: "Animals Quiz",
    color: "#E91E63",
    path: "/dashboard/spanish_lessons/level1_1/quizzes/animals-quiz",
    image: images.animals,
  },
  {
    title: "Colors Quiz",
    color: "#FF5722",
    path: "/dashboard/spanish_lessons/level1_1/quizzes/colors-quiz",
    image: images.colors,
  },
  {
    title: "Transportation Quiz",
    color: "#673AB7",
    path: "/dashboard/spanish_lessons/level1_1/quizzes/transportations-quiz",
    image: images.transportations,
  },
  {
    title: "Weather Quiz",
    color: "#00BCD4",
    path: "/dashboard/spanish_lessons/level1_1/quizzes/weathers-quiz",
    image: images.weathers,
  },
  {
    title: "Shapes Quiz",
    color: "#FFEB3B",
    path: "/dashboard/spanish_lessons/level1_1/quizzes/shapes-quiz",
    image: images.shapes,
  },
  {
    title: "Days of the Week Quiz",
    color: "#FFC107",
    path: "/dashboard/spanish_lessons/level1_1/quizzes/daysofweek-quiz",
    image: images.daysofweek,
  },
];

const ChineseLevel1_1 = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <motion.h2
            className="mb-4"
            style={{ color: "#4A90E2", fontWeight: "bold" }}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Complete these QUIZZES
          </motion.h2>
        </Col>
      </Row>

      <Row className="justify-content-center">
        {categories.map((category, index) => (
          <Col lg={3} md={4} sm={6} xs={12} key={index} className="mb-4">
            <motion.div
              whileHover={{ scale: 1.1, rotate: 3 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.2 }}
              onClick={() => navigate(category.path)}
            >
              <Card
                className="h-100"
                style={{
                  backgroundColor: category.color,
                  borderRadius: "15px",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  cursor: "pointer",
                }}
              >
                <Card.Img
                  variant="top"
                  src={category.image}
                  style={{
                    height: "150px",
                    objectFit: "cover",
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                />
                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                  <motion.h4
                    style={{
                      fontSize: "2rem",
                      color: "#fff",
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    {category.title}
                  </motion.h4>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>

      <Row className="justify-content-end">
        <Col md={4} className="d-flex justify-content-end">
          <Button variant="secondary" className="mb-3 mt-5" onClick={goBack}>
            Go back
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ChineseLevel1_1;
