import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { Zoom, Slide } from "react-awesome-reveal";
import "react-vertical-timeline-component/style.min.css";
import "../../css/ProgramCard.css";

const ProgramCard = ({ event }) => {
  return (
    <VerticalTimelineElement
      contentStyle={{
        // background: "#1d1f32",
        background: "#ffffff",
        // color: "#000000",
        border: "0",
      }}
      // date={event.name}
      icon={
        <Zoom duration="700">
          <img src={event.img} className="program-icon" alt="picc" />
        </Zoom>
      }
    >
      <Slide>
        <div className="program-myCard">
          <div className="program-innerCard">
            <div className="program-frontSide">
              <p id="program-card-data">{event.desc}</p>
            </div>

            <div className="program-backSide">
              <p id="program-card-data">{event.desc}</p>
            </div>
          </div>
        </div>
      </Slide>
    </VerticalTimelineElement>
  );
};

export default ProgramCard;
