import React, { useState } from 'react';
import { Col, Dropdown, Card } from 'react-bootstrap';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';

import { useSelector, useDispatch } from 'react-redux';
import { updateRequestMethod } from '../../../api/api'
import { MARK_QUESTION_COMPLETED } from "../../../api/server";
import { updateStudentDetail } from '../../../redux/action';

const LessonsComponent = ({ lessonsData }) => {
    console.log(lessonsData);
    const [selectedLesson, setSelectedLesson] = useState('lesson1_3');

    // Function to handle lesson selection
    const handleSelect = (lesson) => {
        setSelectedLesson(lesson);
    };

    const token = useSelector((state) => state.LoginReducer?.token);

    const dispatch = useDispatch();
    const updateDetails = (newDetails) => {
        dispatch(updateStudentDetail(newDetails));
    };

    const handleClick = () => {
        try {
            updateRequestMethod(MARK_QUESTION_COMPLETED, { lesson: 'lesson1_1', question: 'question3', isLessonCompleted: true }, "application/json", token)
                .then(response => {
                    console.log(response);
                    updateDetails(response.data.updatedStudentDetail);
                }).catch(error => {
                    console.log(error);
                });
        } catch (error) {
            console.log("Error in updating question completion status:", error);
        }
    }

    return (

        <Col>
            <Card md={4}>
                <Card.Body style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Card.Title>Lessons Progress</Card.Title>
                    <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle variant="primary" style={{ backgroundColor: '#7335b7' }}>
                            {selectedLesson.replace('lesson', 'Lesson ').replace('_', '.')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {Object.keys(lessonsData).map((lessonKey) => {
                                if (!lessonKey.startsWith('lesson')) {
                                    return null;
                                }
                                const lesson = lessonsData[lessonKey];
                                console.log(lesson);
                                const lessonCompleted = lesson.completed;
                                console.log(lessonCompleted);
                                return (
                                    <Dropdown.Item key={lessonKey} eventKey={lessonKey}>
                                        {lessonKey.replace('lesson', 'Lesson ').replace('_', '.')} {lessonCompleted ? <FaCheckCircle color="green" /> : <FaRegCircle color='#7335b7' />}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>

                    <div style={{ marginTop: '20px' }}>
                        <h5>{selectedLesson.replace('lesson', 'Lesson ').replace('_', '.')}</h5>
                        {Object.entries(lessonsData[selectedLesson].questions).map(([questionKey, question]) => (
                            <div key={questionKey}>
                                {questionKey}  {question.completed ? <FaCheckCircle color="green" /> : <FaRegCircle color='#7335b7' />}
                            </div>
                        ))}
                    </div>

                    {/* <button onClick={handleClick}>click</button> */}
                </Card.Body>
            </Card>
        </Col>
    );
};

export default LessonsComponent;

