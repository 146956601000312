import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import images from "../../../images";

const categories = [
  { title: "Fruits", color: "#8BC34A", path: "/dashboard/english_lessons/level1_1/fruits", image: images.fruits },
  { title: "Vegetables", color: "#03A9F4", path: "/dashboard/english_lessons/level1_1/vegetables", image: images.vegetables },
  { title: "Animals", color: "#E91E63", path: "/dashboard/english_lessons/level1_1/animals", image: images.animals },
  { title: "Colors", color: "#FF5722", path: "/dashboard/english_lessons/level1_1/colors", image: images.colors },
  { title: "Transportation", color: "#673AB7", path: "/dashboard/english_lessons/level1_1/transportation", image: images.transportations },
  { title: "Weather", color: "#00BCD4", path: "/dashboard/english_lessons/level1_1/weather", image: images.weathers },
  { title: "Shapes", color: "#FFEB3B", path: "/dashboard/english_lessons/level1_1/shapes", image: images.shapes },
  { title: "Days of the Week", color: "#FFC107", path: "/dashboard/english_lessons/level1_1/days-of-week", image: images.daysofweek },
  { title: "Vocabulary", color: "#4CAF50", path: "/dashboard/english_lessons/level1_1/vocabulary", image: images.vocabulary },
  { title: "Quizzes", color: "#FF5722", path: "/dashboard/english_lessons/level1_1/quizzes", image: images.quiz },
];

const Level1_1 = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const reciteText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(utterance);
  };

  const stopRecitingText = () => {
    window.speechSynthesis.cancel();
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <motion.h2 className="mb-4" style={{ color: "#4A90E2", fontWeight: "bold" }} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
            Learn these words and complete the Quizzes
          </motion.h2>
        </Col>
      </Row>

      <Row className="justify-content-center">
        {categories.map((category, index) => (
          <Col lg={3} md={4} sm={6} xs={12} key={index} className="mb-4">
            <motion.div whileHover={{ scale: 1.1, rotate: 3 }} whileTap={{ scale: 0.95 }} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3, delay: 0.2 }} onClick={() => navigate(category.path)}>
              <Card
                className="h-100"
                style={{
                  backgroundColor: category.color,
                  borderRadius: "15px",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  cursor: "pointer",
                }}
                onMouseEnter={() => reciteText(category.title)}
                onMouseLeave={() => stopRecitingText()}
              >
                <Card.Img variant="top" src={category.image} style={{ height: "150px", objectFit: "cover", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }} />
                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                  <motion.h4
                    style={{
                      fontSize: "2rem",
                      color: "#fff",
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    {category.title}
                  </motion.h4>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>

      <Row className="justify-content-end">
        <Col md={4} className="d-flex justify-content-end">
          <Button variant="secondary" className="mb-3 mt-5" onClick={goBack}>
            Go back
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Level1_1;
