import React, { useState } from "react";
import { CiPlay1 } from "react-icons/ci";
import { aiGamesData } from "./ai-games.data";
import GamingConfiguration from "./GamingConfiguration";

export default function AIGamesPages() {
  const [selectedGame, setSelectedGame] = useState(null);

  return (
    <div className="tw-my-10">
      <div className="tw-max-w-4xl tw-mx-auto">
        <div className="tw-text-center tw-mb-14">
          <h1 className="tw-text-3xl md:tw-text-4xl tw-font-bold tw-text-blue-600 tw-mb-4">
            AI Games 🌟
          </h1>
          <p className="tw-text-xl tw-text-purple-600">
            Learn languages through fun and games with AI!
          </p>
        </div>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6 lg:tw-gap-x-7 lg:tw-gap-y-9 tw-mb-16">
          {aiGamesData.map((game) => (
            <GameCard
              key={game.id}
              {...game}
              onClick={() => setSelectedGame(game)}
            />
          ))}
        </div>
      </div>
      <GamingConfiguration
        selectedGame={selectedGame}
        setSelectedGame={setSelectedGame}
      />
    </div>
  );
}

function GameCard({ title, icon, description, onClick }) {
  return (
    <div className="tw-bg-white tw-rounded-xl tw-p-6 tw-shadow-xl hover:tw-shadow-2xl tw-transition-shadow">
      <div className="tw-text-4xl tw-mb-4">{icon}</div>
      <h3 className="tw-text-xl tw-font-bold tw-text-gray-800 tw-mb-2">
        {title}
      </h3>
      <p className="tw-text-gray-600 tw-mb-2">{description}</p>
      <div className="tw-flex tw-items-center tw-justify-between tw-mt-5">
        <button
          onClick={onClick}
          className="tw-bg-blue-500 tw-flex tw-items-center tw-gap-x-1 tw-text-white tw-px-3 tw-font-semibold tw-text-sm tw-py-2 tw-rounded-lg hover:tw-bg-blue-700 tw-transition-colors"
        >
          Play Game
          <CiPlay1 size={15} className="tw-mt-0.5 tw-ml-0.5" />
        </button>
      </div>
    </div>
  );
}
